"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.object.freeze");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
var _defineProperty2 = _interopRequireDefault(require("/var/www/html/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _lodash = _interopRequireDefault(require("lodash"));
var _config = require("@/views/modulestore/config");
var _vuex = require("vuex");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {},
  data: function data() {
    return {
      dialogVisible: false,
      cur_componentList: [],
      // 当前展示的组件
      cur_iconList: [],
      // 当前展示的组件
      currentNavId: 0,
      // 当前导航id
      navClassify: '',
      // 组件分类
      addSelect: 0,
      // 新增组件选择（存在公共组件时才用） 0-是新增独立项
      cpoySelect: 'no',
      // 是否复制公共组件数据（存在公共组件时才用）  no / yes
      canChooseArr: [],
      // 可覆盖的公共组件配置项汇总 （存在公共组件时才用）
      activeModuleInfo: '' // 当前选中组件的相关信息（存在公共组件时才用,注意id是初始的A-A，注意变更）
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapState)({
    cur_select_module: function cur_select_module(state) {
      return state.components.cur_select_module;
    },
    cur_select_module_index: function cur_select_module_index(state) {
      return state.components.cur_select_module_index;
    },
    current_public_module: function current_public_module(state) {
      return state.components.current_public_module;
    }
  })),
  methods: {
    /**
     * @index 选中id
     * @list 对应组件列表
     */
    onTab: function onTab(index, iconList) {
      // const {flag} = this.$route.query;
      // if (flag.includes('fans')) {
      //     return;
      // }
      this.currentNavId = index;
      this.cur_iconList = iconList;
    },
    // 初始化设置全部组件
    initComponentList: function initComponentList() {
      this.cur_iconList = this.navClassify[this.currentNavId].iconList;
    },
    //（侧边栏）选择配置组件 （需判断所选组件是否有公共配置）
    selectModule: function selectModule(item) {
      var component_id = item.id;
      var current_public_module = this.current_public_module,
        cur_select_module = this.cur_select_module;
      // 根据组件id, 检索公共配置中包含的同类型数据项
      if (current_public_module && current_public_module.length > 0) {
        var includeArr = [];
        current_public_module.map(function (v, k) {
          if (v.component_id.indexOf(component_id) > -1) {
            includeArr.push(v);
          }
        });
        // 公共存在同类型组件配置
        if (includeArr.length > 0) {
          /**
           * 需要与自身配置做个对比，筛掉公共配置里面包含【相同应用私有组件】配置的项，剩余就是可以用于选择覆盖的组件
           * 例1： 公共 [{id:1-1},{id:1-1-2}]  私有[{id:1-1}] => 筛选出 [{id:1-1-2}]   有可覆盖项
           * 例2： 公共 [{id:1-1},{id:1-1-2}]  私有[{id:1-1},{id:1-1-3}] => 筛选出 [{id:1-1-2}]  有可覆盖项
           * 例3： 公共 [{id:1-1},{id:1-1-2}]  私有[{id:1-1},{id:1-1-2}] => 筛选出 [] (两个对比一致，直接添加随机id即可,不用弹窗)
           */
          var canChooseArr = _lodash.default.differenceBy(includeArr, cur_select_module, 'component_id');
          // 分2种情况，canChooseArr长度为0代表上面的例3，反之是例1、2
          if (canChooseArr.length > 0) {
            // 可以有覆盖项 + 新增随机项
            this.canChooseArr = canChooseArr;
            this.dialogVisible = true;
            this.activeModuleInfo = _lodash.default.cloneDeep(item); // 保存给弹窗确定时使用
          } else {
            this.addModule(item);
          }
        } else {
          // 公共无类似配置
          this.addModule(item);
        }
      } else {
        // 公共无类似配置
        this.addModule(item);
      }
    },
    /**
     * 新增组件：同组件已存在就生成随机id， 反之直接使用初始id
     * 使用随机id的原因：不按照顺序新增是考虑到当组件删除时，不好处理该从哪里新增
     * 假设初始是 [1,2,3] 个组件，删掉 [2,3] 那么新增建2可能影响旧组件，建4需要保存旧数据
     * @params {Object} item   选中的组件相关参数。
     * @params {String} type   类型。 'cover' 覆盖项（直接新增相同id组件，不使用随机id） 'random' 指定生成随机id
     * */
    addModule: function addModule(item, type) {
      var _this = this;
      var data = _lodash.default.cloneDeep(item);
      if (type !== 'cover') {
        // 随机id   格式  1-1-xxxxx
        var num = 0;
        this.cur_select_module.some(function (v, k) {
          if (item.name === v.component_name) {
            num++;
          }
        });
        if (num > 0 || type === 'random') {
          data.id = "".concat(data.id, "-").concat(Math.ceil(Math.random() * 89999 + 10000));
        }
      }
      var params = {
        component_id: data.id,
        component_name: data.name,
        item_num: data.item_num
      };
      this.$store.commit('components/ADD_CUR_SELECT_MOUDLE', params);
      this.$store.commit('components/SET_SELECT_INDEX', this.cur_select_module.length - 1);
      setTimeout(function () {
        var container = _this.$parent.$refs.scroll;
        container.scrollTo({
          top: container.scrollHeight,
          behavior: 'smooth'
        });
      }, 100);
      // 新增完重置回0
      this.addSelect = 0;
    },
    // 切换选择需要新增的组件id
    changeCoverItem: function changeCoverItem(id) {
      this.activeModuleInfo.id = id;
    },
    // 弹窗确定新增按钮
    onSureAddNewModule: function onSureAddNewModule() {
      var _this2 = this;
      var activeModuleInfo = this.activeModuleInfo,
        addSelect = this.addSelect,
        cpoySelect = this.cpoySelect,
        canChooseArr = this.canChooseArr;
      if (addSelect == 0) {
        // 直接新增空白项 （组件id直接使用随机数，因为公共已存在）
        this.addModule(activeModuleInfo, 'random');
        this.dialogVisible = false;
      } else {
        // 覆盖项 - 是否复制公共组件数据 (指定id)
        if (cpoySelect === 'no') {
          this.addModule(activeModuleInfo, 'cover');
          this.dialogVisible = false;
        } else {
          // 获取复制项数据
          this.dialogVisible = false;
          var moduleData = _lodash.default.cloneDeep(canChooseArr[addSelect - 1]);
          this.$store.commit('components/ADD_COPY_CUR_SELECT_MOUDLE', moduleData);
          this.$store.commit('components/SET_SELECT_INDEX', this.cur_select_module.length - 1);
          setTimeout(function () {
            var container = _this2.$parent.$refs.scroll;
            container.scrollTo({
              top: container.scrollHeight,
              behavior: 'smooth'
            });
          }, 100);
          // 新增完重置回0
          this.addSelect = 0;
        }
      }
    }
  },
  mounted: function mounted() {
    var modules = this.$route.query.modules;
    var navClassifyCopy;
    // 导粉，商城配置，只需要展示导粉组件。页面配置，不展示导粉组件
    if (modules === 'page') {
      navClassifyCopy = _config.navClassify.filter(function (v) {
        return v.type === 'page';
      });
    } else {
      navClassifyCopy = _config.navClassify.slice(0, 1);
    }
    var navClassifys = _lodash.default.cloneDeep(navClassifyCopy);
    _config.comlist.forEach(function (item) {
      if (item.type === modules) {
        if (modules === 'page') {
          navClassifys[0].iconList.push(item);
          navClassifys[parseInt(item.name)].iconList.push(item);
        } else {
          navClassifys[0].iconList.push(item);
        }
      }
    });
    this.navClassify = Object.freeze(navClassifys);
    this.initComponentList();
  }
};