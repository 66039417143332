"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _defineProperty2 = _interopRequireDefault(require("/var/www/html/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _lodash = _interopRequireDefault(require("lodash"));
var _vuex = require("vuex");
var _yunying = require("@/api/yunying");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var uploadType = '';
var _default2 = exports.default = {
  props: {
    manageType: '',
    ruleForm: {
      type: Object,
      default: function _default() {
        return {
          avatar_image: ''
        };
      }
    }
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(['token'])),
  data: function data() {
    return {
      dialogVisible: false,
      uploadImgUrl: _yunying.uploadImgUrl,
      rules: {
        template: {
          required: true,
          message: '请输入模块名称',
          trigger: 'blur'
        },
        name: {
          required: true,
          message: '请输入老师名称',
          trigger: 'blur'
        },
        tip: [{
          required: true,
          message: '请输入描述',
          trigger: 'blur'
        }],
        introduce: [{
          required: true,
          message: '请输入其他信息',
          trigger: 'blur'
        }],
        avatar_image: [{
          required: true,
          message: '请上传老师头像',
          trigger: 'blur'
        }],
        introduce_image: [{
          required: true,
          message: '请上传老师大图',
          trigger: 'blur'
        }]
      }
    };
  },
  mounted: function mounted() {},
  unmounted: function unmounted() {
    this.$refs.ruleForm.resetFields();
  },
  methods: {
    onCancel: function onCancel() {
      this.dialogVisible = false;
    },
    submitForm: function submitForm() {
      var _this = this;
      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          _this.handleEvent();
        } else {
          return false;
        }
      });
    },
    handleEvent: function handleEvent() {
      var _this2 = this;
      var manageType = this.manageType,
        ruleForm = this.ruleForm;
      var content = {
        name: ruleForm.name,
        avatar_image: ruleForm.avatar_image,
        introduce_image: ruleForm.introduce_image,
        tip: ruleForm.tip,
        introduce: ruleForm.introduce,
        extend: ruleForm.extend
      };
      var params = {
        content: JSON.stringify(content),
        name: ruleForm.template
      };
      if (manageType == 'add') {
        (0, _yunying.addTeacher)(params).then(function (data) {
          if (data.code === 200) {
            _this2.$message({
              message: '添加成功',
              type: 'success'
            });
            _this2.dialogVisible = false;
            _this2.$parent.getWxComponentList(); // 更新父组件数据
          }
        });
      } else {
        (0, _yunying.modifyTeacher)(ruleForm.id, params).then(function (data) {
          if (data.code === 200) {
            _this2.$message({
              message: '修改成功',
              type: 'success'
            });
            _this2.dialogVisible = false;
            _this2.$parent.getWxComponentList(); // 更新父组件数据
          }
        });
      }
    },
    // 上传文件之前的钩子
    handleBeforeUpload: function handleBeforeUpload(file) {
      var isLt500KB = file.size / 1024 < 500;
      if (!isLt500KB) {
        this.$message.error('上传图片大小不能超过 500 kb!');
      }
      return isLt500KB;
    },
    // 图片超出限定个数钩子
    handleExceed: function handleExceed() {
      this.$message.error("\u53EA\u80FD\u4E0A\u4F201\u5F20\u56FE\u7247\uFF0C\u5982\u9700\u66F4\u6362\u56FE\u7247\uFF0C\u8BF7\u5220\u9664\u5DF2\u4E0A\u4F20\u6587\u4EF6\uFF0C\u91CD\u65B0\u4E0A\u4F20");
    },
    // 图片上传成功
    handleSuccess: function handleSuccess(response, file, fileList) {
      var data = response.data;
      this.$set(this.ruleForm, "".concat(uploadType, "_image"), data[0].url);
      fileList.pop();
    },
    // 上传失败
    handleError: function handleError() {
      this.$message.error('图片上传失败,请重新上传!');
    },
    triggerUpload: function triggerUpload(type) {
      uploadType = type;
    }
  }
};