var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit-wrapper" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "编辑",
            visible: _vm.dialogVisible,
            width: "50%",
            "show-close": false,
            "close-on-click-modal": false,
            center: "",
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "rulesForm",
              attrs: {
                model: _vm.formData,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "权限名称", prop: "desc" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入权限名称", clearable: "" },
                    model: {
                      value: _vm.formData.desc,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "desc", $$v)
                      },
                      expression: "formData.desc",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "HTTP方式", prop: "method" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { multiple: "", placeholder: "请选择" },
                      model: {
                        value: _vm.formData.method,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "method", $$v)
                        },
                        expression: "formData.method",
                      },
                    },
                    _vm._l(_vm.selectOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "前端路由", prop: "front_route" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入前端路由", clearable: "" },
                    model: {
                      value: _vm.formData.front_route,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "front_route", $$v)
                      },
                      expression: "formData.front_route",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "接口路由", prop: "route" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入接口路由", clearable: "" },
                    model: {
                      value: _vm.formData.route,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "route", $$v)
                      },
                      expression: "formData.route",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "form-buttons" },
                [
                  _c("el-button", { on: { click: _vm.cancel } }, [
                    _vm._v("取消"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitHandle },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.manageType === "add" ? "新增" : "保存") +
                          "\n                "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }