var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tips" }, [
    _vm._v("您没有访问权限！请按照流程申请权限！"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }