"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var _user = require("@/api/user");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'userManage',
  data: function data() {
    return {
      loading: false,
      searchStatus: '',
      // 登录状态搜索
      searchRealname: '',
      // 姓名搜索
      searchRole: '',
      // 角色搜索
      rolelist: [],
      // 角色列表
      tableData: [],
      selectStatus: [{
        value: 0,
        label: '禁止登录'
      }, {
        value: 1,
        label: '允许登录'
      }],
      tablePage: {
        per_page: 30,
        current: 1,
        total_page: 1
      }
    };
  },
  filters: {
    loginStatus: function loginStatus(val) {
      var str = '-';
      switch (val) {
        case 0:
          str = '禁止登陆';
          break;
        case 1:
          str = '允许登录';
          break;
      }
      return str;
    }
  },
  mounted: function mounted() {
    this.getTableData();
    this.getRoleList();
  },
  methods: {
    // 初始化角色列表
    getRoleList: function getRoleList() {
      var _this = this;
      (0, _user.getRoleList)().then(function (res) {
        var data = res.data,
          list = [];
        data.map(function (item, index) {
          list.push({
            value: item.id,
            label: item.role
          });
        });
        _this.rolelist = list;
      });
    },
    // 分页
    handleCurrentChange: function handleCurrentChange(page) {
      this.tablePage.current = page;
      this.getTableData();
    },
    // 搜索
    onSearch: function onSearch() {
      this.getTableData();
    },
    // 获取用户列表
    getTableData: function getTableData() {
      var _this2 = this;
      this.loading = true;
      var params = {
        name: this.searchRealname,
        allow_login: this.searchStatus,
        role_id: this.searchRole,
        size: this.tablePage.per_page,
        page: this.tablePage.current
      };
      (0, _user.getUserList)(params).then(function (res) {
        var data = res.data;
        _this2.tablePage = {
          per_page: data.per_page,
          current: data.current_page,
          total_page: data.total_page,
          total_num: data.total_num
        };
        _this2.tableData = data.list;
        _this2.loading = false;
      }).catch(function () {
        _this2.loading = false;
      });
    },
    editRow: function editRow() {
      var row = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var path = "/auth/useredit";
      if (row.id) {
        path = "".concat(path, "/").concat(row.id);
      }
      this.$router.push({
        path: path
      });
    }
  }
};