var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "el-form",
        { attrs: { inline: true } },
        [
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  staticClass: "width-250",
                  attrs: {
                    filterable: "",
                    clearable: "",
                    multiple: "",
                    placeholder: "产品名称/APPID",
                  },
                  model: {
                    value: _vm.searchProductIdList,
                    callback: function ($$v) {
                      _vm.searchProductIdList = $$v
                    },
                    expression: "searchProductIdList",
                  },
                },
                _vm._l(_vm.all_products, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: {
                      label:
                        "" + item.name + (item.app_id ? " " + item.app_id : ""),
                      value: item.id,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  staticClass: "width-200",
                  attrs: {
                    filterable: "",
                    clearable: "",
                    placeholder: "模型名称",
                  },
                  model: {
                    value: _vm.searchPattern,
                    callback: function ($$v) {
                      _vm.searchPattern = $$v
                    },
                    expression: "searchPattern",
                  },
                },
                _vm._l(_vm.patternList, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c("el-input", {
                staticClass: "width-200",
                attrs: { placeholder: "渠道", clearable: "" },
                model: {
                  value: _vm.searchChannel,
                  callback: function ($$v) {
                    _vm.searchChannel = $$v
                  },
                  expression: "searchChannel",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  staticClass: "width-150",
                  attrs: { placeholder: "配置类型", clearable: "" },
                  model: {
                    value: _vm.searchConfigType,
                    callback: function ($$v) {
                      _vm.searchConfigType = $$v
                    },
                    expression: "searchConfigType",
                  },
                },
                _vm._l(_vm.configTypeList, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-search", plain: "" },
                  on: { click: _vm.onSearch },
                },
                [_vm._v("\n                搜索\n            ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-plus" },
                  on: { click: _vm.onAdd },
                },
                [_vm._v("新增")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "warning",
                    icon: "el-icon-refresh",
                    plain: "",
                  },
                  on: { click: _vm.onReset },
                },
                [_vm._v("\n                重置\n            ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "success",
                    icon: "el-icon-download",
                    plain: "",
                  },
                  on: { click: _vm.onExport },
                },
                [_vm._v("\n                导出\n            ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-popover",
                {
                  attrs: {
                    placement: "top-start",
                    width: "200",
                    trigger: "hover",
                    content: "提示：批量操作仅对当前页勾选项有效！",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        slot: "reference",
                        type: "danger",
                        icon: "el-icon-edit",
                        plain: "",
                      },
                      on: { click: _vm.onModifyMulti },
                      slot: "reference",
                    },
                    [_vm._v("\n                    批量操作\n                ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger", icon: "el-icon-setting" },
                  on: { click: _vm.onModifyAll },
                },
                [_vm._v("\n                一键配置\n            ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("p", { staticClass: "remark" }, [
        _vm._v(
          "\n        备注： 1. 成交：拿大数据前一天的数据；2.\n        配置值：导粉数=该渠道成交数*该模型导粉率*该模型权重比例\n    "
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            border: "",
            "span-method": _vm.objectSpanMethod,
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "40" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "app_id",
              label: "APP ID",
              width: "120",
              align: "center",
              fixed: "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "product_id",
              label: "产品ID",
              width: "250",
              align: "center",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "product_name",
              label: "产品名称",
              width: "250",
              align: "center",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "id", label: "ID", width: "120", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "channel_id",
              label: "渠道",
              width: "180",
              align: "center",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "mode_name",
              label: "渠道匹配模式",
              width: "120",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-tag",
                      {
                        staticClass: "patter-tag",
                        attrs: {
                          type:
                            scope.row.mode_name === "模糊匹配"
                              ? "success"
                              : "warning",
                          "disable-transitions": "",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(scope.row.mode_name) + "\n                "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "order_count",
              label: "成交数",
              width: "180",
              align: "center",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "class_name",
              label: "配置类型",
              width: "180",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type: _vm.styleList[
                            scope.row.class % _vm.styleList.length
                          ],
                        },
                      },
                      [_vm._v(_vm._s(scope.row.class_name))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "value",
              label: "配置值",
              align: "center",
              "min-width": "400",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(scope.row.newValue, function (v, k) {
                    return _c(
                      "el-tag",
                      {
                        key: k,
                        staticClass: "patter-tag",
                        attrs: {
                          type: _vm.styleList[k % _vm.styleList.length],
                          "disable-transitions": "",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            v.label +
                              "：比例 " +
                              v.weight +
                              "%，导粉数 " +
                              v.fans
                          ) + "\n                "
                        ),
                      ]
                    )
                  })
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "updated_at",
              label: "更新时间",
              width: "180",
              align: "center",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              align: "center",
              fixed: "right",
              width: "120",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "text",
                          disabled:
                            scope.row.change_state === _vm.forbidModifyFlag,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.onModify(scope.row)
                          },
                        },
                      },
                      [_vm._v("修改")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "text",
                          disabled:
                            scope.row.change_state === _vm.forbidModifyFlag,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.onDelete(scope.row)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "statistic-wrap" }, [
        _c("p", [
          _vm._v("\n            该页总成交数："),
          _c("span", [_vm._v(_vm._s(_vm.totalOrders))]),
          _vm._v("， 总导粉数："),
          _c("span", [_vm._v(_vm._s(_vm.totalFans))]),
        ]),
        _vm._v(" "),
        _vm.searchChannel && _vm.showChannelCount
          ? _c("p", [
              _vm._v("\n            该页搜索渠道"),
              _c("span", [_vm._v(_vm._s(_vm.searchChannel))]),
              _vm._v("总成交数："),
              _c("span", [_vm._v(_vm._s(_vm.totalChannelOrders))]),
              _vm._v("，总导粉数："),
              _c("span", [_vm._v(_vm._s(_vm.totalChannelFans))]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.totalPatternOrders > -1 && _vm.searchPattern > 0
          ? _c("p", [
              _vm._v("\n            该页搜索模型总成交数："),
              _c("span", [_vm._v(_vm._s(_vm.totalPatternOrders))]),
              _vm._v("，总导粉数："),
              _c("span", [_vm._v(_vm._s(_vm.totalPatternFans))]),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.totalPage > 1
        ? _c(
            "section",
            { staticClass: "pagination-wrap" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.currentPage,
                  "page-size": _vm.perPage,
                  layout: "prev, pager, next, jumper",
                  "page-count": _vm.totalPage,
                },
                on: {
                  "current-change": _vm.handleCurrentChange,
                  "update:currentPage": function ($event) {
                    _vm.currentPage = $event
                  },
                  "update:current-page": function ($event) {
                    _vm.currentPage = $event
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("RatioManage", {
        ref: "managePop",
        attrs: {
          manageType: _vm.manageType,
          ruleForm: _vm.chooseData,
          patternList: _vm.patternList,
          multiList: _vm.multipleSelection,
          configTypeList: _vm.configTypeList,
          changeStateList: _vm.changeStateList,
          forbidModifyFlag: _vm.forbidModifyFlag,
        },
        on: {
          "update:ruleForm": function ($event) {
            _vm.chooseData = $event
          },
          "update:rule-form": function ($event) {
            _vm.chooseData = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }