var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "el-form",
        {
          attrs: {
            model: _vm.propForm,
            rules: _vm.openRules ? _vm.rules : {},
            "label-width": _vm.labelWidth,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.labelTitle, prop: "desc" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: _vm.placeholder },
                on: { focus: _vm.onFocus, blur: _vm.onBlur },
                model: {
                  value: _vm.propForm.desc,
                  callback: function ($$v) {
                    _vm.$set(_vm.propForm, "desc", $$v)
                  },
                  expression: "propForm.desc",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }