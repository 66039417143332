var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.manageType == "add" ? "新增导粉号" : "修改导粉号",
            visible: _vm.dialogVisible,
            width: "60%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "section",
            { staticClass: "lists" },
            [
              _c(
                "el-form",
                {
                  ref: "fromData",
                  attrs: {
                    model: _vm.fromData,
                    "label-position": "left",
                    "label-width": "140px",
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      key: _vm.fromData.type + "type",
                      attrs: { label: "导粉号类型", prop: "type" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "width-200",
                          attrs: {
                            placeholder: "请选择类型",
                            disabled: _vm.disabled,
                          },
                          on: { change: _vm.handleType },
                          model: {
                            value: _vm.fromData.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.fromData, "type", $$v)
                            },
                            expression: "fromData.type",
                          },
                        },
                        _vm._l(_vm.type, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.fromData.type === 3
                    ? _c(
                        "el-form-item",
                        {
                          key: _vm.fromData.type + "qrcode_id",
                          attrs: { label: "活码ID", prop: "qrcode_id" },
                        },
                        [
                          _c("el-input", {
                            staticClass: "width-200",
                            attrs: { placeholder: "如：157", clearable: "" },
                            on: { input: _vm.qrcodeIdChange },
                            model: {
                              value: _vm.fromData.qrcode_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.fromData, "qrcode_id", $$v)
                              },
                              expression: "fromData.qrcode_id",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            { on: { click: _vm.getlivecodeData } },
                            [_vm._v("获取")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      key: _vm.fromData.type + "qrcode_remarks",
                      attrs: {
                        label: "活码标识（中文）",
                        prop: "qrcode_remarks",
                      },
                    },
                    [
                      _c("el-input", {
                        staticClass: "width-400",
                        attrs: {
                          placeholder: "如：咨询-结果页-顶部banner-投放百度",
                          clearable: "",
                          maxlength: "50",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.fromData.qrcode_remarks,
                          callback: function ($$v) {
                            _vm.$set(_vm.fromData, "qrcode_remarks", $$v)
                          },
                          expression: "fromData.qrcode_remarks",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      key: _vm.fromData.type + "department",
                      attrs: { label: "部门", prop: "department" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "width-200",
                          attrs: { filterable: "", placeholder: "请选择部门" },
                          model: {
                            value: _vm.fromData.department,
                            callback: function ($$v) {
                              _vm.$set(_vm.fromData, "department", $$v)
                            },
                            expression: "fromData.department",
                          },
                        },
                        _vm._l(
                          this.wechatConstantData["department"],
                          function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: { label: item, value: index },
                            })
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  this.fromData.type > 2
                    ? _c(
                        "el-form-item",
                        {
                          key: _vm.fromData.type + "subject",
                          attrs: { label: "主体", prop: "subject" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "width-200",
                              attrs: {
                                filterable: "",
                                placeholder: "请选择主体",
                              },
                              model: {
                                value: _vm.fromData.subject,
                                callback: function ($$v) {
                                  _vm.$set(_vm.fromData, "subject", $$v)
                                },
                                expression: "fromData.subject",
                              },
                            },
                            _vm._l(
                              this.wechatConstantData["subject"],
                              function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: item, value: index },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      key: _vm.fromData.type + "qrcode_sign",
                      attrs: { label: "活码标识（拼音）", prop: "qrcode_sign" },
                    },
                    [
                      _c("el-input", {
                        staticClass: "width-400",
                        attrs: {
                          placeholder: "如：ZX_jgy_db_tfbd",
                          clearable: "",
                          maxlength: "50",
                          "show-word-limit": "",
                          disabled: _vm.disabled,
                        },
                        model: {
                          value: _vm.fromData.qrcode_sign,
                          callback: function ($$v) {
                            _vm.$set(_vm.fromData, "qrcode_sign", $$v)
                          },
                          expression: "fromData.qrcode_sign",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  this.fromData.type === 3
                    ? _c(
                        "el-form-item",
                        {
                          key: _vm.fromData.type + "qrlink",
                          attrs: { label: "获客链接" },
                        },
                        [
                          _c("el-input", {
                            staticClass: "width-400",
                            attrs: {
                              clearable: "",
                              placeholder: "请输入获客链接",
                              maxlength: "120",
                              "show-word-limit": "",
                              disabled: _vm.disabled,
                            },
                            model: {
                              value: _vm.fromData.qrlink,
                              callback: function ($$v) {
                                _vm.$set(_vm.fromData, "qrlink", $$v)
                              },
                              expression: "fromData.qrlink",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("ImageUpload", {
                    key: _vm.fromData.type + "imgUrl",
                    ref: "imageUpload",
                    attrs: {
                      groupFlag: false,
                      ruleForm: _vm.imgUrl,
                      labelTitle: "二维码图片",
                      labelWidth: "140px",
                      required: _vm.imgUrlRequired,
                      disabled: _vm.disabled,
                    },
                    on: {
                      "update:ruleForm": function ($event) {
                        _vm.imgUrl = $event
                      },
                      "update:rule-form": function ($event) {
                        _vm.imgUrl = $event
                      },
                      "update:required": function ($event) {
                        _vm.imgUrlRequired = $event
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "状态", prop: "status" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.fromData.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.fromData, "status", $$v)
                            },
                            expression: "fromData.status",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("可用"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("禁用"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.onCancel } }, [
                _vm._v("取消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("fromData")
                    },
                  },
                },
                [_vm._v("确定并同步")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }