"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.regexp.match");
var _axios = _interopRequireDefault(require("axios"));
var _qs = _interopRequireDefault(require("qs"));
var _elementUi = require("element-ui");
var _store = _interopRequireDefault(require("@/store"));
var _auth = require("@/utils/auth");
// create an axios instance
var service = _axios.default.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // url = base url + request url
  timeout: 100000 // 请求超时
});

// 请求拦截
service.interceptors.request.use(function (config) {
  var data = config.data,
    method = config.method;

  /** 在请求之前做统一处理 **/

  // token验证
  if (_store.default.getters.token) {
    config.headers['Authorization'] = (0, _auth.getToken)();
  }
  if (config.type == 'ltv') {
    // 大数据的post请求不用做处理
    config.baseURL = process.env.VUE_APP_LTV_API;
  } else {
    var isFormData = function isFormData(o) {
      return !!Object.prototype.toString.call(o).match(/formdata/i);
    };
    if (method === 'post' || method === 'put') {
      if (typeof data === 'string' || isFormData(data)) {
        config.data = data;
      } else {
        config.data = _qs.default.stringify(data);
      }
    }
  }
  return config;
}, function (error) {
  // 这里可以留错误上报
  console.log(error); // for debug
  return Promise.reject(error);
});
// 响应拦截
service.interceptors.response.use(function (response) {
  var res = response.data;
  return res;
}, function (error) {
  if (!error.response) {
    (0, _elementUi.Message)({
      message: 'error!!!',
      type: 'error',
      duration: 1000
    });
    return null;
  }
  var data = error.response.data;
  (0, _elementUi.Message)({
    message: data.tips || data.msg || '出错了 T^T',
    type: 'error',
    duration: 2000
  });
  return Promise.reject(error.response || null);
});
var _default = exports.default = service;