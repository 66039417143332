"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _request = _interopRequireDefault(require("@/utils/request"));
var _user = require("@/api/user");
var _permitEdit = _interopRequireDefault(require("./component/permit-edit"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    permitEdit: _permitEdit.default
  },
  data: function data() {
    return {
      loading: false,
      manageType: '',
      // 编辑类型: add modify
      chooseData: {},
      // 编辑数据
      tableData: [],
      editflag: this.$route.query.editflag || 0 // 仅限开发者使用 权限的编辑修改不开放
    };
  },
  mounted: function mounted() {
    this.getTableData();
  },
  methods: {
    // 获取权限列表数据
    getTableData: function getTableData() {
      var _this = this;
      this.loading = true;
      (0, _user.getPermission)('get').then(function (res) {
        var data = res.data;
        _this.tableData = data;
        _this.loading = false;
      }).catch(function () {
        _this.loading = false;
      });
    },
    // 新增
    addRow: function addRow() {
      this.manageType = 'add';
      this.chooseData = {};
      this.$refs.permitManage.dialogVisible = true;
    },
    // 修改
    editRow: function editRow() {
      var row = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.manageType = 'modify';
      this.chooseData = {
        id: row.id,
        desc: row.desc,
        method: row.method.split(','),
        route: row.route,
        front_route: row.front_route
      };
      this.$refs.permitManage.dialogVisible = true;
    },
    // 删除
    delRow: function delRow() {
      var _this2 = this;
      var row = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.$confirm('此操作将删除该权限，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var data = {
          id_list: JSON.stringify([row.id])
        };
        (0, _user.getPermission)('delete', data).then(function () {
          _this2.$message({
            type: 'success',
            message: '删除成功!'
          });
          _this2.getTableData();
        });
      }).catch(function (e) {});
    }
  }
};