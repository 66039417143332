var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ComComponent", {
    ref: "comComponent",
    attrs: {
      title: "banner",
      id: _vm.id,
      keyId: _vm.keyId,
      tips: "说明：banner尺寸宽度750px，高度不限制。个数不限定",
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          var configData = ref.configData
          return [
            configData.length >= 1
              ? _c(
                  "div",
                  _vm._l(configData, function (v, k) {
                    return _c(
                      "div",
                      { key: k },
                      [
                        _c(
                          "el-image",
                          {
                            staticClass: "banner",
                            attrs: { src: v.img_url, fit: "contain" },
                            on: {
                              click: function ($event) {
                                return _vm.onLocation(v.url_config)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(v.url_config) +
                                "\n                    "
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "image-default",
                                attrs: { slot: "error" },
                                slot: "error",
                              },
                              [_c("i", { staticClass: "el-icon-picture" })]
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  }),
                  0
                )
              : _vm._e(),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }