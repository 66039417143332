"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _comConfig = _interopRequireDefault(require("../../common/com-config"));
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    ComConfig: _comConfig.default
  },
  data: function data() {
    return {
      styleConfig: {
        descShow: 'show'
      }
    };
  }
};