"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.object.freeze");
var _yunying = require("@/api/yunying");
var _regChannelsEdit = _interopRequireDefault(require("./components/reg-channels-edit"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    RegChannelsEdit: _regChannelsEdit.default
  },
  data: function data() {
    return {
      loading: false,
      tablePage: {
        per_page: 50,
        current: 1,
        total_page: 1,
        total_num: 1
      },
      tableData: [],
      searchName: '',
      // 搜索输入
      searchReg: '',
      // 搜索输入
      editType: '',
      // 编辑类型
      chooseData: {} // 当前选择项的全部参数 {}
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      var params = {
        name: this.searchName,
        reg: this.searchReg,
        per_page: this.tablePage.per_page,
        page: this.tablePage.current
      };
      (0, _yunying.regChannelsList)(params).then(function (_ref) {
        var data = _ref.data;
        _this.loading = false;
        _this.tableData = Object.freeze(data.list);
        _this.tablePage = {
          per_page: data.per_page,
          current: data.current_page,
          total_page: data.total_page,
          total_num: data.total_num
        };
      });
    },
    handleCurrentChange: function handleCurrentChange(page) {
      this.tablePage.current = page;
      this.getList();
    },
    onAdd: function onAdd() {
      this.editType = 'add';
      this.chooseData = {};
      this.$refs.RegChannelEdit.dialogVisible = true;
    },
    onModify: function onModify(val) {
      this.editType = 'modify';
      this.chooseData = Object.assign({}, val);
      this.$refs.RegChannelEdit.dialogVisible = true;
    },
    onDelete: function onDelete(val) {
      var _this2 = this;
      var name = val.name,
        id = val.id;
      this.$confirm("\u5373\u5C06\u5220\u9664\u6B63\u5219\u6E20\u9053\u7EC4\u3010".concat(name, "\u3011, \u662F\u5426\u7EE7\u7EED?"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _yunying.deleteRegChannels)({
          id: id
        }).then(function (_ref2) {
          var code = _ref2.code;
          if (code == 200) {
            _this2.$message({
              type: 'success',
              message: '删除成功!'
            });
            _this2.getList();
          }
        });
      }).catch(function () {});
    }
  }
};