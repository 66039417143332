var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("div", { staticClass: "config-item-2" }, [
        _c(
          "p",
          {
            on: {
              click: function ($event) {
                _vm.dialogVisible = true
              },
            },
          },
          [_vm._v("+ 添加 / 修改图文项")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择图文项",
            visible: _vm.dialogVisible,
            width: "75%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "button-wrap" },
            [
              _c("el-button", { on: { click: _vm.toAddPage } }, [
                _vm._v("新增图文项"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "width-250",
                      attrs: { placeholder: "输入名称搜索", clearable: "" },
                      on: { change: _vm.onSearch },
                      model: {
                        value: _vm.searchName,
                        callback: function ($$v) {
                          _vm.searchName = $$v
                        },
                        expression: "searchName",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: { slot: "append", icon: "el-icon-search" },
                        on: { click: _vm.onSearch },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    { staticClass: "icon-update", on: { click: _vm.onUpDate } },
                    [_c("i"), _vm._v("数据更新 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "select-wrap" }, [
            _c("div", { staticClass: "select-wrap-left" }, [
              _c("div", { staticClass: "title" }, [
                _vm._v("已选 (拖动图块可调整顺序)"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "choose-wrap" },
                [
                  _vm.configLists &&
                  _vm.configLists.config_data.length >= 1 &&
                  _vm.checkList.length > 0
                    ? _c(
                        "draggable",
                        {
                          staticClass: "choose-list",
                          attrs: { list: _vm.configLists.config_data },
                        },
                        _vm._l(_vm.configLists.config_data, function (v, k) {
                          return _c(
                            "div",
                            {
                              key: k,
                              staticClass: "choose-item",
                              attrs: { title: "拖动图块调整顺序" },
                            },
                            [
                              _c("div", [
                                _c("img", {
                                  attrs: { src: v.img_url, alt: "" },
                                }),
                                _vm._v(" "),
                                _c("p", { staticClass: "font-12" }, [
                                  _vm._v(_vm._s(v.title)),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("i", {
                                staticClass: "el-icon-delete delete-btn",
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteHandle(k)
                                  },
                                },
                              }),
                            ]
                          )
                        }),
                        0
                      )
                    : _c("div", { staticClass: "choose-list-none" }, [
                        _vm._v("暂无数据~"),
                      ]),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "select-wrap-right" },
              [
                _c(
                  "el-main",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                  },
                  [
                    _vm.dataList && _vm.dataList.length > 0
                      ? _c(
                          "div",
                          { staticClass: "data-list" },
                          [
                            _c(
                              "el-checkbox-group",
                              {
                                model: {
                                  value: _vm.checkList,
                                  callback: function ($$v) {
                                    _vm.checkList = $$v
                                  },
                                  expression: "checkList",
                                },
                              },
                              _vm._l(_vm.dataList, function (v, k) {
                                return _c(
                                  "el-checkbox",
                                  {
                                    key: k,
                                    attrs: { border: "", label: v.id },
                                    on: {
                                      click: function ($event) {
                                        return _vm.chooseItemHandle(v, k)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "data-item" },
                                      [
                                        _c("el-image", {
                                          staticStyle: {
                                            width: "40px",
                                            height: "40px",
                                          },
                                          attrs: {
                                            src: v.img_url,
                                            fit: "contain",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("p", { staticClass: "font-8" }, [
                                          _vm._v(_vm._s(v.title)),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _c("div", { staticClass: "data-list-none" }, [
                          _vm._v("暂无数据"),
                        ]),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }