"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/var/www/html/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _user = require("@/api/user");
var _vuex = require("vuex");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      id: this.$route.params.ID || '',
      // 从链接获取用户id
      permitList: '',
      // 权限列表
      rolelist: '',
      // 角色列表
      ruleForm: {
        selected: [],
        allow_login: 0,
        role: '',
        realname: ''
      },
      oldData: '',
      // 旧数据，保存时对比用
      permitFlag: true
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(['permissionList'])),
  mounted: function mounted() {
    var _this = this;
    if (this.id) {
      this.getUserById();
    }
    this.getRoleList();
    this.$store.dispatch('user/getPermissionList').then(function () {
      _this.permitList = _this.generateData(_this.permissionList);
    });
  },
  methods: {
    // 获取用户信息
    getUserById: function getUserById() {
      var _this2 = this;
      (0, _user.getSingleUser)(this.id).then(function (res) {
        var _res$data = res.data,
          allow_login = _res$data.allow_login,
          role = _res$data.role,
          permission = _res$data.permission,
          realname = _res$data.realname;
        _this2.ruleForm.allow_login = allow_login;
        _this2.ruleForm.realname = realname;
        permission.map(function (item) {
          _this2.ruleForm.selected.push(item.id);
        });
        role.map(function (item) {
          _this2.ruleForm.role.push(item.id);
          if (item.id == 30) {
            _this2.permitFlag = false;
          }
        });
        _this2.oldData = Object.assign({}, _this2.ruleForm);
      });
    },
    // 初始化权限选择列表
    generateData: function generateData(permission) {
      var data = [];
      permission.map(function (item, index) {
        data.push({
          key: item.id,
          label: item.desc,
          disabled: false
        });
      });
      return data;
    },
    // 初始化角色列表
    getRoleList: function getRoleList() {
      var _this3 = this;
      (0, _user.getRoleList)().then(function (res) {
        var data = res.data,
          list = [];
        data.map(function (item, index) {
          list.push({
            value: item.id,
            label: item.role
          });
        });
        _this3.rolelist = list;
      });
    },
    roleChange: function roleChange(e) {
      // 30是超级管理员
      if (e.indexOf(30) > -1) {
        this.permitFlag = false;
      } else {
        this.permitFlag = true;
      }
    },
    cancel: function cancel() {
      this.$router.push({
        path: '/auth/usermanage'
      });
    },
    submit: function submit() {
      var _this4 = this;
      if (JSON.stringify(this.ruleForm) == JSON.stringify(this.oldData)) {
        this.$message('您未修改任何信息');
        return;
      }
      this.$confirm('您确定修改该用户信息吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this4.submitUser();
      });
    },
    // 提交角色信息
    submitUser: function submitUser() {
      var _this5 = this;
      var ruleForm = this.ruleForm;
      var data = {
        id: this.id,
        permission: ruleForm.selected.length > 0 ? ruleForm.selected.join(',') : '',
        allow_login: ruleForm.allow_login,
        role: ruleForm.role.length > 0 ? ruleForm.role.join(',') : ''
      };
      (0, _user.updateSingleUser)(data).then(function (res) {
        _this5.$message({
          type: 'success',
          message: '提交成功!'
        });
        _this5.$router.push({
          path: '/auth/usermanage'
        });
      });
    }
  }
};