var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ComConfig", {
    attrs: {
      title: "导粉-2",
      component_name: "8-2-guide-add-wechat",
      limit: 1,
      configItemId: 3,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }