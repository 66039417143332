"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.object.freeze");
require("core-js/modules/es6.function.name");
var _yunying = require("@/api/yunying");
var _groupManage = _interopRequireDefault(require("./components/group-manage"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 新增配置项
var _default = exports.default = {
  components: {
    GroupManage: _groupManage.default
  },
  data: function data() {
    return {
      loading: true,
      tableData: [],
      name: '',
      // 修改数据
      chooseData: {
        wechatId: [],
        id: '',
        percent: []
      },
      manageType: '',
      // 操作类型
      currentPage: 1,
      totalPage: 1,
      pageSize: 30,
      type: []
    };
  },
  mounted: function mounted() {
    this.getList();
    this.getType();
  },
  methods: {
    // 获取配置列表
    getList: function getList() {
      var _this = this;
      var params = {
        page: this.currentPage,
        per_page: this.pageSize
      };
      if (this.name) {
        params.name = this.name;
      }
      if (this.searchName) {
        params.name = this.searchName;
      }
      if (this.searchId) {
        params.wechat = this.searchId;
      }
      (0, _yunying.getGoodsGroup)(params).then(function (_ref) {
        var data = _ref.data,
          code = _ref.code;
        _this.loading = false;
        if (code === 200) {
          _this.tableData = Object.freeze(data.list);
          _this.totalPage = data.total_page;
        }
      });
    },
    getType: function getType() {
      var _this2 = this;
      (0, _yunying.getGoodsProp)({
        mode: 'type'
      }).then(function (_ref2) {
        var data = _ref2.data;
        _this2.type = data;
      });
    },
    onSearch: function onSearch() {
      this.getList();
    },
    // 分页
    handleCurrentChange: function handleCurrentChange(page) {
      this.currentPage = page;
      this.getList();
    },
    // 新增配置
    onAdd: function onAdd() {
      this.chooseData = {
        name: '',
        type: '',
        group: []
      };
      this.manageType = 'add';
      this.$refs.ConfigManage.dialogVisible = true;
    },
    // 修改
    onModify: function onModify(val) {
      var type = val.type,
        goods_item_group = val.goods_item_group,
        id = val.id,
        name = val.name;
      var group = goods_item_group.split(',').map(function (v) {
        return parseInt(v);
      });
      this.chooseData = {
        id: id,
        name: name,
        group: group,
        type: type
      };
      this.manageType = 'modify';
      this.$refs.ConfigManage.dialogVisible = true;
    }
  }
};