"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UNIFY_BACKENDS = void 0;
exports.addRole = addRole;
exports.authLogout = exports.authLogin = void 0;
exports.deleteRole = deleteRole;
exports.getLoginUserInfo = getLoginUserInfo;
exports.getOperateLog = getOperateLog;
exports.getPermission = getPermission;
exports.getRoleDetail = getRoleDetail;
exports.getRoleList = getRoleList;
exports.getSingleUser = getSingleUser;
exports.getUserList = getUserList;
exports.updatePermission = updatePermission;
exports.updateRole = updateRole;
exports.updateSingleUser = updateSingleUser;
exports.userLogin = userLogin;
var _request = _interopRequireDefault(require("@/utils/request"));
// 统一后台地址
var UNIFY_BACKENDS = exports.UNIFY_BACKENDS = 'https://unify-backends.linghit.cn/#/basic/get_backend';

// 登录
var authLogin = exports.authLogin = function authLogin() {
  return "".concat(UNIFY_BACKENDS);
};

// 注销
var authLogout = exports.authLogout = function authLogout() {
  return "".concat(UNIFY_BACKENDS);
};

// 登录
function userLogin(data) {
  return (0, _request.default)({
    url: '/backend/login',
    method: 'post',
    data: data
  });
}

// 根据认证信息获取管理员信息
function getLoginUserInfo(params) {
  return (0, _request.default)({
    url: '/backend/rbac/info',
    method: 'get',
    params: params
  });
}

// 获取用户列表
function getUserList(params) {
  return (0, _request.default)({
    url: '/resource/rbac/manager',
    method: 'get',
    params: params
  });
}

// 获取单个用户信息
function getSingleUser(id) {
  return (0, _request.default)({
    url: '/resource/rbac/manager/detail',
    method: 'get',
    params: {
      id: id
    }
  });
}

// 修改单个用户角色、权限
function updateSingleUser(data) {
  return (0, _request.default)({
    url: '/resource/rbac/manager/update',
    method: 'post',
    data: data
  });
}

/**********角色*************/
// 角色列表
function getRoleList(params) {
  return (0, _request.default)({
    url: "/resource/rbac/role",
    method: 'get',
    params: params
  });
}

// 删除角色
function deleteRole(params) {
  return (0, _request.default)({
    url: "/resource/rbac/role",
    method: 'delete',
    params: params
  });
}

// 角色详情
function getRoleDetail(params) {
  return (0, _request.default)({
    url: '/resource/rbac/role/detail',
    method: 'get',
    params: params
  });
}

// 新增角色
function addRole(data) {
  return (0, _request.default)({
    url: "/resource/rbac/role",
    method: 'post',
    data: data
  });
}

// 修改角色
function updateRole(data) {
  return (0, _request.default)({
    url: "/resource/rbac/role/update",
    method: 'post',
    data: data
  });
}

/**********权限*************/
// 权限列表查看，增加，删除
function getPermission(method, params, data) {
  return (0, _request.default)({
    url: "/resource/rbac/permission",
    method: method,
    params: params,
    data: data
  });
}
// 修改权限
function updatePermission(data) {
  return (0, _request.default)({
    url: "/resource/rbac/permission/update",
    method: 'post',
    data: data
  });
}

// 获取操作日志
function getOperateLog(params) {
  return (0, _request.default)({
    url: '/resource/rbac/operating',
    method: 'get',
    params: params
  });
}