"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.object.freeze");
var _yunying = require("@/api/yunying");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      h5LineValue: '',
      h5Line: [{
        label: '在线测算',
        value: '在线测算'
      }],
      loading: true,
      tableData: [],
      searchInput: '',
      // 搜索输入
      searchAppid: '',
      // 搜索输入
      chooseData: '',
      // 当前选择项的全部参数 {}
      tablePage: {
        per_page: 30,
        current: 1,
        total_page: 1,
        total_num: 1
      }
    };
  },
  mounted: function mounted() {
    this.getH5List();
  },
  methods: {
    getH5List: function getH5List() {
      var _this = this;
      var params = {
        per_page: this.tablePage.per_page,
        current: this.tablePage.current,
        desc: this.searchInput,
        appid: this.searchAppid
      };
      (0, _yunying.getH5List)(params).then(function (_ref) {
        var data = _ref.data;
        _this.loading = false;
        _this.tableData = Object.freeze(data.list);
        _this.tablePage = {
          per_page: data.page.per_page,
          current: data.page.current,
          total_page: data.page.total_page,
          total_num: data.page.count
        };
      });
    },
    // 分页
    handleCurrentChange: function handleCurrentChange(page) {
      this.tablePage.current = page;
      this.getH5List();
    },
    onSearch: function onSearch() {
      this.getH5List();
    },
    onAdd: function onAdd() {
      window.open('https://tec.linghit.io/admin/#/tools/projectRecord', '_blank');
    },
    // 查看测算链接
    checkLink: function checkLink(link) {
      window.open(link, '_blank');
    },
    onCopy: function onCopy(e) {
      this.$message({
        type: 'success',
        message: '复制成功!',
        duration: 1000
      });
    },
    onError: function onError(e) {
      this.$message.error('复制失败!');
    },
    onClear: function onClear() {
      this.getH5List();
    },
    upDateData: function upDateData() {
      var _this2 = this;
      this.loading = true;
      (0, _yunying.upDateH5List)().then(function (data) {
        _this2.loading = false;
        _this2.$message({
          message: '更新成功',
          type: 'success'
        });
      });
    }
  }
};