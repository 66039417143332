"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  device: function device(state) {
    return state.app.device;
  },
  token: function token(state) {
    return state.user.token;
  },
  avatar: function avatar(state) {
    return state.user.avatar;
  },
  realname: function realname(state) {
    return state.user.realname;
  },
  userInfo: function userInfo(state) {
    return state.user.userInfo;
  },
  userInfoPermission: function userInfoPermission(state) {
    return state.user.userInfoPermission;
  },
  permissionList: function permissionList(state) {
    return state.user.permissionList;
  },
  img_group_list: function img_group_list(state) {
    return state.common.img_group_list;
  }
};
var _default = exports.default = getters;