"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _validate = require("@/utils/validate");
var _yunying = require("@/api/yunying");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    url_config: {
      type: Object,
      default: function _default() {
        return {
          url_rule: 'rule',
          // rule:域名库+路径拼接  norule:自定义链接
          url_id: '',
          // (rule) 域名id (与 url_name 配套)
          url_name: '',
          // (rule) 域名 (与 url_id 配套,使用时不需要传入，有url_id会自动填充)
          path: '',
          // (rule) 路径
          path_norule: '' // (norule) 自定义链接
        };
      }
    },
    labelTitle: {
      // 标题
      type: String,
      default: function _default() {
        return '链接';
      }
    },
    labelWidth: {
      // 标题宽度
      type: String,
      default: function _default() {
        return '60px';
      }
    },
    labelPosition: {
      // 标题位置
      type: String,
      default: function _default() {
        return 'left';
      }
    }
  },
  data: function data() {
    var validatePath = function validatePath(rule, value, callback) {
      if (value === '') {
        callback(new Error('请输入路径!'));
      } else if (!(0, _validate.isUrlPath)(value)) {
        callback(new Error('路径格式不正确!（参考例子：/mllyuncheng/index ）'));
      } else {
        callback();
      }
    };
    var validatePathNorule = function validatePathNorule(rule, value, callback) {
      if (value === '') {
        callback(new Error('请输入链接！'));
      } else if (!(0, _validate.isCorrectUrl)(value)) {
        callback(new Error('链接格式不正确！'));
      } else {
        callback();
      }
    };
    return {
      rules: {
        domain: {
          required: true,
          message: '请选择域名',
          trigger: 'blur'
        },
        path: {
          required: true,
          validator: validatePath,
          trigger: 'blur'
        },
        path_norule: {
          required: true,
          validator: validatePathNorule,
          trigger: 'blur'
        }
      },
      domainList: [],
      // 域名列表
      pathPlaceholder: '请输入路径（例如: /mllyuncheng/index ）'
    };
  },
  mounted: function mounted() {
    this.init();
  },
  methods: {
    // 初始化下拉框数据
    init: function init() {
      var _this = this;
      (0, _yunying.domainList)().then(function (_ref) {
        var data = _ref.data;
        _this.domainList = data;
      });
    },
    // 数据校验 （提供给调用的组件使用）
    validateData: function validateData() {
      var isValidate = false;
      this.$refs['urlRuleForm'].validate(function (valid) {
        if (valid) {
          isValidate = true;
        } else {
          isValidate = false;
        }
      });
      return isValidate;
    },
    // 清空表单  (提供给调用的组件使用)
    resetForm: function resetForm() {
      this.$refs.urlRuleForm.resetFields();
    },
    // 域名选择
    handleChange: function handleChange(id) {
      var url_config = this.url_config,
        domainList = this.domainList;
      domainList.map(function (v, k) {
        if (v.id === id) {
          url_config.url_name = v.production_url;
        }
      });
    },
    // 验证链接
    checkUrl: function checkUrl() {
      var _this$url_config = this.url_config,
        url_name = _this$url_config.url_name,
        path = _this$url_config.path;
      window.open("".concat(url_name).concat(path), '_blank');
    }
  }
};