"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.string.link");
var _lodash = _interopRequireDefault(require("lodash"));
var _yunying = require("@/api/yunying");
var _imageUpload = _interopRequireDefault(require("@/components/image-upload"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  components: {
    ImageUpload: _imageUpload.default
  },
  props: {
    manageType: {},
    ruleForm: {
      type: Object,
      default: function _default() {
        return {
          type: 3,
          qrcode_remarks: '',
          department: '',
          subject: '',
          qrcode_sign: '',
          status: 1,
          qrlink: '',
          qrcode_id: ''
        };
      }
    },
    imageUploadData: {
      type: Object,
      default: function _default() {
        return {
          img_url: '' // 图片上传 - 图片链接
        };
      }
    },
    type: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    wechatConstantData: {
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    var _this = this;
    return {
      dialogVisible: false,
      rules: {
        type: {
          required: true,
          message: '请选择导粉号类型',
          trigger: 'blur'
        },
        qrcode_id: {
          required: false,
          message: '请输入活码ID',
          trigger: 'blur'
        },
        qrcode_remarks: {
          required: false,
          message: '请输入活码标识（中文）',
          trigger: 'change'
        },
        department: {
          required: false,
          message: '请选择部门',
          trigger: 'blur'
        },
        subject: {
          required: false,
          message: '请选择主体',
          trigger: 'blur'
        },
        qrcode_sign: {
          required: false,
          trigger: 'change',
          validator: function validator(rule, value, callback) {
            if (!value && _this.fromData.type !== 3) {
              callback(new Error('请输入微信号'));
            } else {
              callback();
            }
          }
        },
        status: {
          required: false,
          message: '请选择状态',
          trigger: 'blur'
        },
        qrlink: {
          type: 'string',
          required: false,
          trigger: 'blur',
          validator: function validator(rule, value, callback) {
            if (!value) {
              callback(new Error('请输入获客链接'));
            } else if (value.indexOf(' ') > -1) {
              callback(new Error('请输入正确获客链接，删除多余空格'));
            } else {
              callback();
            }
          }
        }
      },
      fromData: {
        type: 3,
        qrcode_remarks: '',
        department: 0,
        subject: 0,
        qrcode_sign: '',
        status: 1,
        qrlink: '',
        qrcode_id: ''
      },
      imgUrl: {
        img_url: ''
      },
      imgUrlRequired: false,
      disabled: false,
      fullscreenLoading: false
    };
  },
  watch: {
    ruleForm: function ruleForm(val) {
      this.fromData = _lodash.default.cloneDeep(val);
    },
    imageUploadData: function imageUploadData(val) {
      this.imgUrl = _lodash.default.cloneDeep(val);
    },
    dialogVisible: function dialogVisible(val) {
      // 个人微信和公众号是必填
      if (this.fromData.type !== 3) {
        this.setRequired(true);
        this.disabled = false;
      } else {
        this.setRequired(false);
        this.disabled = false;
      }
      if (val) {
        //新建企业微信必填活码 id，修改企业微信活码 id 存在则必填
        this.rules.qrcode_id.required = this.manageType === 'add' || this.fromData.qrcode_id;
        if (this.manageType === 'modify' && this.fromData.qrcode_id) {
          // 编辑时，存在活码 设置不可编辑
          this.disabled = true;
        }
      }
    }
  },
  methods: {
    onCancel: function onCancel() {
      this.dialogVisible = false;
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;
      var imgFlag = this.$refs.imageUpload.validateData();
      this.$refs[formName].validate(function (valid) {
        if (valid && imgFlag) {
          _this2.handleEvent();
        } else {
          return false;
        }
      });
    },
    handleEvent: function handleEvent() {
      var _this3 = this;
      var manageType = this.manageType,
        fromData = this.fromData,
        imgUrl = this.imgUrl;
      var params = {
        type: fromData.type,
        // 类型
        qrcode_id: fromData.qrcode_id || '',
        // 活码
        department: fromData.department || 0,
        // 部门
        subject: fromData.subject || 0,
        // 主体
        qrcode: imgUrl.img_url || '',
        // 二维码链接
        qrlink: fromData.qrlink || '',
        // 链接
        status: fromData.status,
        // 状态
        qrcode_sign: fromData.qrcode_sign || '',
        // 英文标识 原来的 wechat
        qrcode_remarks: fromData.qrcode_remarks || '' // 中文标识 原来的 name
      };
      if (manageType == 'add') {
        (0, _yunying.addWechat)(params).then(function (_ref) {
          var code = _ref.code;
          if (code === 200) {
            _this3.$message({
              message: '添加成功',
              type: 'success'
            });
            _this3.dialogVisible = false;
            _this3.$parent.getWxComponentList(); // 更新父组件数据
          }
        });
      } else {
        (0, _yunying.modifyWechat)(fromData.id, params).then(function (_ref2) {
          var code = _ref2.code;
          if (code === 200) {
            _this3.$message({
              message: '修改成功',
              type: 'success'
            });
            _this3.dialogVisible = false;
            _this3.$parent.getWxComponentList(); // 更新父组件数据
          }
        });
      }
    },
    handleType: function handleType(val) {
      // 个人微信 和 公众号是必填的
      if (val === 1 || val === 2) {
        this.$set(this.fromData, 'subject', 0);
        this.setRequired(true);
      } else {
        this.setRequired(false);
      }
    },
    getlivecodeData: function getlivecodeData() {
      var _this4 = this;
      if (this.fromData.qrcode_id) {
        var loading = this.$loading({
          lock: true,
          text: 'Loading',
          customClass: 'abc',
          background: 'rgba(255, 255, 255, 0.8)'
        });
        setTimeout(function () {}, 2000);
        (0, _yunying.getlivecodeData)(this.fromData.qrcode_id).then(function (_ref3) {
          var data = _ref3.data;
          // qrcode_remarks 中文标识
          // team_id 部门
          // corp_id 主体
          // qrcode_sign 拼音标识
          // qrcode_url 二维码图片
          // link 获客链接
          var qrcode_remarks = data.qrcode_remarks,
            qrcode_sign = data.qrcode_sign,
            qrcode_url = data.qrcode_url,
            link = data.link,
            team_id = data.team_id,
            corp_id = data.corp_id;
          _this4.$set(_this4.fromData, 'qrlink', link);
          _this4.$set(_this4.fromData, 'qrcode_remarks', qrcode_remarks);
          _this4.$set(_this4.fromData, 'qrcode_sign', qrcode_sign);
          _this4.$set(_this4.fromData, 'subject', corp_id);
          _this4.$set(_this4.fromData, 'department', team_id);
          _this4.imgUrl.img_url = qrcode_url;
          _this4.disabled = true; // 点击获取以后不可编辑
          loading.close();
        }).catch(function () {
          loading.close();
        });
      } else {
        this.$message.error('请输入活码ID');
      }
    },
    setRequired: function setRequired(bol) {
      this.rules.type.required = bol;
      this.rules.qrcode_remarks.required = bol;
      this.rules.department.required = bol;
      this.rules.subject.required = bol;
      this.rules.qrcode_sign.required = bol;
      this.rules.status.required = bol;
      this.rules.qrlink.required = bol;
      this.imgUrlRequired = bol;
    },
    qrcodeIdChange: function qrcodeIdChange(val) {
      if (!val) {
        this.disabled = false;
      }
    }
  }
};