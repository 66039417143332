var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.manageType === "add" ? "新增产品配置" : "修改产品配置",
            visible: _vm.dialogVisible,
            width: "60%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-position": "left",
                "label-width": "130px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "名称", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入配置标签名称",
                      disabled: true,
                    },
                    model: {
                      value: _vm.ruleForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "name", $$v)
                      },
                      expression: "ruleForm.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "配置值", prop: "value" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入配置值" },
                    model: {
                      value: _vm.ruleForm.value,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "value", $$v)
                      },
                      expression: "ruleForm.value",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "产品ID", prop: "product_id" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        placeholder:
                          "请输入产品ID（若要匹配所有产品则选择all）",
                      },
                      model: {
                        value: _vm.ruleForm.product_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "product_id", $$v)
                        },
                        expression: "ruleForm.product_id",
                      },
                    },
                    _vm._l(_vm.all_products, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          label: item.name + "（" + item.id + "）",
                          value: item.id,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "渠道", prop: "channel_id" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", placeholder: "请输入渠道" },
                    model: {
                      value: _vm.ruleForm.channel_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "channel_id", $$v)
                      },
                      expression: "ruleForm.channel_id",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "渠道匹配模式", prop: "mode" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.mode,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "mode", $$v)
                        },
                        expression: "ruleForm.mode",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "fuzzy" } }, [
                        _vm._v("模糊匹配"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "full" } }, [
                        _vm._v("精准匹配"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.onCancel } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("ruleForm")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }