"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _comChannel = _interopRequireDefault(require("../components/com-channel"));
var _comRegChannels = _interopRequireDefault(require("../components/com-reg-channels"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  components: {
    ComChannel: _comChannel.default,
    ComRegChannels: _comRegChannels.default
  },
  props: {
    propData: {
      type: Object,
      default: function _default() {
        return {
          default_visible: 'none',
          // 全渠道状态 enable-显示 disable-隐藏 none-空值（用于单个测算，none的情况会继承公共的）
          channel_item: [],
          //可见状态渠道 ['swchannel']
          // 以下为新增字段，待接口完成再做调整
          reg_channel_group: [],
          // 可见状态渠道组
          shield_channel: [],
          // 不可见状态渠道
          shield_reg_channel_group: [] // 不可见状态渠道组
        };
      }
    },
    update: {
      // 数据更新
      type: Function,
      default: function _default() {
        return function () {};
      }
    },
    type: ''
  },
  computed: (0, _vuex.mapState)({
    reg_channels: function reg_channels(state) {
      return state.common.reg_channels;
    }
  }),
  watch: {
    propData: {
      handler: function handler() {
        this.update();
      },
      deep: true
    }
  },
  data: function data() {
    return {
      dialogVisible: false
      // 全渠道状态说明
    };
  }
};