"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _lodash = _interopRequireDefault(require("lodash"));
var _validate = require("@/utils/validate");
var _yunying = require("@/api/yunying");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      rules: {
        channels: {
          required: true,
          message: '请选择渠道',
          trigger: 'blur'
        }
      },
      ruleForm: {
        channels: '' // 主渠道(选中的)
      },
      sub_channel: [],
      // 子渠道（选中的）
      optionsList: [],
      // 主渠道列表（接口数据）
      loading: false,
      sub_per_page: 200 // 子渠道请求数量 （与后端协商过，太大请求失败，后续如果子渠道量大，需要优化这个交互逻辑）
    };
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    type: function type(val) {
      var attr = '商务渠道';
      switch (val) {
        case 1:
          attr = '商务渠道';
          break;
        case 2:
          attr = '投放媒体';
          break;
        case 3:
          attr = '流量中心';
          break;
        case 4:
          attr = '增长中心';
          break;
        case 5:
          attr = '运营中心';
          break;
        case 6:
          attr = '平台中心';
          break;
        default:
          break;
      }
      return attr;
    },
    attribute: function attribute(val) {
      var attr = '外部流量';
      switch (val) {
        case 'outside':
          attr = '外部流量';
          break;
        case 'inside':
          attr = '内部流量';
          break;
        default:
          break;
      }
      return attr;
    },
    // 初始化主渠道列表
    initData: function initData() {
      var _this = this;
      var data = {
        page: 1,
        per_page: 2000
      };
      (0, _yunying.mainChannelList)(data).then(function (res) {
        _this.optionsList = res.data.list;
      });
    },
    // 下拉框选中的值
    changeSelect: function changeSelect(val) {
      var sub_channel = this.sub_channel;
      // 对比主次渠道
      var diffMainVal = val.map(function (item) {
        return item;
      });
      var diffSubChannel = sub_channel.map(function (item) {
        return "".concat(item.mainchannel, ",").concat(item.mainname);
      });
      // 主次渠道差集部分 （需变更）
      var diffArray = _lodash.default.xorWith(diffMainVal, diffSubChannel);
      for (var i = 0; i < diffArray.length; i++) {
        if (diffSubChannel.indexOf(diffArray[i]) == -1) {
          // 差集不存在于次渠道,说明（主有次无）需新增
          this.addSubChannel(diffArray[i]);
        } else {
          // 如果存在，需再次对比主渠道（主渠道也有，说明主次一致，不变；主渠道没有，说明次渠道多余，需删除）
          if (diffMainVal.indexOf(diffArray[i]) == -1) {
            this.removeSelect(diffArray[i]);
          }
        }
      }
    },
    // 次渠道列表新增
    addSubChannel: function addSubChannel(addItem) {
      var _this2 = this;
      var data = {
        page: 1,
        per_page: this.sub_per_page,
        channel: addItem.split(',')[0],
        name: addItem.split(',')[1],
        sub_name: '',
        sub_channel: ''
      };
      var nodes = [];
      this.loading = true;
      // 根据主渠道，请求改主渠道下所有子渠道
      (0, _yunying.childChannelList)(data).then(function (res) {
        var _res$data = res.data,
          list = _res$data.list,
          page = _res$data.page;
        list.map(function (item) {
          nodes.push(item.show_channel);
        });
        _this2.sub_channel.push({
          mainchannel: addItem.split(',')[0],
          // 主渠道
          mainname: addItem.split(',')[1],
          // 主渠道名
          sub_channel_list: list,
          // 子渠道列表
          nodes: nodes,
          // 选中的子渠道（默认全部选中）
          page: page // 分页数据
        });
        _this2.pushChannelsData();
        _this2.loading = false;
      });
    },
    // 分页 （数据多的渠道项）
    handleCurrentChange: function handleCurrentChange(event, valObj, index) {
      var _this3 = this;
      var sub_channel = this.sub_channel;
      var mainname = valObj.mainname,
        mainchannel = valObj.mainchannel;
      this.loading = true;
      // 请求子渠道分页数据
      var data = {
        page: event,
        per_page: this.sub_per_page,
        name: mainname,
        channel: mainchannel,
        sub_name: '',
        sub_channel: ''
      };
      var nodes = [];
      (0, _yunying.childChannelList)(data).then(function (res) {
        var _res$data2 = res.data,
          list = _res$data2.list,
          page = _res$data2.page;
        list.map(function (item) {
          nodes.push(item.show_channel);
        });
        _this3.sub_channel[index].sub_channel_list = list;
        _this3.sub_channel[index].nodes = nodes;
        _this3.loading = false;
      });
    },
    // 删除下拉框的值
    removeSelect: function removeSelect(val) {
      var _this4 = this;
      // 删除对应的子渠道
      this.sub_channel.some(function (item, index) {
        if (val.split(',')[0] === item.mainchannel) {
          _this4.sub_channel.splice(index, 1);
        }
      });
      this.pushChannelsData();
    },
    // 子渠道多选
    changeCheckbox: function changeCheckbox(main, nodes) {
      var _this5 = this;
      var currentMain = "".concat(main.mainchannel, ",").concat(main.mainname);
      if (nodes.length != 0) {
        return;
      }
      // 删除主渠道
      this.ruleForm.channels.splice(this.ruleForm.channels.indexOf(currentMain), 1);
      // 删除子渠道
      this.sub_channel.some(function (item, index) {
        if (main.mainchannel === item.mainchannel) {
          _this5.sub_channel.splice(index, 1);
        }
      });
      this.pushChannelsData();
    },
    // 传递数据
    pushChannelsData: function pushChannelsData() {
      var sub_channel = this.sub_channel,
        channelData = [];
      sub_channel.map(function (item) {
        item.nodes.map(function (item) {
          channelData.push(item);
        });
      });
      this.$emit('getChannelsData', channelData);
    },
    // 数据校验
    validateData: function validateData() {
      var isValidate = false;
      this.$refs['RuleForm'].validate(function (valid) {
        if (valid) {
          isValidate = true;
        } else {
          isValidate = false;
        }
      });
      return isValidate;
    }
  }
};