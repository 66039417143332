var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.manageTitle,
        visible: _vm.dialogVisible,
        width: "60%",
        center: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-position": "left",
            "label-width": "100px",
          },
        },
        [
          _vm.isAddOrModify
            ? _c(
                "el-form-item",
                { attrs: { label: "产品ID", prop: "product_id" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        placeholder:
                          "请输入产品ID（若要匹配所有产品则选择all）",
                      },
                      model: {
                        value: _vm.ruleForm.product_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "product_id", $$v)
                        },
                        expression: "ruleForm.product_id",
                      },
                    },
                    _vm._l(_vm.all_products, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          label:
                            "" +
                            item.name +
                            (item.app_id ? " " + item.app_id : ""),
                          value: item.id,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isAddOrModify
            ? _c(
                "el-form-item",
                { attrs: { label: "渠道", prop: "channel_id" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", placeholder: "请输入渠道" },
                    model: {
                      value: _vm.ruleForm.channel_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "channel_id", $$v)
                      },
                      expression: "ruleForm.channel_id",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isAddOrModify
            ? _c(
                "el-form-item",
                { attrs: { label: "渠道匹配", prop: "mode" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.mode,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "mode", $$v)
                        },
                        expression: "ruleForm.mode",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("模糊匹配"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("精准匹配"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.manageType == "modifyMulti"
            ? _c(
                "el-form-item",
                { attrs: { label: "操作类型", prop: "modifyMultiType" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.modifyMultiType,
                        callback: function ($$v) {
                          _vm.modifyMultiType = $$v
                        },
                        expression: "modifyMultiType",
                      },
                    },
                    _vm._l(_vm.modifyMultiList, function (item, index) {
                      return _c(
                        "el-radio",
                        { key: index, attrs: { label: item.value } },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(item.label) +
                              "\n                "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showConfigType
            ? _c(
                "el-form-item",
                { attrs: { label: "配置类型", prop: "class" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { filterable: "", placeholder: "请选择配置类型" },
                      on: { change: _vm.onConfigTypeChange },
                      model: {
                        value: _vm.ruleForm.class,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "class", $$v)
                        },
                        expression: "ruleForm.class",
                      },
                    },
                    _vm._l(_vm.configTypeList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          (_vm.manageType === "modifyAll" || _vm.manageType === "add") &&
          _vm.ruleForm.class >= 0
            ? _c(
                "el-form-item",
                { attrs: { label: "操作" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.changeState,
                        callback: function ($$v) {
                          _vm.changeState = $$v
                        },
                        expression: "changeState",
                      },
                    },
                    _vm._l(_vm.changeStateList, function (item, index) {
                      return _c(
                        "el-radio",
                        {
                          key: index,
                          attrs: {
                            label: item.value,
                            disabled: _vm.manageType !== "modifyAll",
                          },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(item.label) +
                              "\n                "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showConfigValue
            ? _c(
                "el-form-item",
                { attrs: { label: "配置值" } },
                [
                  _c(
                    "el-card",
                    {
                      attrs: {
                        "body-style": "background: #f7f8fa",
                        shadow: "never",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticStyle: { "padding-bottom": "20px" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-plus",
                                disabled: _vm.isConfigDisabled,
                              },
                              on: { click: _vm.addPattern },
                            },
                            [_vm._v("添加模型和比例")]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticStyle: { "padding-left": "20px" } },
                            [_vm._v("总比例：" + _vm._s(_vm.totalRatio) + "%")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.ratioList, function (ratioItem, ratioIndex) {
                        return _c(
                          "el-form",
                          {
                            key: ratioItem.id,
                            staticStyle: { "margin-bottom": "10px" },
                            attrs: { inline: true },
                          },
                          [
                            _c(
                              "el-form-item",
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "width-250",
                                    attrs: {
                                      placeholder: "选择或填写模型",
                                      filterable: "",
                                      disabled: _vm.isConfigDisabled,
                                    },
                                    model: {
                                      value: ratioItem.value,
                                      callback: function ($$v) {
                                        _vm.$set(ratioItem, "value", $$v)
                                      },
                                      expression: "ratioItem.value",
                                    },
                                  },
                                  _vm._l(_vm.patternList, function (item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "比例" } },
                              [
                                _c("el-input-number", {
                                  staticClass: "width-150",
                                  attrs: {
                                    min: 0,
                                    max: 100,
                                    precision: 0,
                                    label: "比例",
                                    disabled: _vm.isConfigDisabled,
                                  },
                                  model: {
                                    value: ratioItem.weight,
                                    callback: function ($$v) {
                                      _vm.$set(ratioItem, "weight", $$v)
                                    },
                                    expression: "ratioItem.weight",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "text",
                                      disabled: _vm.isConfigDisabled,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deletePattern(ratioIndex)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            删除\n                        "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.onCancel } }, [_vm._v("取 消")]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", disabled: _vm.isSubmitButtonDisabled },
              on: {
                click: function ($event) {
                  return _vm.submitForm("ruleForm")
                },
              },
            },
            [_vm._v("\n            确 定\n        ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }