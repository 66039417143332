"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addConfigTag = addConfigTag;
exports.addProductConfig = addProductConfig;
exports.editConfigTag = editConfigTag;
exports.editProductConfig = editProductConfig;
exports.getConfigTagDetail = getConfigTagDetail;
exports.getConfigTags = getConfigTags;
exports.getProductConfigList = getProductConfigList;
exports.getProductDetail = getProductDetail;
exports.getProducts = getProducts;
var _request = _interopRequireDefault(require("@/utils/request"));
// 后端配置（主要提供给后端操作）
// 文档：https://yapi.linghit.cn/project/755/interface/api/33703

/**
 * 测算列表
 * @param {string} from 来自哪里,例如from = 'tf'代表来自投放独立项测算
 */
function getProducts(from) {
  return (0, _request.default)({
    url: '/resource/product',
    method: 'get',
    params: {
      from: from
    }
  });
}

/**
 * 测算详情
 * @param {string} product_id 产品id
 */
function getProductDetail(product_id) {
  return (0, _request.default)({
    url: "/resource/product/".concat(product_id),
    method: 'get',
    params: {
      product_id: product_id
    }
  });
}

/**
 * 产品配置标签
 * @param {object} params
 * params 包含：
 *   name-标签
 *   title-名称（非必需）
 *   per_page-每页数量（非必需）
 *   page-当前页码（非必需）
 */
function getConfigTags() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/resource/product/config_tag',
    method: 'get',
    params: params
  });
}

/**
 * 产品配置标签详情
 * @param {string} id 产品配置标签id
 */
function getConfigTagDetail(id, params) {
  return (0, _request.default)({
    url: "/resource/product/config_tag/".concat(id),
    method: 'get',
    params: params
  });
}

/**
 * 创建产品配置标签
 * @param {object} data
 * data 包含：
 *   name-标签
 *   title-名称
 *   remark-备注
 */
function addConfigTag() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/resource/product/config_tag',
    method: 'post',
    data: data
  });
}

/**
 * 编辑产品配置标签
 * @param {string} id 标签id
 * @param {object} data
 * data 包含：
 *   name-标签
 *   title-名称
 *   remark-备注
 */
function editConfigTag(id) {
  var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return (0, _request.default)({
    url: "/resource/product/config_tag/".concat(id),
    method: 'post',
    data: data
  });
}

/**
 * 产品配置列表
 * @param {object} params
 * params 包含:
 *   product_id-产品id
 *   name-名称
 *   channel-渠道
 *   per_page-每页数量
 *   page-当前页码
 */
function getProductConfigList() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/resource/product/config',
    method: 'get',
    params: params
  });
}

/**
 * 创建产品配置
 * @param {object} data
 * data 包含：
 *   name-配置标签名称
 *   value-配置值
 *   channel_id-渠道
 *   product_id-产品ID，所有产品则为：all
 *   mode-模式：模糊匹配- fuzzy 精准匹配 - full
 */
function addProductConfig(data) {
  return (0, _request.default)({
    url: '/resource/product/config',
    method: 'post',
    data: data
  });
}

/**
 * 修改产品配置
 * @param {string} id
 * @param {object} data
 * data 包含：
 *   name-配置标签名称
 *   value-配置值
 *   channel_id-渠道
 *   product_id-产品ID，所有产品则为：all
 *   mode-模式：模糊匹配- fuzzy 精准匹配 - full
 */
function editProductConfig(id, data) {
  return (0, _request.default)({
    url: "/resource/product/config/".concat(id),
    method: 'post',
    data: data
  });
}