"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _comComponent = _interopRequireDefault(require("../../common/com-component"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  components: {
    ComComponent: _comComponent.default
  },
  props: {
    id: {
      default: function _default() {
        return '7-2';
      }
    },
    keyId: {}
  },
  methods: {
    onLocation: function onLocation(url_config) {
      this.$refs.comComponent.onLocation(url_config);
    }
  }
};