"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addWechatAppConfig = addWechatAppConfig;
exports.editWechatAppConfig = editWechatAppConfig;
exports.getWechatAppDetail = getWechatAppDetail;
exports.getWechatAppLists = getWechatAppLists;
var _request = _interopRequireDefault(require("@/utils/request"));
/**
 * 微信应用配置
 * https://yapi.linghit.cn/project/755/interface/api/cat_7471
 */

/**
 * https://yapi.linghit.cn/project/755/interface/api/49570
 * 获取应用列表
 * @param {object} params
 * @returns
 */
function getWechatAppLists() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/resource/wxapp',
    method: 'get',
    params: params
  });
}

/**
 * https://yapi.linghit.cn/project/755/interface/api/49579
 * 获取应用详情
 * @param {string} id
 * @returns
 */
function getWechatAppDetail(id) {
  return (0, _request.default)({
    url: "/resource/wxapp/".concat(id),
    method: 'get'
  });
}

/**
 * https://yapi.linghit.cn/project/755/interface/api/49588
 * 创建应用
 * @param {object} data
 * @returns
 */
function addWechatAppConfig() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/resource/wxapp',
    method: 'post',
    data: data
  });
}

/**
 * https://yapi.linghit.cn/project/755/interface/api/49597
 * 修改应用配置
 * @param {string} id
 * @param {object} data
 * @returns
 */
function editWechatAppConfig(id) {
  var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return (0, _request.default)({
    url: "/resource/wxapp/".concat(id),
    method: 'post',
    data: data
  });
}