"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
var _user = require("@/api/user");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    manageType: {},
    formData: {
      type: Object,
      default: {
        return: {
          id: '',
          // 修改项id
          desc: '',
          method: '',
          route: '',
          front_route: ''
        }
      }
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      selectOptions: [{
        value: 'ANY',
        label: 'ANY'
      }, {
        value: 'GET',
        label: 'GET'
      }, {
        value: 'POST',
        label: 'POST'
      }, {
        value: 'PUT',
        label: 'PUT'
      }, {
        value: 'DELETE',
        label: 'DELETE'
      }],
      rules: {
        desc: [{
          required: true,
          message: '请填写权限名称',
          trigger: 'blur'
        }],
        method: [{
          required: true,
          message: 'HTTP方式',
          trigger: 'blur'
        }],
        route: [{
          required: true,
          message: '请填写接口路由',
          trigger: 'blur'
        }],
        front_route: [{
          required: true,
          message: '请填写前端路由',
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    cancel: function cancel() {
      this.dialogVisible = false;
    },
    submitHandle: function submitHandle() {
      var _this = this;
      this.$refs.rulesForm.validate(function (valid) {
        if (!valid) return;
        var formData = _this.formData,
          manageType = _this.manageType;
        var params = {
          method: formData.method.toString(),
          desc: formData.desc,
          route: formData.route,
          front_route: formData.front_route
        };
        // 新增
        if (manageType === 'add') {
          (0, _user.getPermission)('post', '', params).then(function (res) {
            _this.$message({
              type: 'success',
              message: '提交成功!'
            });
            _this.dialogVisible = false;
            _this.$parent.getTableData();
          });
        } else {
          // 修改
          params.id = formData.id;
          _this.$confirm('您确定修改该权限信息吗', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(function () {
            (0, _user.updatePermission)(params).then(function (res) {
              _this.$message({
                type: 'success',
                message: '修改成功!'
              });
              _this.dialogVisible = false;
              _this.$parent.getTableData();
            });
          });
        }
      });
    }
  }
};