import { render, staticRenderFns } from "./com-style-type.vue?vue&type=template&id=2c2b0012&scoped=true"
import script from "./com-style-type.vue?vue&type=script&lang=js"
export * from "./com-style-type.vue?vue&type=script&lang=js"
import style0 from "./com-style-type.vue?vue&type=style&index=0&id=2c2b0012&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c2b0012",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2c2b0012')) {
      api.createRecord('2c2b0012', component.options)
    } else {
      api.reload('2c2b0012', component.options)
    }
    module.hot.accept("./com-style-type.vue?vue&type=template&id=2c2b0012&scoped=true", function () {
      api.rerender('2c2b0012', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/modulestore/components/com-style-type.vue"
export default component.exports