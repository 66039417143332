"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _backend = require("@/api/backend");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    manageType: {
      type: String,
      default: ''
    },
    ruleForm: {
      type: Object,
      default: function _default() {
        return {
          title: '',
          // 标题
          name: '',
          // 名称
          remark: '' // 备注
        };
      }
    }
  },
  data: function data() {
    var validatePass = function validatePass(rule, value, callback) {
      if (!value || value.trim() === '') {
        callback(new Error(rule.message));
      } else if (/^\d$/.test) {
        callback();
      }
    };
    return {
      dialogVisible: false,
      rules: {
        title: {
          validator: validatePass,
          required: true,
          message: '请输入标题',
          trigger: 'blur'
        },
        name: {
          validator: validatePass,
          required: true,
          message: '请输入名称',
          trigger: 'blur'
        },
        remark: {
          validator: validatePass,
          required: true,
          message: '请输入备注',
          trigger: 'blur'
        }
      }
    };
  },
  methods: {
    onCancel: function onCancel() {
      this.dialogVisible = false;
    },
    submitForm: function submitForm(formName) {
      var _this = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this.handleEvent();
        } else {
          return false;
        }
      });
    },
    handleEvent: function handleEvent() {
      var _this2 = this;
      var _this$ruleForm = this.ruleForm,
        id = _this$ruleForm.id,
        title = _this$ruleForm.title,
        name = _this$ruleForm.name,
        remark = _this$ruleForm.remark;
      var meta = this.$route.meta;
      var params = {
        title: title,
        name: name,
        remark: remark
      };
      if (meta.from) {
        params.from = meta.from;
      }
      if (this.manageType === 'add') {
        (0, _backend.addConfigTag)(params).then(function (_ref) {
          var code = _ref.code;
          if (code === 200) {
            _this2.$message({
              message: '添加成功',
              type: 'success'
            });
            _this2.dialogVisible = false;
            _this2.$parent.getList(); // 更新父组件数据
          }
        });
      } else {
        (0, _backend.editConfigTag)(id, params).then(function (_ref2) {
          var code = _ref2.code;
          if (code === 200) {
            _this2.$message({
              message: '修改成功',
              type: 'success'
            });
            _this2.dialogVisible = false;
            _this2.$parent.getList(); // 更新父组件数据
          }
        });
      }
    }
  }
};