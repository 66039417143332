"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addCenterChannels = addCenterChannels;
exports.addDomain = addDomain;
exports.addGoods = addGoods;
exports.addGoodsGroup = addGoodsGroup;
exports.addImgGroup = addImgGroup;
exports.addImgType = addImgType;
exports.addPageFlag = addPageFlag;
exports.addRegChannels = addRegChannels;
exports.addTeacher = addTeacher;
exports.addTextImg = addTextImg;
exports.addWechat = addWechat;
exports.addWorkWechat = addWorkWechat;
exports.addWxComponent = addWxComponent;
exports.addWxConfig = addWxConfig;
exports.addWxGroup = addWxGroup;
exports.addWxModule = addWxModule;
exports.appGroup = appGroup;
exports.appManage = appManage;
exports.appVersion = appVersion;
exports.appVersionGroup = appVersionGroup;
exports.centerChannelsInfo = centerChannelsInfo;
exports.centerChannelsList = centerChannelsList;
exports.childChannelList = childChannelList;
exports.createPage = createPage;
exports.deleteCenterChannels = deleteCenterChannels;
exports.deleteDomain = deleteDomain;
exports.deleteImage = deleteImage;
exports.deletePage = deletePage;
exports.deleteRegChannels = deleteRegChannels;
exports.deleteTextImg = deleteTextImg;
exports.deleteWxComponent = deleteWxComponent;
exports.deleteWxConfig = deleteWxConfig;
exports.deleteWxModule = deleteWxModule;
exports.domainList = domainList;
exports.editRegChannels = editRegChannels;
exports.getDefaultPageConfig = getDefaultPageConfig;
exports.getDefaultPageList = getDefaultPageList;
exports.getGoods = getGoods;
exports.getGoodsGroup = getGoodsGroup;
exports.getGoodsProp = getGoodsProp;
exports.getH5List = getH5List;
exports.getPageConfig = getPageConfig;
exports.getPageFlag = getPageFlag;
exports.getPageList = getPageList;
exports.getTeacher = getTeacher;
exports.getWorkWxList = getWorkWxList;
exports.getWxComponentList = getWxComponentList;
exports.getWxConfigChannel = getWxConfigChannel;
exports.getWxConfigList = getWxConfigList;
exports.getWxGroup = getWxGroup;
exports.getWxList = getWxList;
exports.getWxModuleDetail = getWxModuleDetail;
exports.getWxModuleList = getWxModuleList;
exports.getlivecodeData = getlivecodeData;
exports.imageList = imageList;
exports.imgGroupList = imgGroupList;
exports.imgTextList = imgTextList;
exports.imgTypeList = imgTypeList;
exports.mainChannelList = mainChannelList;
exports.modifyGoods = modifyGoods;
exports.modifyGoodsGroup = modifyGoodsGroup;
exports.modifyTeacher = modifyTeacher;
exports.modifyWechat = modifyWechat;
exports.modifyWorkWechat = modifyWorkWechat;
exports.modifyWxGroup = modifyWxGroup;
exports.postWxConfigChannel = postWxConfigChannel;
exports.regChannelsList = regChannelsList;
exports.saveImage = saveImage;
exports.savePageConfigDev = savePageConfigDev;
exports.savePageConfigPro = savePageConfigPro;
exports.syncWxGroup = syncWxGroup;
exports.tierGroupManage = tierGroupManage;
exports.tierManage = tierManage;
exports.upDateH5List = upDateH5List;
exports.upDateWxComponent = upDateWxComponent;
exports.upDateWxConfig = upDateWxConfig;
exports.updateDomain = updateDomain;
exports.updateGuideDisposition = updateGuideDisposition;
exports.updatePageInfo = updatePageInfo;
exports.updateTextImg = updateTextImg;
exports.updateWxModule = updateWxModule;
exports.uploadImage = uploadImage;
exports.uploadImgUrl = void 0;
exports.wechatConstant = wechatConstant;
var _request = _interopRequireDefault(require("@/utils/request"));
/**
 * 注意事项：
 *
 * 1、接口路由需要能区分出功能模块，方便用于权限分配。（不符合让后端修改下）
 *    例如api: /resource/web/list*** 只能匹配到h5应用相关功能
 */

// 图片上传链接
var uploadImgUrl = exports.uploadImgUrl = process.env.VUE_APP_BASE_API + '/resource/image/upload';

//************************* h5应用管理 *************************/

// h5应用列表
function getH5List(params) {
  params.did = '5b90e59590593c4507c4b1ee'; // 在线测算
  return (0, _request.default)({
    url: '/resource/web/list',
    method: 'get',
    params: params
  });
}

// 同步应用列表数据 （在另外一个平台修改数据以后，需手动同步一遍）
function upDateH5List() {
  return (0, _request.default)({
    url: '/resource/web/list/auto',
    method: 'get',
    params: {
      did: '5b90e59590593c4507c4b1ee' // 在线测算
    }
  });
}

/************************* 渠道组 *************************/
// 中台渠道组列表
function centerChannelsList(params) {
  return (0, _request.default)({
    url: '/resource/web/channel/group_list',
    method: 'get',
    params: params
  });
}

// 中台渠道组对应详情
function centerChannelsInfo(params) {
  return (0, _request.default)({
    url: '/resource/web/channel/info',
    method: 'get',
    params: params
  });
}

// 创建中台渠道组
function addCenterChannels(data) {
  return (0, _request.default)({
    url: '/resource/web/channel/group',
    method: 'post',
    data: data
  });
}

// 删除中台渠道组
function deleteCenterChannels(data) {
  return (0, _request.default)({
    url: '/resource/web/channel/group_del',
    method: 'post',
    data: data
  });
}

// 中台主渠道列表
function mainChannelList(params) {
  return (0, _request.default)({
    url: '/resource/web/channel',
    method: 'get',
    params: params
  });
}

// 中台子渠道列表
function childChannelList(params) {
  return (0, _request.default)({
    url: '/resource/web/channel/sub',
    method: 'get',
    params: params
  });
}

// 正则渠道组列表
function regChannelsList(params) {
  return (0, _request.default)({
    url: '/resource/web/reg_channel/list',
    method: 'get',
    params: params
  });
}

// 新增正则渠道组
function addRegChannels(data) {
  return (0, _request.default)({
    url: '/resource/web/reg_channel/reg',
    method: 'post',
    data: data
  });
}

// 编辑正则渠道组
function editRegChannels(data) {
  return (0, _request.default)({
    url: '/resource/web/reg_channel/edit',
    method: 'post',
    data: data
  });
}

// 删除正则渠道组
function deleteRegChannels(data) {
  return (0, _request.default)({
    url: '/resource/web/reg_channel/del',
    method: 'post',
    data: data
  });
}

/************************* 产品入口域名管理 *************************/
// 域名列表
function domainList() {
  return (0, _request.default)({
    url: '/resource/domain/list',
    method: 'get'
  });
}

// 新增域名
function addDomain(data) {
  return (0, _request.default)({
    url: '/resource/domain/create',
    method: 'post',
    data: data
  });
}

// 修改域名
function updateDomain(data) {
  return (0, _request.default)({
    url: '/resource/domain/update',
    method: 'post',
    data: data
  });
}

// 删除域名
function deleteDomain(params) {
  return (0, _request.default)({
    url: '/resource/domain',
    method: 'delete',
    params: params
  });
}

/************************* 图文列表项管理 *************************/
// 图文列表
function imgTextList(params) {
  return (0, _request.default)({
    url: '/resource/img_text/list',
    method: 'get',
    params: params
  });
}

// 图文列表类型
function imgTypeList() {
  return (0, _request.default)({
    url: '/resource/img_text/type_list',
    method: 'get'
  });
}

// 新增图文列表类型
function addImgType(data) {
  return (0, _request.default)({
    url: '/resource/img_text/create_type',
    method: 'post',
    data: data
  });
}

// 新增图文列表
function addTextImg(data) {
  return (0, _request.default)({
    url: '/resource/img_text/create',
    method: 'post',
    data: data
  });
}

// 修改图文列表
function updateTextImg(data) {
  return (0, _request.default)({
    url: '/resource/img_text/update',
    method: 'post',
    data: data
  });
}

// 删除图文列表
function deleteTextImg(params) {
  return (0, _request.default)({
    url: '/resource/img_text',
    method: 'delete',
    params: params
  });
}

/************************* 图片库 *************************/
// 图片分组列表
function imgGroupList() {
  return (0, _request.default)({
    url: '/resource/image/group',
    method: 'get'
  });
}

// 新增图片分组
function addImgGroup(data) {
  return (0, _request.default)({
    url: '/resource/image/group',
    method: 'post',
    data: data
  });
}

// 图片列表 （传分组id获取对应分组的图片列表）
function imageList(params) {
  return (0, _request.default)({
    url: '/resource/image',
    method: 'get',
    params: params
  });
}

// 上传图片
function uploadImage(data, headers) {
  return (0, _request.default)({
    url: '/resource/image/upload',
    method: 'post',
    headers: headers,
    data: data
  });
}

// 保存图片
function saveImage(data) {
  return (0, _request.default)({
    url: '/resource/image/save',
    method: 'post',
    data: data
  });
}

// 删除图片
function deleteImage(params) {
  return (0, _request.default)({
    url: '/resource/image',
    method: 'delete',
    params: params
  });
}

/************************* 页面配置 *************************/
// 创建配置页面
function createPage(data) {
  return (0, _request.default)({
    url: '/resource/web/page',
    method: 'post',
    data: data
  });
}

// 删除页面
function deletePage(data) {
  return (0, _request.default)({
    url: '/resource/web/page/delete',
    method: 'post',
    data: data
  });
}

// 修改页面基本信息
function updatePageInfo(data) {
  return (0, _request.default)({
    url: '/resource/web/page/edit',
    method: 'post',
    data: data
  });
}

// 获取公共配置页面列表
function getDefaultPageList(params) {
  return (0, _request.default)({
    url: '/resource/web/page/default_list',
    method: 'get',
    params: params
  });
}

// 获取应用配置页面列表
function getPageList(params) {
  return (0, _request.default)({
    url: '/resource/web/page/list',
    method: 'get',
    params: params
  });
}

// 页面标识列表
function getPageFlag() {
  return (0, _request.default)({
    url: '/resource/web/page/flag_list',
    method: 'get'
  });
}

// 新增页面标识
function addPageFlag(data) {
  return (0, _request.default)({
    url: '/resource/web/page/flag_add',
    method: 'post',
    data: data
  });
}

// 获取公共页面资源配置信息
function getDefaultPageConfig(params) {
  return (0, _request.default)({
    url: '/resource/web/page_config/default_detail',
    method: 'get',
    params: params
  });
}

// 获取h5应用页面资源配置信息
function getPageConfig(params) {
  return (0, _request.default)({
    url: '/resource/web/page_config/detail',
    method: 'get',
    params: params
  });
}

// 保存页面配置 (测服)
function savePageConfigDev(data, type) {
  var path = '/resource/web/page_config/config'; // 私有应用配置保存
  if (type == 'public') {
    path = '/resource/web/page_config/default_config'; // 公共配置保存
  }
  return (0, _request.default)({
    url: path,
    method: 'post',
    data: data
  });
}

// 同步页面配置上线
function savePageConfigPro(data) {
  return (0, _request.default)({
    url: '/resource/web/page_config/default_syn',
    method: 'post',
    data: data
  });
}

// 更新导粉配置
function updateGuideDisposition() {
  return (0, _request.default)({
    url: '/resource/wx/wechat_config',
    method: 'post'
  });
}

// 关系映射
function wechatConstant() {
  return (0, _request.default)({
    url: '/resource/wx/wechat_constant',
    method: 'get'
  });
}

/************************* 个人号导粉管理 *************************/
// 微信号列表
function getWxList() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/resource/wx/wechat',
    method: 'get',
    params: params
  });
}

// 新增微信号
function addWechat(data) {
  return (0, _request.default)({
    url: '/resource/wx/wechat',
    method: 'post',
    data: data
  });
}

// 修改微信号
function modifyWechat(id, data) {
  return (0, _request.default)({
    url: "/resource/wx/wechat/".concat(id),
    method: 'post',
    data: data
  });
}

// 企业微信号列表
function getWorkWxList() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/resource/wx/workwechat',
    method: 'get',
    params: params
  });
}

// 新增企业微信号
function addWorkWechat(data) {
  return (0, _request.default)({
    url: '/resource/wx/workwechat',
    method: 'post',
    data: data
  });
}

// 修改企业微信号
function modifyWorkWechat(id, data) {
  return (0, _request.default)({
    url: "/resource/wx/workwechat/".concat(id),
    method: 'post',
    data: data
  });
}

// 微信组列表
function getWxGroup() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    params: params,
    url: '/resource/wx/group',
    method: 'get'
  });
}

// 修改微信组
function modifyWxGroup(id, data) {
  return (0, _request.default)({
    url: "/resource/wx/group/".concat(id),
    method: 'post',
    data: data
  });
}

// 新增微信组
function addWxGroup(data) {
  return (0, _request.default)({
    url: '/resource/wx/group',
    method: 'post',
    data: data
  });
}

// 活码ID获取数据
function getlivecodeData(id) {
  return (0, _request.default)({
    url: "/resource/wx/livecode/".concat(id),
    method: 'get'
  });
}

// 同步微信组
function syncWxGroup(id) {
  return (0, _request.default)({
    url: "/resource/wx/wechat_to_service/".concat(id),
    method: 'post'
  });
}

/************************* 个人号导粉管理 *************************/

// 导粉组件模板列表
function getWxModuleList(params) {
  return (0, _request.default)({
    url: '/resource/wx/template/list',
    method: 'get',
    params: params
  });
}

// 导粉组件模板新增
function addWxModule(data) {
  return (0, _request.default)({
    url: '/resource/wx/template/create',
    method: 'post',
    data: data
  });
}

// 删除导粉组件模板
function deleteWxModule(params) {
  return (0, _request.default)({
    url: '/resource/wx/template',
    method: 'delete',
    params: params
  });
}

// 导粉组件模板更新
function updateWxModule(data) {
  return (0, _request.default)({
    url: '/resource/wx/template/update',
    method: 'post',
    data: data
  });
}

// 模板组件详情
function getWxModuleDetail(params) {
  return (0, _request.default)({
    url: '/resource/wx/template/detail',
    method: 'get',
    params: params
  });
}

// 导粉组件列表
function getWxComponentList(params) {
  return (0, _request.default)({
    url: '/resource/wx/component/list',
    method: 'get',
    params: params
  });
}

// 导粉组件配置新增
function addWxComponent(data) {
  return (0, _request.default)({
    url: '/resource/wx/component/create',
    method: 'post',
    data: data
  });
}

// 导粉组件配置更新
function upDateWxComponent(data) {
  return (0, _request.default)({
    url: '/resource/wx/component/update',
    method: 'post',
    data: data
  });
}

// 导粉组件删除
function deleteWxComponent(params) {
  return (0, _request.default)({
    url: '/resource/wx/component',
    method: 'delete',
    params: params
  });
}

// 导粉配置列表
function getWxConfigList(params) {
  return (0, _request.default)({
    url: '/resource/wx/config/list',
    method: 'get',
    params: params
  });
}

// 新增导粉配置
function addWxConfig(data) {
  return (0, _request.default)({
    url: '/resource/wx/config/create',
    method: 'post',
    data: data
  });
}

// 更新导粉配置
function upDateWxConfig(data) {
  return (0, _request.default)({
    url: '/resource/wx/config/update',
    method: 'post',
    data: data
  });
}

// 删除导粉配置
function deleteWxConfig(params) {
  return (0, _request.default)({
    url: '/resource/wx/config',
    method: 'delete',
    params: params
  });
}

// 导粉配置渠道控制管理详情
function getWxConfigChannel() {
  return (0, _request.default)({
    url: '/resource/wx/channel/detail',
    method: 'get'
  });
}

// 导粉配置渠道控制
function postWxConfigChannel(data) {
  return (0, _request.default)({
    url: '/resource/wx/config/channel',
    method: 'post',
    data: data
  });
}

/************************* 老师信息管理 *************************/

// 创建老师模板
function addTeacher(data) {
  return (0, _request.default)({
    url: '/resource/wx/template',
    method: 'post',
    data: data
  });
}

// 获取老师模板
function getTeacher(params) {
  return (0, _request.default)({
    url: '/resource/wx/template',
    method: 'get',
    params: params
  });
}

// 修改老师模板
function modifyTeacher(id, data) {
  return (0, _request.default)({
    url: "/resource/wx/template/".concat(id),
    method: 'post',
    data: data
  });
}

/************************* 老师信息管理 *************************/

/************************* 商城商品管理 *************************/
// 商品列表
function getGoods() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/resource/goods/item',
    method: 'get',
    params: params
  });
}

// 新增商品
function addGoods(data) {
  return (0, _request.default)({
    url: '/resource/goods/item',
    method: 'post',
    data: data
  });
}

// 修改商品
function modifyGoods(id, data) {
  return (0, _request.default)({
    url: "/resource/goods/item/".concat(id),
    method: 'post',
    data: data
  });
}

// 商品组列表
function getGoodsGroup() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    params: params,
    url: '/resource/goods/group',
    method: 'get'
  });
}

// 修改商品组
function modifyGoodsGroup(id, data) {
  return (0, _request.default)({
    url: "/resource/goods/group/".concat(id),
    method: 'post',
    data: data
  });
}

// 新增商品组
function addGoodsGroup(data) {
  return (0, _request.default)({
    url: '/resource/goods/group',
    method: 'post',
    data: data
  });
}

//获取商品属性
function getGoodsProp() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/resource/goods/property',
    method: 'get',
    params: params
  });
}

/************************* 商城商品管理 *************************/

/************************* 用户标签管理 （todo,这块功能还没开发完，待优化）*************************/
// 用户标签管理
function tierManage(id, method, params, data) {
  return (0, _request.default)({
    url: "/resource/user/tier".concat(id ? '/' + id : ''),
    method: method,
    params: params,
    data: data
  });
}

// 标签组管理
function tierGroupManage(id, method, params, data) {
  return (0, _request.default)({
    url: "/resource/user/tier/group".concat(id ? '/' + id : ''),
    method: method,
    params: params,
    data: data
  });
}

/************************* app应用管理 （todo,这块功能还没开发完，待优化） *************************/
// APP应用
function appManage(id, method, params, data) {
  return (0, _request.default)({
    url: "/resource/app".concat(id ? '/' + id : ''),
    method: method,
    params: params,
    data: data
  });
}

// APP应用版本/渠道
function appVersion(id, method, params, data) {
  return (0, _request.default)({
    url: "/resource/app/version".concat(id ? '/' + id : ''),
    method: method,
    params: params,
    data: data
  });
}

// APP应用组
function appGroup(id, method, params, data) {
  return (0, _request.default)({
    url: "/resource/app/group".concat(id ? '/' + id : ''),
    method: method,
    params: params,
    data: data
  });
}

// APP应用版本/渠道组
function appVersionGroup(id, method, params, data) {
  return (0, _request.default)({
    url: "/resource/app/version/group".concat(id ? '/' + id : ''),
    method: method,
    params: params,
    data: data
  });
}