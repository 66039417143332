"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/var/www/html/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/es6.object.freeze");
var _backend = require("@/api/backend");
var _tagManage = _interopRequireDefault(require("./components/tag-manage.vue"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    TagManage: _tagManage.default
  },
  data: function data() {
    return {
      tableData: [],
      currentPage: 1,
      totalPage: 1,
      perPage: 15,
      manageType: '',
      // 弹窗操作类型
      chooseData: {}
    };
  },
  methods: {
    getList: function getList() {
      var _this = this;
      var meta = this.$route.meta;
      var params = {
        page: this.currentPage,
        per_page: this.perPage
      };
      if (meta.from) {
        params.from = meta.from;
      }
      (0, _backend.getConfigTags)(params).then(function (_ref) {
        var code = _ref.code,
          data = _ref.data;
        if (code === 200) {
          var list = data.list,
            _meta = data.meta;
          _this.tableData = Object.freeze(list);
          _this.totalPage = _meta.page.total_page;
        }
      });
    },
    // 分页
    handleCurrentChange: function handleCurrentChange(page) {
      this.currentPage = page;
      this.getList();
    },
    // 修改
    onModify: function onModify(val) {
      this.chooseData = _objectSpread({}, val);
      this.manageType = 'modify';
      this.$refs.managePop.dialogVisible = true;
    },
    // 新增
    onAdd: function onAdd() {
      this.chooseData = {}; // 清空数据
      this.manageType = 'add';
      this.$refs.managePop.dialogVisible = true;
    },
    //配置
    onConfig: function onConfig(val) {
      var from = this.$route.meta.from;
      this.$router.push({
        name: 'backendConfig',
        query: {
          from: from,
          tag_id: val.id
        }
      });
    }
  },
  mounted: function mounted() {
    this.getList();
  }
};