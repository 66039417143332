"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addWorkWx = addWorkWx;
exports.deleteWorkWx = deleteWorkWx;
exports.getCompanyList = getCompanyList;
exports.getWorkWxDetail = getWorkWxDetail;
exports.getWorkWxList = getWorkWxList;
exports.modifyWorkWx = modifyWorkWx;
var _request = _interopRequireDefault(require("@/utils/request"));
// 企业微信监控 接口文档：https://yapi.linghit.cn/project/755/interface/api/cat_10727

// 获取主体信息列表
function getCompanyList() {
  return (0, _request.default)({
    url: '/web/employee/company',
    method: 'get'
  });
}

/**
 * 获取企业微信列表
 * @param {object} params
 */
function getWorkWxList(params) {
  return (0, _request.default)({
    url: '/web/employee',
    method: 'get',
    params: params
  });
}

/**
 * 删除企业微信
 * @param {string} employeeId
 */
function deleteWorkWx(employeeId) {
  return (0, _request.default)({
    url: "/web/employee/".concat(employeeId),
    method: 'delete'
  });
}

/**
 * 新增企业微信
 * @param {object} data
 */
function addWorkWx(data) {
  return (0, _request.default)({
    url: '/web/employee',
    method: 'post',
    data: data
  });
}

/**
 * 修改企业微信信息
 * @param {string} employeeId
 * @param {object} data
 */
function modifyWorkWx(employeeId, data) {
  return (0, _request.default)({
    url: "/web/employee/".concat(employeeId),
    method: 'put',
    data: data
  });
}

/**
 * 获取企业微信详情信息
 * @param {string} employeeId
 */
function getWorkWxDetail(employeeId) {
  return (0, _request.default)({
    url: "/web/employee/".concat(employeeId),
    method: 'get'
  });
}