var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "section",
        { staticClass: "search-area" },
        [
          _c(
            "div",
            [
              _c(
                "el-input",
                {
                  staticClass: "width-250",
                  attrs: { placeholder: "模块名搜索", clearable: "" },
                  on: { change: _vm.onSearch },
                  model: {
                    value: _vm.searchName,
                    callback: function ($$v) {
                      _vm.searchName = $$v
                    },
                    expression: "searchName",
                  },
                },
                [
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-search" },
                    slot: "append",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-plus" },
              on: { click: _vm.onAdd },
            },
            [_vm._v("新增")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData, border: "", size: "medium" },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "template",
              label: "归属模块（如：中式命理）",
              "min-width": "60",
              "header-align": "center",
              align: "center",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "avatar",
              label: "老师头像",
              "min-width": "50",
              "header-align": "center",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "template-scope-wrap" },
                      [
                        _c("el-image", {
                          staticStyle: { width: "80px" },
                          attrs: { fit: "contain", src: scope.row.avatar },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "desc",
              label: "描述文案",
              "min-width": "60",
              "header-align": "center",
              align: "center",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "tips",
              label: "其他文案",
              "min-width": "60",
              "header-align": "center",
              align: "center",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "updated_at",
              label: "最近同步时间",
              "min-width": "60",
              "header-align": "center",
              align: "center",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "cancel",
              label: "修改",
              "min-width": "30",
              "header-align": "center",
              align: "center",
              fixed: "right",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-button", {
                      attrs: { type: "text", icon: "el-icon-edit" },
                      on: {
                        click: function ($event) {
                          return _vm.onModify(scope.row)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.totalPage > 0
        ? _c(
            "section",
            { staticClass: "pagination-wrap" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.currentPage,
                  "page-size": _vm.pageSize,
                  layout: "prev, pager, next, jumper",
                  "page-count": _vm.totalPage,
                },
                on: {
                  "current-change": _vm.handleCurrentChange,
                  "update:currentPage": function ($event) {
                    _vm.currentPage = $event
                  },
                  "update:current-page": function ($event) {
                    _vm.currentPage = $event
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("Manage", {
        ref: "managePop",
        attrs: {
          manageType: _vm.manageType,
          ruleForm: _vm.chooseData,
          imageUploadData: _vm.imageUploadData,
        },
        on: {
          "update:ruleForm": function ($event) {
            _vm.chooseData = $event
          },
          "update:rule-form": function ($event) {
            _vm.chooseData = $event
          },
          "update:imageUploadData": function ($event) {
            _vm.imageUploadData = $event
          },
          "update:image-upload-data": function ($event) {
            _vm.imageUploadData = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }