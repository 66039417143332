var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "el-form",
        {
          attrs: {
            model: _vm.propForm,
            rules: _vm.ruleFlag ? _vm.rules : _vm.ruleNull,
            "label-width": _vm.labelWidth,
          },
        },
        [
          _vm.propForm.url_config
            ? _c(
                "el-form-item",
                { attrs: { label: "跳转", prop: "url" } },
                [
                  _vm.propForm.url_config.url_name ||
                  _vm.propForm.url_config.path ||
                  _vm.propForm.url_config.path_norule
                    ? _c(
                        "div",
                        { staticClass: "showbox" },
                        [
                          _c(
                            "div",
                            { staticClass: "el-tag el-tag--info showbox" },
                            [
                              _vm.propForm.url_config.url_rule === "rule"
                                ? _c("div", { staticClass: "font-12" }, [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(
                                          _vm.propForm.url_config.url_name +
                                            _vm.propForm.url_config.path
                                        ) +
                                        "\n                    "
                                    ),
                                  ])
                                : _c("div", { staticClass: "font-12" }, [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(
                                          _vm.propForm.url_config.path_norule
                                        ) +
                                        "\n                    "
                                    ),
                                  ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticStyle: { "padding-left": "6px" },
                              attrs: { type: "text", size: "small" },
                              on: { click: _vm.onModify },
                            },
                            [
                              _vm._v(
                                "\n                    修改\n                "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "text" },
                          on: {
                            click: function ($event) {
                              _vm.dialogVisible = true
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                设置跳转页面/模块\n            "
                          ),
                        ]
                      ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "跳转模块设置",
            visible: _vm.dialogVisible,
            width: "60%",
            "close-on-click-modal": false,
            "show-close": false,
            left: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("UrlConfig", {
            ref: "urlConfig",
            attrs: { url_config: _vm.propForm.url_config },
            on: {
              "update:url_config": function ($event) {
                return _vm.$set(_vm.propForm, "url_config", $event)
              },
            },
          }),
          _vm._v(" "),
          _vm.propForm.url_config.url_rule === "rule"
            ? _c(
                "div",
                { staticClass: "show-url" },
                [
                  _c("span", { staticClass: "font-14" }, [
                    _vm._v(
                      "\n                配置链接展示：" +
                        _vm._s(
                          "" +
                            _vm.propForm.url_config.url_name +
                            _vm.propForm.url_config.path
                        ) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.propForm.url_config.url_name
                    ? _c(
                        "el-link",
                        {
                          directives: [
                            {
                              name: "clipboard",
                              rawName: "v-clipboard:copy",
                              value:
                                "" +
                                _vm.propForm.url_config.url_name +
                                _vm.propForm.url_config.path,
                              expression:
                                "`${propForm.url_config.url_name}${propForm.url_config.path}`",
                              arg: "copy",
                            },
                            {
                              name: "clipboard",
                              rawName: "v-clipboard:success",
                              arg: "success",
                            },
                            {
                              name: "clipboard",
                              rawName: "v-clipboard:error",
                              arg: "error",
                            },
                          ],
                          attrs: { type: "primary" },
                        },
                        [_vm._v("\n                复制\n            ")]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.onCancel } }, [
                _vm._v("关闭并清空数据"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "info" }, on: { click: _vm.onReset } },
                [_vm._v("重置")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSureUrl } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }