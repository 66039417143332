var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "zy-common-component-wrapper" }, [
    _c("div", { staticClass: "zy-common-component-wrapper-id font-13" }, [
      _vm._v(_vm._s(_vm.id)),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "zy-common-component-wrapper-title font-16" }, [
      _vm._v("\n        ◂\n        "),
      _c("span", [_vm._v(_vm._s(_vm.title))]),
      _vm._v("\n        ▸\n    "),
    ]),
    _vm._v(" "),
    _vm.cur_select_module[_vm.keyId]
      ? _c(
          "div",
          [
            _vm._t("default", null, {
              configData:
                _vm.cur_select_module[_vm.keyId].component[
                  _vm.cur_select_module_index == _vm.keyId
                    ? _vm.active_content_key
                    : 0
                ].config_data,
              onLocation: _vm.onLocation,
            }),
          ],
          2
        )
      : _c(
          "div",
          [
            _vm._t("default", null, {
              configData: [{}],
              onLocation: _vm.onLocation,
            }),
          ],
          2
        ),
    _vm._v(" "),
    _c("div", { staticClass: "tips" }, [_vm._v(_vm._s(_vm.tips))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }