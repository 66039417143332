"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
var _user = require("@/api/user");
var _auth = require("@/utils/auth");
var state = {
  token: (0, _auth.getToken)(),
  realname: '',
  avatar: '',
  userInfo: '',
  // 登录后的用户信息
  userInfoPermission: '',
  // 登录后的用户信息-权限列表
  permissionList: '' // 权限列表
};
var mutations = {
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_NAME: function SET_NAME(state, realname) {
    state.realname = realname;
  },
  SET_AVATAR: function SET_AVATAR(state, avatar) {
    state.avatar = avatar;
  },
  SET_USER_INFO: function SET_USER_INFO(state, userInfo) {
    // 当前登录用户信息
    state.userInfo = userInfo;
    state.userInfoPermission = userInfo.permission;
  },
  SET_PERISSION_LIST: function SET_PERISSION_LIST(state, permission) {
    // 权限列表
    state.permissionList = permission;
  }
};
var actions = {
  // 用户登录
  userLogin: function userLogin(_ref, data) {
    var commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      (0, _user.userLogin)(data).then(function (res) {
        var data = res.data;
        if (data.authorization) {
          (0, _auth.setToken)(data.authorization); // 12小时有效期
          location.replace("".concat(location.protocol, "//").concat(location.host));
        } else {
          console.log('error');
        }
        resolve();
      }).catch(function (e) {
        reject();
        console.log(e);
        (0, _auth.removeToken)();
        window.location.href = '/login';
      });
    });
  },
  // 获取用户信息
  getLoginUserInfo: function getLoginUserInfo(_ref2) {
    var commit = _ref2.commit,
      state = _ref2.state;
    if (state.realname) {
      return;
    }
    return new Promise(function (resolve, reject) {
      (0, _user.getLoginUserInfo)({
        authorization: state.token
      }).then(function (res) {
        var data = res.data;
        if (!data) {
          window.location.href = (0, _user.authLogin)();
          reject('请重新登陆');
        }
        var realname = data.realname,
          avatar = data.avatar;
        commit('SET_NAME', realname);
        commit('SET_AVATAR', avatar);
        commit('SET_USER_INFO', data);
        resolve(data);
      }).catch(function (err) {
        if (err.data.code === 100000) {
          // 登录过期
          window.location.href = (0, _user.authLogin)();
        }
        reject(err);
      });
    });
  },
  // 获取权限列表
  getPermissionList: function getPermissionList(_ref3) {
    var commit = _ref3.commit,
      state = _ref3.state;
    return new Promise(function (resolve, reject) {
      (0, _user.getPermission)('get').then(function (res) {
        var data = res.data;
        commit('SET_PERISSION_LIST', data);
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  }
};
var _default = exports.default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};