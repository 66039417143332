var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ComConfig", {
    attrs: { title: "悬浮icon", component_name: "7-3-pop-icon-1", limit: 1 },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }