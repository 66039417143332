"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _h5ConfigList = _interopRequireDefault(require("./components/h5-config-list"));
var _appConfigList = _interopRequireDefault(require("./components/app-config-list"));
//
//
//
//
//
//
//
//
//
//
//
//
//
// h5应用
// app应用
var _default = exports.default = {
  components: {
    H5ConfigList: _h5ConfigList.default,
    AppConfigList: _appConfigList.default
  },
  data: function data() {
    return {
      activeName: 'first'
    };
  }
};