var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("section", { staticClass: "module-wrap" }, [
        _c("aside", { staticClass: "left-choose-container" }, [
          _c(
            "div",
            { staticClass: "nav-lists" },
            _vm._l(_vm.navClassify, function (v, k) {
              return _c(
                "div",
                {
                  key: k,
                  staticClass: "nav-lists-item font-14",
                  class: { active: _vm.currentNavId === k },
                  on: {
                    click: function ($event) {
                      return _vm.onTab(k, v.iconList)
                    },
                  },
                },
                [
                  _c("div", [_vm._v(_vm._s(v.title))]),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "arrow-content",
                    class: { "arrow-active": _vm.currentNavId === k },
                  }),
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c("div", { staticClass: "grid-list" }, [
            _vm.cur_iconList.length > 0
              ? _c(
                  "div",
                  { staticClass: "grid" },
                  _vm._l(_vm.cur_iconList, function (v, k) {
                    return _c(
                      "div",
                      {
                        key: k,
                        staticClass: "grid-item",
                        on: {
                          click: function ($event) {
                            return _vm.selectModule(v)
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "icon-img",
                          attrs: { src: v.icon, alt: "" },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "icon-text" }, [
                          _vm._v(_vm._s(v.title)),
                        ]),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "新增配置组件",
            visible: _vm.dialogVisible,
            width: "60%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "section",
            { staticClass: "add-dialog" },
            [
              _c("div", { staticClass: "add-tips" }, [
                _c("div", { staticClass: "font-16" }, [
                  _vm._v("\n                    当前该组件在"),
                  _c(
                    "span",
                    {
                      staticStyle: { "font-weight": "bold", color: "#7eacff" },
                    },
                    [_vm._v("公共配置已存在")]
                  ),
                  _vm._v(
                    "，请确认添加组件是【覆盖公共组件】还是【新增组件】.\n                "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "font-15" }, [
                  _c("span", { staticStyle: { color: "red" } }, [
                    _vm._v("tips："),
                  ]),
                  _vm._v(
                    "组件id与公共组件id一致，会覆盖公共组件数据，如果是不想影响公共组件，单纯新增配置，请选新增独立项.\n                "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  staticClass: "add-select",
                  attrs: { "label-position": "left" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "请选择", "label-width": "110px" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.addSelect,
                            callback: function ($$v) {
                              _vm.addSelect = $$v
                            },
                            expression: "addSelect",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("新增独立项"),
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.canChooseArr, function (v, k) {
                            return _c(
                              "el-radio",
                              {
                                key: k,
                                attrs: { label: k + 1 },
                                on: {
                                  change: function ($event) {
                                    return _vm.changeCoverItem(v.component_id)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            覆盖项：组件id【" +
                                    _vm._s(v.component_id) +
                                    "】\n                        "
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.addSelect !== 0
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "是否复制数据",
                            "label-width": "110px",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.cpoySelect,
                                callback: function ($$v) {
                                  _vm.cpoySelect = $$v
                                },
                                expression: "cpoySelect",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "no" } }, [
                                _vm._v("否"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: "yes" } }, [
                                _vm._v("是"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onSureAddNewModule },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }