"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      loading: false,
      tableData: [],
      tablePage: {
        per_page: 15,
        current: 1,
        total_page: 1
      }
    };
  },
  mounted: function mounted() {
    this.getTableData();
  },
  methods: {
    getTableData: function getTableData() {
      var _this = this;
      this.loading = true;
      var params = {
        per_page: this.tablePage.per_page,
        page: this.tablePage.current_page
      };
      (0, _user.getOperateLog)(params).then(function (res) {
        var data = res.data;
        _this.tablePage = {
          per_page: data.per_page,
          current: data.current_page,
          total_page: data.total_page,
          total_num: data.total_num
        };
        _this.tableData = data.list;
        _this.loading = false;
      }).catch(function () {
        _this.loading = false;
      });
    },
    // 分页
    handleCurrentChange: function handleCurrentChange(page) {
      this.tablePage.current_page = page;
      this.getTableData();
    }
  }
};