"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FANS_TYPES_MAP = exports.FANS_TYPES_LIST = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.map");
var FANS_TYPES_LIST = exports.FANS_TYPES_LIST = [{
  name: 'wechat',
  label: '个人微信',
  value: 1
}, {
  name: 'official_wechat',
  label: '公众号',
  value: 2
}, {
  name: 'enterprise_wechat',
  label: '企业微信/获客链接',
  value: 3
}];
var FANS_TYPES_MAP = exports.FANS_TYPES_MAP = new Map([[1, '个人微信'], [2, '公众号'], [3, '企业微信/获客链接'], [4, '企业微信/获客链接']]);