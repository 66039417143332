"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _slicedToArray2 = _interopRequireDefault(require("/var/www/html/node_modules/@babel/runtime-corejs2/helpers/slicedToArray.js"));
var _postback = require("@/api/postback");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {},
  data: function data() {
    return {
      loading: false,
      tableData: [],
      currentPage: 1,
      totalPage: 1,
      perPage: 15,
      multipleSelection: [],
      channel: '',
      channelMode: 'full',
      channelModeOptions: [{
        value: 'full',
        label: '精准匹配'
      }, {
        value: 'fuzzy',
        label: '模糊匹配'
      }],
      reportStatus: 'all',
      reportStatusOptions: [{
        value: 'no',
        label: '未上报'
      }, {
        value: 'ok',
        label: '已上报'
      }, {
        value: 'all',
        label: '全部'
      }],
      timeRange: '',
      // 时间范围
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      }
    };
  },
  methods: {
    onSearch: function onSearch() {
      this.getList();
    },
    // 分页
    handleCurrentChange: function handleCurrentChange(page) {
      this.currentPage = page;
      this.getList();
    },
    getList: function getList() {
      var _this = this;
      this.loading = true;
      var params = {
        page: this.currentPage,
        per_page: this.perPage
      };
      if (this.channel) {
        params.channel = this.channel;
        params.channel_mode = this.channelMode;
      }
      if (this.reportStatus === 'ok' || this.reportStatus === 'no') {
        params.status = this.reportStatus === 'ok' ? 1 : 0;
      }
      if (this.timeRange) {
        var _this$getFormatDateAr = this.getFormatDateArr(),
          _this$getFormatDateAr2 = (0, _slicedToArray2.default)(_this$getFormatDateAr, 2),
          start = _this$getFormatDateAr2[0],
          end = _this$getFormatDateAr2[1];
        params.start = start;
        params.end = end;
      }
      (0, _postback.getOrderList)(params).then(function (_ref) {
        var code = _ref.code,
          data = _ref.data;
        _this.loading = false;
        if (code === 200) {
          var list = data.list,
            meta = data.meta;
          _this.tableData = list;
          _this.totalPage = meta.page.total_page;
        }
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    getFormatDateArr: function getFormatDateArr() {
      var _this2 = this;
      var newDateArr = [];
      // 格式如：2023-08-22 12:00:00
      this.timeRange.forEach(function (item) {
        var newDate = new Date(item);
        var year = newDate.getFullYear();
        var month = newDate.getMonth() + 1;
        var day = newDate.getDate();
        var hour = newDate.getHours();
        var minute = newDate.getMinutes();
        var second = newDate.getSeconds();
        var dateStr = "".concat(year, "-").concat(_this2.addPrefixZero(month), "-").concat(_this2.addPrefixZero(day), " ").concat(_this2.addPrefixZero(hour), ":").concat(_this2.addPrefixZero(minute), ":").concat(_this2.addPrefixZero(second));
        newDateArr.push(dateStr);
      });
      return newDateArr;
    },
    addPrefixZero: function addPrefixZero(num) {
      if (num < 10) {
        return '0' + num;
      }
      return num;
    },
    handleSelectionChange: function handleSelectionChange(val) {
      var newVal = [];
      for (var i = 0; i < val.length; i++) {
        newVal.push(val[i].id);
      }
      this.multipleSelection = newVal;
    },
    // 确认上报
    confirmReport: function confirmReport() {
      var _this3 = this;
      var list = JSON.stringify(this.multipleSelection);
      (0, _postback.reportOrder)(list).then(function (_ref2) {
        var code = _ref2.code,
          msg = _ref2.msg;
        if (code === 200 && msg) {
          _this3.$message({
            type: 'success',
            message: msg
          });
          // 更新列表
          _this3.getList();
        }
      });
    },
    handleSelectable: function handleSelectable(row) {
      if (row.status === 1) {
        return false;
      }
      return true;
    }
  },
  mounted: function mounted() {
    this.getList();
  }
};