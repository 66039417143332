"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/var/www/html/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/es6.function.name");
var _lodash = _interopRequireDefault(require("lodash"));
var _yunying = require("@/api/yunying");
var _vuex = require("vuex");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    moduleList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    editType: {},
    ruleForm: {
      type: Object,
      default: function _default() {
        return {
          name: '',
          reg: '',
          sub_reg_id: []
        };
      }
    }
  },
  data: function data() {
    var _this = this;
    // 正则匹配规则或子渠道组其中一个必填
    var validateNum = function validateNum(rule, value, callback) {
      if (!_this.ruleForm.reg && _this.ruleForm.sub_reg_id.length < 1) {
        callback(new Error('请至少填写一项'));
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false,
      rules: {
        name: {
          required: true,
          message: '请输入渠道组名称',
          trigger: 'blur'
        },
        reg: {
          required: true,
          message: '正则匹配规则和子渠道组两者间至少需填写其中一项',
          trigger: 'blur',
          validator: validateNum
        },
        sub_reg_id: {
          required: true,
          message: '正则匹配规则和子渠道组两者间至少需填写其中一项',
          trigger: 'blur',
          validator: validateNum
        }
      },
      //测试数据
      options: [{
        value: '^test',
        label: '测试'
      }, {
        value: '^wwww',
        label: 'www'
      }]
    };
  },
  computed: (0, _vuex.mapState)({
    reg_channels: function reg_channels(state) {
      var _this2 = this;
      var filterChannels = state.common.reg_channels;
      if (state.common.reg_channels) {
        filterChannels = state.common.reg_channels.filter(function (v) {
          return v.name != _this2.ruleForm.name;
        });
      }
      return filterChannels;
    }
  }),
  watch: {
    dialogVisible: function dialogVisible(newValue, oldValue) {
      if (newValue) {
        this.updateRegChannels();
      }
    }
  },
  methods: _objectSpread(_objectSpread({}, (0, _vuex.mapActions)({
    getRegChannels: 'common/getRegChannels'
  })), {}, {
    onCancel: function onCancel() {
      this.dialogVisible = false;
    },
    submitForm: function submitForm(formName) {
      var _this3 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this3.handleEvent();
        } else {
          return false;
        }
      });
    },
    handleEvent: function handleEvent() {
      var _this4 = this;
      var editType = this.editType,
        ruleForm = this.ruleForm;
      var params = {
        name: ruleForm.name,
        reg: ruleForm.reg,
        sub_reg_id: ruleForm.sub_reg_id.join(',')
      };
      if (editType == 'add') {
        (0, _yunying.addRegChannels)(params).then(function (data) {
          if (data.code === 200) {
            _this4.$message({
              message: '添加成功',
              type: 'success'
            });
            _this4.dialogVisible = false;
            _this4.$parent.getList(); // 更新父组件数据
            _this4.updateRegChannels(true); // 更新渠道数据
          }
        });
      } else {
        params.id = ruleForm.id;
        (0, _yunying.editRegChannels)(params).then(function (data) {
          if (data.code === 200) {
            _this4.$message({
              message: '修改成功',
              type: 'success'
            });
            _this4.dialogVisible = false;
            _this4.$parent.getList(); // 更新父组件数据
            _this4.updateRegChannels(true); // 更新渠道数据
          }
        });
      }
    },
    updateRegChannels: function updateRegChannels() {
      var update = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var params = {
        page: 1,
        per_page: 500
      };
      // update-是否需要更新数据
      this.getRegChannels({
        params: params,
        update: update
      });
    }
  })
};