"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      moduleList: [],
      visible: false
    };
  },
  computed: {
    cur_select_module: function cur_select_module() {
      var _this = this;
      var data = this.$store.state.components.cur_select_module;
      data.map(function (v, k) {
        _this.moduleList.push({
          component_name: v.component_name,
          component_id: v.component_id,
          module: function module() {
            return Promise.resolve(require("@/views/modulestore/module/".concat(v.component_name)).default);
          }
        });
      });
      return data;
    },
    cur_select_module_index: function cur_select_module_index() {
      return this.$store.state.components.cur_select_module_index;
    }
  },
  methods: {
    selectModule: function selectModule(item, index) {
      if (this.cur_select_module_index === index) {
        return;
      }
      this.$store.commit('components/SET_SELECT_INDEX', index);
      // 每次重选组件，重置组件渠道内容选择id
      this.$store.commit('components/SET_CONTENT_INDEX', {
        active_content_index: 0,
        active_content_key: 0
      });
    },
    remove: function remove(item, index) {
      if (this.cur_select_module.length === 1) {
        this.$store.commit('components/SET_SELECT_INDEX', 0);
        this.$store.commit('components/REMOVE_CUR_SELECT_MOUDLE', index);
        this.visible = false;
        return;
      }
      if (index === this.cur_select_module.length - 1) {
        this.$store.commit('components/SET_SELECT_INDEX', this.cur_select_module.length - 2);
      }
      this.visible = false;
      this.$store.commit('components/REMOVE_CUR_SELECT_MOUDLE', index);
    }
  }
};