"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  name: 'channels',
  props: {
    propForm: {
      type: Object,
      default: function _default() {
        return {
          reg_channel_group: []
        };
      }
    },
    update: {
      // 数据更新
      type: Function,
      default: function _default() {
        return function () {};
      }
    },
    labelWidth: {
      // 标题宽度
      type: String,
      default: function _default() {
        return '90px';
      }
    },
    labelPosition: {
      // 标题位置
      type: String,
      default: function _default() {
        return 'left';
      }
    },
    openRules: {
      // 是否开启规则验证
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    placeholder: {
      default: function _default() {
        return '请选择正则渠道组';
      }
    }
  },
  watch: {
    propForm: {
      handler: function handler() {
        this.update();
      },
      deep: true
    }
  },
  computed: {
    reg_channels: function reg_channels() {
      return this.$store.state.common.reg_channels;
    }
  },
  data: function data() {
    return {
      loading: false,
      rules: {}
    };
  },
  mounted: function mounted() {},
  methods: {}
};