"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _urlConfig = _interopRequireDefault(require("@/components/url-config"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  components: {
    UrlConfig: _urlConfig.default
  },
  props: {
    propForm: {
      type: Object,
      default: function _default() {
        return {
          url_config: {
            url_rule: 'rule',
            url_id: '',
            //域名id
            url_name: '',
            //域名
            path: '' // 路径
          }
        };
      }
    },
    update: {
      // 数据更新
      type: Function,
      default: function _default() {
        return function () {};
      }
    },
    labelWidth: {
      // 标题宽度
      type: String,
      default: function _default() {
        return '55px';
      }
    },
    ruleFlag: {
      // 是否开启规则验证
      type: Boolean,
      default: function _default() {
        return true;
      }
    }
  },
  watch: {
    propForm: {
      handler: function handler() {
        this.update();
      },
      deep: true
    }
  },
  data: function data() {
    return {
      rules: {
        url: {
          required: true,
          message: '请输入链接',
          trigger: 'blur'
        }
      },
      ruleNull: {},
      dialogVisible: false // 弹窗
    };
  },
  methods: {
    // 打开链接设置弹窗
    openUrlDialog: function openUrlDialog() {
      this.dialogVisible = true;
    },
    // 保存
    onSureUrl: function onSureUrl() {
      var flag = this.$refs.urlConfig.validateData();
      // 验证通过再关闭弹窗
      if (flag) {
        this.dialogVisible = false;
      }
      // rule：提交 url_id + path 、 norule：提交 path_norule
      var url_config = this.propForm.url_config;
      if (url_config.url_rule === 'rule') {
        url_config.path_norule = '';
      } else {
        url_config.url_id = '';
        url_config.path = '';
      }
      this.$emit('closeOrOpenDrag', this.dialogVisible);
    },
    // 修改
    onModify: function onModify() {
      this.dialogVisible = true;
      this.$emit('closeOrOpenDrag', this.dialogVisible);
    },
    // 重置
    onReset: function onReset() {
      this.propForm.url_config.url_id = '';
      this.propForm.url_config.url_name = '';
      this.propForm.url_config.path = '';
      this.propForm.url_config.path_norule = '';
    },
    // 取消并清除数据
    onCancel: function onCancel() {
      this.onReset();
      this.dialogVisible = false;
      this.$emit('closeOrOpenDrag', this.dialogVisible);
    }
  }
};