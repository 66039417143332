var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "zy-common-config-wrapper" },
    [
      _c(
        "div",
        { staticClass: "zy-common-config-wrapper-title" },
        [
          _c("div", { staticClass: "font-title font-17" }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "font-15",
              attrs: { type: "text", size: "medium", icon: "el-icon-edit" },
              on: { click: _vm.openPopStatus },
            },
            [_vm._v("组件状态")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "slot-wrap" }, [
        _c("div", { staticClass: "slot-title font-15" }, [
          _c("div", [_vm._v("内容配置")]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  staticClass: "font-12",
                  attrs: {
                    size: "mini",
                    type: "primary",
                    plain: "",
                    icon: "el-icon-plus",
                  },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = true
                    },
                  },
                },
                [_vm._v("新增其他渠道内容配置项\n                ")]
              ),
              _vm._v(" "),
              _vm.cur_select_module[_vm.cur_select_module_index].component
                .length > 1
                ? _c(
                    "el-button",
                    {
                      staticClass: "font-12",
                      attrs: {
                        size: "mini",
                        type: "danger",
                        plain: "",
                        icon: "el-icon-delete",
                      },
                      on: { click: _vm.deleteHandle },
                    },
                    [_vm.showDelete ? _c("span", [_vm._v("取消")]) : _vm._e()]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _vm.canRender &&
        _vm.cur_select_module[_vm.cur_select_module_index].component.length > 1
          ? _c(
              "div",
              { staticClass: "slot-channel-list" },
              _vm._l(
                _vm.cur_select_module[_vm.cur_select_module_index].component,
                function (v, k) {
                  return _c("div", { key: k, staticClass: "items font-14" }, [
                    v.index > -1
                      ? _c(
                          "span",
                          {
                            class: {
                              active: _vm.active_content_index === v.index,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleTab(v.index, k)
                              },
                            },
                          },
                          [_vm._v("配置" + _vm._s(v.index + 1))]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showDelete
                      ? _c("span", {
                          staticClass: "el-icon-delete delete-box",
                          on: {
                            click: function ($event) {
                              return _vm.deleteChannelContentItem(k, v.index)
                            },
                          },
                        })
                      : _vm._e(),
                  ])
                }
              ),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.cur_select_module[_vm.cur_select_module_index].component.length >= 0
          ? _c(
              "div",
              { staticClass: "slot-content" },
              [
                _c(
                  "div",
                  { staticClass: "group-item-channel" },
                  [
                    _c("div", { staticClass: "tips font-10" }, [
                      _vm._v(
                        "\n                    说明：该渠道配置用于组件内容控制，如果需要根据渠道切换内容，点击【新增其他渠道内容配置项】按钮\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("ComChannel", {
                      attrs: {
                        labelWidth: "70px",
                        placeholder: "非必填，默认全渠道铺开",
                        update: _vm.update,
                        propForm:
                          _vm.cur_select_module[_vm.cur_select_module_index]
                            .component[_vm.active_content_key],
                      },
                      on: {
                        "update:propForm": function ($event) {
                          return _vm.$set(
                            _vm.cur_select_module[_vm.cur_select_module_index]
                              .component,
                            _vm.active_content_key,
                            $event
                          )
                        },
                        "update:prop-form": function ($event) {
                          return _vm.$set(
                            _vm.cur_select_module[_vm.cur_select_module_index]
                              .component,
                            _vm.active_content_key,
                            $event
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("ComRegChannels", {
                      attrs: {
                        placeholder: "非必选，默认全渠道铺开",
                        update: _vm.update,
                        propForm:
                          _vm.cur_select_module[_vm.cur_select_module_index]
                            .component[_vm.active_content_key],
                      },
                      on: {
                        "update:propForm": function ($event) {
                          return _vm.$set(
                            _vm.cur_select_module[_vm.cur_select_module_index]
                              .component,
                            _vm.active_content_key,
                            $event
                          )
                        },
                        "update:prop-form": function ($event) {
                          return _vm.$set(
                            _vm.cur_select_module[_vm.cur_select_module_index]
                              .component,
                            _vm.active_content_key,
                            $event
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.type !== "public"
                      ? _c("ComSchannel", {
                          attrs: {
                            labelWidth: "70px",
                            update: _vm.update,
                            propForm:
                              _vm.cur_select_module[_vm.cur_select_module_index]
                                .component[_vm.active_content_key],
                          },
                          on: {
                            "update:propForm": function ($event) {
                              return _vm.$set(
                                _vm.cur_select_module[
                                  _vm.cur_select_module_index
                                ].component,
                                _vm.active_content_key,
                                $event
                              )
                            },
                            "update:prop-form": function ($event) {
                              return _vm.$set(
                                _vm.cur_select_module[
                                  _vm.cur_select_module_index
                                ].component,
                                _vm.active_content_key,
                                $event
                              )
                            },
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.type == "public"
                      ? _c("ComSchannel", {
                          attrs: {
                            labelWidth: "70px",
                            propForm: {
                              schannel: "应用appid + 页面标识 + 组件ID",
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(_vm.currentConfig, {
                  ref: "configRef",
                  tag: "component",
                  attrs: {
                    update: _vm.update,
                    configLists:
                      _vm.cur_select_module[_vm.cur_select_module_index]
                        .component[_vm.active_content_key],
                    active_content_index: _vm.active_content_index,
                    styleConfig: _vm.styleConfig,
                  },
                  on: {
                    "update:configLists": function ($event) {
                      return _vm.$set(
                        _vm.cur_select_module[_vm.cur_select_module_index]
                          .component,
                        _vm.active_content_key,
                        $event
                      )
                    },
                    "update:config-lists": function ($event) {
                      return _vm.$set(
                        _vm.cur_select_module[_vm.cur_select_module_index]
                          .component,
                        _vm.active_content_key,
                        $event
                      )
                    },
                  },
                }),
                _vm._v(" "),
                _vm.moreConfigItem
                  ? _c("el-button", {
                      attrs: { icon: "el-icon-plus" },
                      on: { click: _vm.addConfigItem },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("ComPopStatus", {
        ref: "statusPop",
        attrs: {
          update: _vm.update,
          propData: _vm.cur_select_module[_vm.cur_select_module_index].status,
          type: _vm.type,
        },
        on: {
          "update:propData": function ($event) {
            return _vm.$set(
              _vm.cur_select_module[_vm.cur_select_module_index],
              "status",
              $event
            )
          },
          "update:prop-data": function ($event) {
            return _vm.$set(
              _vm.cur_select_module[_vm.cur_select_module_index],
              "status",
              $event
            )
          },
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "新增其他渠道内容配置项",
            visible: _vm.dialogVisible,
            width: "35%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "radio-box" },
            [
              _c("div", { staticClass: "font-16" }, [_vm._v("请选择新增项：")]),
              _vm._v(" "),
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.copySelect,
                    callback: function ($$v) {
                      _vm.copySelect = $$v
                    },
                    expression: "copySelect",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "0" } }, [_vm._v("空白项")]),
                  _vm._v(" "),
                  _vm._l(
                    _vm.cur_select_module[_vm.cur_select_module_index]
                      .component,
                    function (v, k) {
                      return _c(
                        "el-radio",
                        { key: k, attrs: { label: k + 1 } },
                        [_vm._v("复制项（配置" + _vm._s(v.index + 1) + "）")]
                      )
                    }
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.addChannelContentItem },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }