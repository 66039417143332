"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.symbol.async-iterator");
require("core-js/modules/es6.symbol");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.array.from");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.to-string");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _slicedToArray2 = _interopRequireDefault(require("/var/www/html/node_modules/@babel/runtime-corejs2/helpers/slicedToArray.js"));
var _pattern = require("@/api/pattern");
var _lodash = _interopRequireDefault(require("lodash"));
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    ruleForm: {
      type: Object,
      default: function _default() {
        return {
          fan_rate: 0 // 导粉率
        };
      }
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      rules: {
        fan_rate: {
          required: true,
          message: '请填写导粉率',
          trigger: 'blur'
        }
      },
      canClick: true,
      // 是否可以点击提交按钮
      guideTimeList: [] // 强导时间列表
    };
  },
  watch: {
    ruleForm: function ruleForm(value, oldValue) {
      this.guideTimeList = _lodash.default.cloneDeep(value.guideTimeList);
    }
  },
  methods: {
    submitForm: function submitForm(formName) {
      var _this = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this.handleEvent();
        } else {
          return false;
        }
      });
    },
    handleEvent: function handleEvent() {
      var _this2 = this;
      if (!this.canClick) {
        this.$message({
          message: '请勿重复提交！',
          type: 'warning'
        });
        return;
      }
      var newGuideTimeList = [];
      if (this.guideTimeList.length > 0) {
        var _iterator = _createForOfIteratorHelper(this.guideTimeList),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var item = _step.value;
            var _item = (0, _slicedToArray2.default)(item, 2),
              startDate = _item[0],
              endDate = _item[1];
            if (!startDate || !endDate) {
              this.$message({
                message: '请选择时间！',
                type: 'warning'
              });
              return;
            }
            if (startDate.getTime() === endDate.getTime()) {
              this.$message({
                message: '结束时间不能与开始时间相同！',
                type: 'warning'
              });
              return;
            }
            var newItem = this.getFormatTime(startDate, endDate);
            newGuideTimeList.push(newItem);
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      }
      var _this$ruleForm = this.ruleForm,
        id = _this$ruleForm.id,
        fan_rate = _this$ruleForm.fan_rate;
      var params = {
        fan_rate: fan_rate,
        basket: {
          guide_time: newGuideTimeList.length > 0 ? newGuideTimeList : '' // 兼容删除的情况
        }
      };
      (0, _pattern.editPattern)(id, params).then(function (_ref) {
        var code = _ref.code;
        if (code === 200) {
          _this2.$message({
            message: '修改成功',
            type: 'success'
          });
          _this2.dialogVisible = false;
          _this2.$parent.getList(); // 更新父组件数据
          _this2.canClick = true;
        }
      }).catch(function (err) {
        _this2.canClick = true;
      });
    },
    addPrefixZero: function addPrefixZero(number) {
      return number >= 10 ? number : '0' + number;
    },
    getFormatTime: function getFormatTime(startDate, endDate) {
      var sHour = this.addPrefixZero(startDate.getHours());
      var sMinute = this.addPrefixZero(startDate.getMinutes());
      var eHour = this.addPrefixZero(endDate.getHours());
      var eMinute = this.addPrefixZero(endDate.getMinutes());
      var time = "".concat(sHour, ":").concat(sMinute, "-").concat(eHour, ":").concat(eMinute);
      return time;
    },
    deleteGuideTime: function deleteGuideTime(index) {
      this.guideTimeList.splice(index, 1);
    },
    addGuideTime: function addGuideTime() {
      var guideTimeItem = [new Date(2024, 0, 1), new Date(2024, 0, 1)];
      this.guideTimeList.push(guideTimeItem);
    }
  }
};