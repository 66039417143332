var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "edit-wrapper" }, [
    _c(
      "div",
      { staticClass: "menu" },
      [
        _c("div", { staticClass: "font-18" }, [_vm._v("用户权限编辑")]),
        _vm._v(" "),
        _c(
          "el-button",
          {
            staticStyle: { "margin-bottom": "10px" },
            on: { click: _vm.cancel },
          },
          [_vm._v("返回")]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-wrap" },
      [
        _c(
          "el-form",
          {
            ref: "ruleForm",
            attrs: {
              model: _vm.ruleForm,
              "label-position": "right",
              "label-width": "100px",
            },
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "用户姓名", prop: "realname" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "用户姓名", disabled: true },
                  model: {
                    value: _vm.ruleForm.realname,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "realname", $$v)
                    },
                    expression: "ruleForm.realname",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "选择角色", prop: "role" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { multiple: "", placeholder: "请选择角色" },
                    on: { change: _vm.roleChange },
                    model: {
                      value: _vm.ruleForm.role,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "role", $$v)
                      },
                      expression: "ruleForm.role",
                    },
                  },
                  _vm._l(_vm.rolelist, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "允许登录", prop: "allow_login" } },
              [
                _c("el-switch", {
                  attrs: {
                    "active-color": "#13ce66",
                    "inactive-color": "#dcdfe6",
                    "active-value": 1,
                    "inactive-value": 0,
                  },
                  model: {
                    value: _vm.ruleForm.allow_login,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "allow_login", $$v)
                    },
                    expression: "ruleForm.allow_login",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.permitFlag
              ? _c(
                  "el-form-item",
                  { attrs: { label: "权限分配", prop: "selected" } },
                  [
                    _vm.permitList.length > 0
                      ? _c(
                          "div",
                          [
                            _c("el-transfer", {
                              staticStyle: {
                                "text-align": "left",
                                display: "inline-block",
                              },
                              attrs: {
                                filterable: "",
                                titles: ["选择权限", "已有权限"],
                                format: {
                                  noChecked: "${total}",
                                  hasChecked: "${checked}/${total}",
                                },
                                data: _vm.permitList,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var option = ref.option
                                      return _c("span", {}, [
                                        _vm._v(_vm._s(option.label)),
                                      ])
                                    },
                                  },
                                ],
                                null,
                                false,
                                3395026510
                              ),
                              model: {
                                value: _vm.ruleForm.selected,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "selected", $$v)
                                },
                                expression: "ruleForm.selected",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "span",
      {
        staticClass: "form-buttons",
        attrs: { slot: "footer" },
        slot: "footer",
      },
      [
        _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取 消")]),
        _vm._v(" "),
        _vm.id
          ? _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.submit } },
              [_vm._v("保 存")]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }