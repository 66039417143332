"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isAppId = isAppId;
exports.isChinese = isChinese;
exports.isChineseAndLine = isChineseAndLine;
exports.isCorrectDomain = isCorrectDomain;
exports.isCorrectUrl = isCorrectUrl;
exports.isExternal = isExternal;
exports.isUrlPath = isUrlPath;
exports.validUsername = validUsername;
/**
 * @param {string} path
 * @returns {Boolean}
 */
function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
function validUsername(str) {
  var valid_map = ['admin', 'editor'];
  return valid_map.indexOf(str.trim()) >= 0;
}

// 格式：只允许字母和数字
function isAppId(val) {
  return /^[0-9a-zA-Z]{1,}$/.test(val);
}

// 中文限制
function isChinese(val) {
  return /^[\u4e00-\u9fa5]+$/.test(val);
}

// 中文和横线限制
function isChineseAndLine(val) {
  return /^[\u4e00-\u9fa5|\-|\_]+$/.test(val);
}

// 链接匹配 (完整地址匹配)
function isCorrectUrl(val) {
  return /^https?:\/\/[a-zA-Z_0-9-\.]+\/*[0-9a-zA-Z_#-?=%\/&]*$/i.test(val);
}

// 网址匹配 （匹配到协议+域名部分，不包括/ http://baidu.com ）
function isCorrectDomain(val) {
  // return /^https?:\/\/[a-zA-Z_0-9-\.]+/i.test(val);
  return /^https?:\/\/[a-zA-Z_0-9\.]+$/i.test(val);
}

// 匹配路径部分 （/开头）
function isUrlPath(val) {
  return /^\/{1}[0-9a-zA-Z_#.?=%\/&-]*$/i.test(val);
}