"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var _user = require("@/api/user");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      loading: false,
      tableData: []
    };
  },
  mounted: function mounted() {
    this.getTableData();
  },
  methods: {
    getTableData: function getTableData() {
      var _this = this;
      this.loading = true;
      (0, _user.getRoleList)().then(function (res) {
        var data = res.data;
        _this.tableData = data.reverse();
        _this.loading = false;
      }).catch(function () {
        _this.loading = false;
      });
    },
    // 新增or编辑
    editRow: function editRow() {
      var row = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var path = "/auth/roleedit";
      if (row.id) {
        path = "".concat(path, "/").concat(row.id);
      }
      this.$router.push({
        path: path
      });
    },
    // 删除
    delRow: function delRow() {
      var _this2 = this;
      var row = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.$confirm('此操作将删除该角色，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var params = {
          id_list: JSON.stringify([row.id])
        };
        (0, _user.deleteRole)(params).then(function () {
          _this2.$message({
            type: 'success',
            message: '删除成功!'
          });
          _this2.getTableData();
        });
      }).catch(function (e) {});
    }
  }
};