var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "imageRuleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": _vm.labelWidth,
            "label-position": _vm.labelPosition,
          },
        },
        [
          _vm.groupFlag
            ? _c("el-form-item", { staticStyle: { "margin-bottom": "0" } }, [
                _c("div", { staticClass: "tips font-13" }, [
                  _vm._v("注意：如果不选择资源分组，图片将不会保存到图片库"),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.groupFlag
            ? _c(
                "el-form-item",
                { attrs: { label: "资源分组", prop: "group_id" } },
                [
                  _vm.imgGroupListData && _vm.imgGroupListData.length > 0
                    ? _c(
                        "div",
                        [
                          _c("el-cascader", {
                            attrs: {
                              placeholder:
                                "请选择图片资源分组（可输入搜索）(非必选)",
                              options: _vm.imgGroupListData,
                              props: _vm.groupProps,
                              clearable: "",
                              filterable: "",
                            },
                            on: { change: _vm.selectGroupHandle },
                            model: {
                              value: _vm.ruleForm.group_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "group_id", $$v)
                              },
                              expression: "ruleForm.group_id",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: _vm.labelTitle, prop: "img_url" } },
            [
              _c(
                "div",
                [
                  !_vm.ruleForm.img_url
                    ? _c(
                        "el-upload",
                        {
                          attrs: {
                            action: _vm.uploadImgUrl,
                            headers: { Authorization: _vm.token },
                            "list-type": "picture-card",
                            limit: 1,
                            multiple: false,
                            accept: "image/*",
                            "on-preview": _vm.handlePictureCardPreview,
                            "on-remove": _vm.handleRemove,
                            "before-upload": _vm.handleBeforeUpload,
                            "on-exceed": _vm.handleExceed,
                            "on-success": _vm.handleSuccess,
                            "on-error": _vm.handleError,
                            disabled: _vm.imgDisabled,
                          },
                        },
                        [_c("i", { staticClass: "el-icon-plus" })]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.ruleForm.img_url
                    ? _c(
                        "div",
                        { staticClass: "show-img-box" },
                        [
                          _c("el-image", {
                            staticStyle: { width: "150px", height: "150px" },
                            attrs: {
                              src: _vm.ruleForm.img_url,
                              fit: "contain",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-button", {
                            attrs: {
                              size: "mini",
                              type: "danger",
                              plain: "",
                              icon: "el-icon-delete",
                              disabled: _vm.imgDisabled,
                            },
                            on: { click: _vm.deletePic },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-input", {
                staticStyle: { "margin-top": "10px" },
                style: { width: _vm.inputWidth },
                attrs: {
                  placeholder: "也可以在此直接输入图片链接",
                  clearable: "",
                  disabled: _vm.imgDisabled,
                },
                on: { change: _vm.onChange },
                model: {
                  value: _vm.ruleForm.img_url,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "img_url", $$v)
                  },
                  expression: "ruleForm.img_url",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }