"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.object.freeze");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
var _defineProperty2 = _interopRequireDefault(require("/var/www/html/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _vuex = require("vuex");
var _yunying = require("@/api/yunying");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  components: {},
  props: {
    configLists: {
      type: Object,
      default: function _default() {
        return {
          config_data: []
        };
      }
    },
    update: {
      // 数据更新
      type: Function,
      default: function _default() {
        return function () {};
      }
    }
  },
  data: function data() {
    return {
      index: 0,
      wxgroup: [],
      teacher: [],
      value: '',
      uploadImg: {
        img_url: ''
      },
      rules: {
        wechat_group_id: [{
          required: true,
          message: '请选择分组',
          trigger: 'blur'
        }],
        template_id: [{
          required: true,
          message: '请选择分组',
          trigger: 'blur'
        }]
      }
    };
  },
  mounted: function mounted() {
    this.getWxGroup();
    this.getTeachers();
  },
  computed: _objectSpread({
    teacherInfo: function teacherInfo() {
      var index = this.configLists.config_data[0].template_id,
        teacher = this.teacher.filter(function (v) {
          return v.id === index;
        })[0] || {};
      if (!teacher.content) {
        return [];
      }
      var info = Object.keys(teacher.content).map(function (v) {
        var name = '';
        switch (v) {
          case 'name':
            name = '名称';
            break;
          case 'avatar_image':
            name = '头像';
            break;
          case 'introduce_image':
            name = '大图';
            break;
          case 'tip':
            name = '描述';
            break;
          case 'introduce':
            name = '其他';
            break;
          default:
            name = v;
        }
        return {
          name: name,
          id: v,
          val: teacher.content[v]
        };
      });
      return info;
    }
  }, (0, _vuex.mapState)({
    fansId: function fansId(state) {
      return state.components.cur_select_module[0].component_id;
    }
  })),
  methods: {
    getWxGroup: function getWxGroup() {
      var _this = this;
      var params = {
        page: 1,
        per_page: 1000
      };
      (0, _yunying.getWxGroup)(params).then(function (_ref) {
        var data = _ref.data,
          code = _ref.code;
        if (code === 200) {
          _this.wxgroup = Object.freeze(data.list);
        }
      });
    },
    getTeachers: function getTeachers() {
      var _this2 = this;
      var params = {
        page: 1,
        per_page: 100
      };
      (0, _yunying.getTeacher)(params).then(function (_ref2) {
        var data = _ref2.data,
          code = _ref2.code;
        if (code === 200) {
          var teacher = data.list.map(function (v) {
            var content = JSON.parse(v.content);
            return {
              content: content,
              name: "".concat(v.name, "-").concat(content.name),
              id: v.id
            };
          });
          _this2.teacher = Object.freeze(teacher);
          // this.wxgroup = Object.freeze(data.list);
        }
      });
    },
    deleteHandle: function deleteHandle(index) {
      var _this3 = this;
      this.$confirm('即将删除该项, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this3.$store.commit('components/DELETE_COMPONENT_CONFIG_ITEM', index);
        _this3.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(function () {});
    },
    validator: function validator() {
      return this.$refs.formRules.validate();
    }
  }
};