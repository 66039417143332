var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ComComponent", {
    ref: "comComponent",
    attrs: {
      title: "悬浮窗-1",
      id: _vm.id,
      keyId: _vm.keyId,
      tips: "说明：icon建议尺寸为80*80",
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          var configData = ref.configData
          return [
            configData.length >= 1
              ? _c(
                  "div",
                  _vm._l(
                    _vm.keyId == undefined ? [{}] : configData,
                    function (v, k) {
                      return _c(
                        "div",
                        {
                          key: k,
                          staticClass: "pop-top-bar",
                          on: {
                            click: function ($event) {
                              return _vm.onLocation(v.url_config)
                            },
                          },
                        },
                        [
                          _c(
                            "el-image",
                            {
                              staticClass: "img-icon",
                              attrs: { src: v.img_url, fit: "contain" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "image-default",
                                  attrs: { slot: "error" },
                                  slot: "error",
                                },
                                [_c("i", { staticClass: "el-icon-picture" })]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "text-box" }, [
                            _c("div", { staticClass: "font-15" }, [
                              _vm._v(_vm._s(v.title || "标题")),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "font-13" }, [
                              _vm._v(
                                _vm._s(v.desc || "描述内容描述内容描述内容")
                              ),
                            ]),
                          ]),
                        ],
                        1
                      )
                    }
                  ),
                  0
                )
              : _vm._e(),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }