import { render, staticRenderFns } from "./config.vue?vue&type=template&id=126e2472"
import script from "./config.vue?vue&type=script&lang=js"
export * from "./config.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('126e2472')) {
      api.createRecord('126e2472', component.options)
    } else {
      api.reload('126e2472', component.options)
    }
    module.hot.accept("./config.vue?vue&type=template&id=126e2472", function () {
      api.rerender('126e2472', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/modulestore/module/7-1-pop-bar-1/config.vue"
export default component.exports