"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    var validateFlag = function validateFlag(rule, value, callback) {
      if (value === '') {
        callback(new Error('请输入页面标识'));
      } else {
        var reg = /^[0-9a-zA-Z_-]{1,}$/;
        if (!reg.test(value)) {
          callback(new Error('只能由数字、字母、下划线/中横线组成'));
        }
        callback();
      }
    };
    return {
      dialogVisible: false,
      ruleForm: {
        name: ''
      },
      rules: {
        name: [{
          validator: validateFlag,
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    submit: function submit() {
      var _this = this;
      var name = this.ruleForm.name;
      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          _this.$emit('addFlagHandle', name);
        } else {
          return false;
        }
      });
    }
  }
};