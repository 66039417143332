var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ComComponent", {
    ref: "comComponent",
    attrs: {
      title: "图片列表-1",
      id: _vm.id,
      keyId: _vm.keyId,
      tips: "说明：(图片有间隔)，宽度750px，高度不限制。个数不限定",
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          var configData = ref.configData
          return [
            configData.length >= 1
              ? _c(
                  "div",
                  { staticClass: "item-lists" },
                  _vm._l(
                    _vm.keyId == undefined ? [{}, {}] : configData,
                    function (v, k) {
                      return _c(
                        "el-image",
                        {
                          key: k,
                          staticClass: "img-box",
                          attrs: { src: v.img_url, fit: "contain" },
                          on: {
                            click: function ($event) {
                              return _vm.onLocation(v.url_config)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "image-slot",
                              attrs: { slot: "error" },
                              slot: "error",
                            },
                            [_c("i", { staticClass: "el-icon-picture" })]
                          ),
                        ]
                      )
                    }
                  ),
                  1
                )
              : _vm._e(),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }