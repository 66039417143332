var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ComComponent",
    {
      ref: "comComponent",
      attrs: {
        title: "商城-1",
        id: _vm.id,
        keyId: _vm.keyId,
        tips: "说明：图片大小500*310",
      },
    },
    [
      [
        _c(
          "el-image",
          {
            staticClass: "img-icon",
            attrs: {
              src: "https://img-fe.tengzhihh.com/image/a63744e8e6bdaa-375x269.png",
              fit: "contain",
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "image-default",
                attrs: { slot: "error" },
                slot: "error",
              },
              [_c("i", { staticClass: "el-icon-picture" })]
            ),
          ]
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }