"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuedraggable = _interopRequireDefault(require("vuedraggable"));
var _yunying = require("@/api/yunying");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  components: {
    draggable: _vuedraggable.default
  },
  props: {
    configLists: {
      type: Object,
      default: function _default() {
        return {
          config_data: []
        };
      }
    },
    update: {
      // 数据更新
      type: Function,
      default: function _default() {
        return function () {};
      }
    },
    active_content_index: {}
  },
  computed: {
    MODULE_JSON: function MODULE_JSON() {
      return this.$store.state.components.MODULE_JSON;
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      loading: true,
      searchName: '',
      // 搜索输入
      checkList: [],
      // 选中的数据id
      dataList: [],
      // 接口数据
      dataListTotal: [],
      // 图文项接口数据 （初始完整数据）
      initPerNum: 1000 // 请求条目数量
    };
  },
  watch: {
    configLists: {
      handler: function handler() {
        this.update();
      },
      deep: true
    },
    active_content_index: function active_content_index(val) {
      // 不用active_content_key是因为可能在同一时间增减，active_content_key无变化
      // 这里需要根据选择的渠道配置，变更初始的数据展示
      this.initData();
    },
    checkList: function checkList(value) {
      // 监听用户选择，变更数据
      var dataListTotal = this.dataListTotal,
        configLists = this.configLists;
      if (dataListTotal) {
        var chooseList = [];
        value.map(function (val, key) {
          dataListTotal.map(function (item, index) {
            if (val == item.id) {
              chooseList.push({
                img_url: item.img_url,
                img_text_id: item.id,
                title: item.title,
                url_config: {
                  url_rule: item.url_rule,
                  url_id: item.url_id,
                  url_name: item.url_name || '',
                  path: item.path,
                  path_norule: item.path_norule
                }
              });
            }
          });
        });
        if (chooseList && chooseList.length > 0) {
          configLists.config_data = chooseList;
        }
      }
    }
  },
  mounted: function mounted() {
    this.getList();
    this.initData();
  },
  methods: {
    // 获取图文项数据
    getList: function getList(itemNum, title, type) {
      var _this = this;
      // todo 这里由于需要展示已选项，目前需把全部数据一次性请求回来，图文项数据量暂时不多，后续多再优化
      var params = {
        per_page: itemNum || this.initPerNum,
        page: 1
      };
      // 输入标题搜索
      if (title) {
        params.title = title;
      }
      (0, _yunying.imgTextList)(params).then(function (_ref) {
        var data = _ref.data;
        if (data.total_num > _this.initPerNum) {
          _this.getList(data.total_num);
        } else {
          _this.loading = false;
          _this.dataList = data.list;
          if (!title) {
            // 存储一份初始的完整数据，用于展示项,上面的dataList会根据搜索值发生变化
            _this.dataListTotal = data.list;
          }
        }
        if (type === 'update') {
          _this.loading = false;
          _this.$message({
            message: '数据更新成功',
            type: 'success'
          });
        }
      });
    },
    // 初始化处理  展示选中项
    initData: function initData() {
      var _this2 = this;
      var config_data = this.configLists.config_data;
      this.checkList = [];
      if (config_data && config_data.length > 0) {
        config_data.map(function (v, k) {
          if (v.title) {
            _this2.checkList.push(v.img_text_id);
          }
        });
      }
    },
    // 删除已选的某一项
    deleteHandle: function deleteHandle(index) {
      this.checkList.splice(index, 1);
    },
    // 新增项跳转
    toAddPage: function toAddPage() {
      window.open('/library/imgitemmanage', '_blank');
    },
    // 数据更新
    onUpDate: function onUpDate() {
      this.loading = true;
      this.getList(this.initPerNum, '', 'update');
    },
    // 搜索
    onSearch: function onSearch() {
      var searchName = this.searchName;
      this.getList(200, searchName);
    },
    // 选择
    chooseItemHandle: function chooseItemHandle(val, key) {
      // console.log(val, key);
    }
  }
};