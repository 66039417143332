var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "div",
        { staticClass: "menu-wrap" },
        [
          _c(
            "el-input",
            {
              staticClass: "width-200",
              attrs: { placeholder: "标识名搜索", clearable: "" },
              on: { change: _vm.onSearch },
              model: {
                value: _vm.searchName,
                callback: function ($$v) {
                  _vm.searchName = $$v
                },
                expression: "searchName",
              },
            },
            [
              _c("el-button", {
                attrs: { slot: "append", icon: "el-icon-search" },
                slot: "append",
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-input",
            {
              staticClass: "width-200",
              attrs: { placeholder: "导粉号搜索", clearable: "" },
              on: { change: _vm.onSearch },
              model: {
                value: _vm.searchId,
                callback: function ($$v) {
                  _vm.searchId = $$v
                },
                expression: "searchId",
              },
            },
            [
              _c("el-button", {
                attrs: { slot: "append", icon: "el-icon-search" },
                slot: "append",
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-plus" },
              on: { click: _vm.onAdd },
            },
            [_vm._v("新增")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%", "min-height": "60vh" },
          attrs: {
            "element-loading-text": "加载中...",
            "element-loading-background": "rgba(0, 0, 0, 0.3)",
            data: _vm.tableData,
            border: "",
            size: "medium",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "index",
              label: "序号",
              "min-width": "30",
              "header-align": "center",
              align: "center",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "name",
              label: "导粉组（标识）",
              "min-width": "100",
              "header-align": "center",
              align: "center",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "wechat_group_info",
              label: "导粉组详情",
              "min-width": "250",
              "header-align": "center",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.wechat_group_info
                      ? _c(
                          "div",
                          { staticClass: "wx-component-info" },
                          _vm._l(scope.row.wechat_group_info, function (v, k) {
                            return _c(
                              "el-tag",
                              {
                                key: k,
                                staticStyle: { margin: "4px" },
                                attrs: {
                                  type: v.status === 1 ? "success" : "danger",
                                  effect: "dark",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.groupFormat(v)) +
                                    "\n                    "
                                ),
                              ]
                            )
                          }),
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "weight",
              label: "导粉号权重",
              "min-width": "70",
              "header-align": "center",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [
                      _vm._v(
                        _vm._s(scope.row.weight ? scope.row.weight : "平均分配")
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "flag",
              label: "标识flag",
              "min-width": "90",
              "header-align": "center",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(scope.row.page_config, function (v, k) {
                    return _c(
                      "div",
                      { key: k, class: _vm.tableRowClassName(k) },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(v.flag) +
                            "\n                "
                        ),
                      ]
                    )
                  })
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "app_id",
              label: "app_id",
              "min-width": "90",
              "header-align": "center",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(scope.row.page_config, function (v, k) {
                    return _c(
                      "div",
                      { key: k, class: _vm.tableRowClassName(k) },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(v.app_id) +
                            "\n                "
                        ),
                      ]
                    )
                  })
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "updated_at",
              label: "上次同步时间",
              "min-width": "100",
              "header-align": "center",
              align: "center",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "cancel",
              label: "操作",
              "min-width": "100",
              "header-align": "center",
              align: "center",
              fixed: "right",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.onModify(scope.row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.onSync(scope.row)
                          },
                        },
                      },
                      [_vm._v("同步")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.totalPage > 0
        ? _c(
            "section",
            { staticClass: "pagination-wrap" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.currentPage,
                  "page-size": _vm.pageSize,
                  layout: "prev, pager, next, jumper",
                  "page-count": _vm.totalPage,
                },
                on: {
                  "current-change": _vm.handleCurrentChange,
                  "update:currentPage": function ($event) {
                    _vm.currentPage = $event
                  },
                  "update:current-page": function ($event) {
                    _vm.currentPage = $event
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("ConfigManage", {
        ref: "ConfigManage",
        attrs: { ruleForm: _vm.chooseData, manageType: _vm.manageType },
        on: {
          "update:ruleForm": function ($event) {
            _vm.chooseData = $event
          },
          "update:rule-form": function ($event) {
            _vm.chooseData = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }