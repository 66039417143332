var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.isModify ? "修改企微" : "新增企微",
            visible: _vm.dialogVisible,
            width: "600px",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "section",
            { staticClass: "lists" },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-position": "left",
                    "label-width": "110px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "主体", prop: "corp_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择主体",
                            clearable: "",
                            filterable: "",
                            disabled: _vm.isModify,
                          },
                          model: {
                            value: _vm.ruleForm.corp_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "corp_id", $$v)
                            },
                            expression: "ruleForm.corp_id",
                          },
                        },
                        _vm._l(_vm.companyList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: {
                              label: item.name + "（" + item.corp_id + "）",
                              value: item.corp_id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "账号ID", prop: "user_id" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入账号ID",
                          clearable: "",
                          maxlength: "50",
                          "show-word-limit": "",
                          disabled: _vm.isModify,
                        },
                        model: {
                          value: _vm.ruleForm.user_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "user_id", $$v)
                          },
                          expression: "ruleForm.user_id",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "账号昵称", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入账号昵称",
                          clearable: "",
                          maxlength: "50",
                          "show-word-limit": "",
                          disabled: _vm.isModify,
                        },
                        model: {
                          value: _vm.ruleForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "name", $$v)
                          },
                          expression: "ruleForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否监控", prop: "control" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.ruleForm.control,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "control", $$v)
                            },
                            expression: "ruleForm.control",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("是"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("否"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "监控优先级", prop: "weight" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.ruleForm.weight,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "weight", $$v)
                            },
                            expression: "ruleForm.weight",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("高"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("低"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.onCancel } }, [
                _vm._v("取消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("ruleForm")
                    },
                  },
                },
                [_vm._v("确定并同步")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }