var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "组件状态配置",
        visible: _vm.dialogVisible,
        width: "65%",
        center: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("section", { staticClass: "com-dialog-wrap" }, [
        _vm.propData
          ? _c("div", { staticClass: "group-item" }, [
              _c("div", { staticClass: "channel-box" }, [
                _c("div", { staticClass: "channel-box-left" }, [
                  _vm._v("\n                    全渠道状态\n                "),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "channel-box-right" },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.propData.default_visible,
                          callback: function ($$v) {
                            _vm.$set(_vm.propData, "default_visible", $$v)
                          },
                          expression: "propData.default_visible",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: "enable" } }, [
                          _vm._v("显示"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: "disable" } }, [
                          _vm._v("隐藏"),
                        ]),
                        _vm._v(" "),
                        _vm.type != "public"
                          ? _c("el-radio", { attrs: { label: "none" } }, [
                              _vm._v(
                                "\n                            空\n                        "
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.type != "public"
                      ? _c("div", { staticClass: "tips" }, [
                          _c("p", [
                            _c("span", [_vm._v("说明：")]),
                            _vm._v("1、若选择“空”，则会继承公共配置"),
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _c(
                              "span",
                              { staticStyle: { visibility: "hidden" } },
                              [_vm._v("说明：")]
                            ),
                            _vm._v(
                              "2、若选择“显示”或“隐藏”，则不会继承公共配置\n                        "
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "channel-box" }, [
                _c("div", { staticClass: "channel-box-left" }, [
                  _vm._v("渠道配置"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "channel-box-right" }, [
                  _c("div", { staticClass: "channel-box-item" }, [
                    _c("div", { staticClass: "font-14" }, [
                      _vm._v(
                        "\n                            以下渠道配置组件状态为："
                      ),
                      _c("span", [_vm._v("显示")]),
                      _vm._v(
                        "（若全渠道状态为显示，建议以下值留空）\n                        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-item" },
                      [
                        _c("label", [_vm._v("渠道")]),
                        _vm._v(" "),
                        _c("el-select", {
                          attrs: {
                            multiple: "",
                            filterable: "",
                            "allow-create": "",
                            "default-first-option": "",
                            remote: "",
                            "reserve-keyword": "",
                            placeholder:
                              "请输入渠道（多项条件组合，后面项优先级高于前面项；不选渠道和渠道组代表默认全渠道）",
                          },
                          model: {
                            value: _vm.propData.channel_item,
                            callback: function ($$v) {
                              _vm.$set(_vm.propData, "channel_item", $$v)
                            },
                            expression: "propData.channel_item",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-item" },
                      [
                        _c("label", [_vm._v("正则渠道组")]),
                        _vm._v(" "),
                        _c(
                          "el-select",
                          {
                            attrs: {
                              multiple: "",
                              placeholder: "请选择正则渠道组",
                              filterable: "",
                            },
                            model: {
                              value: _vm.propData.reg_channel_group,
                              callback: function ($$v) {
                                _vm.$set(_vm.propData, "reg_channel_group", $$v)
                              },
                              expression: "propData.reg_channel_group",
                            },
                          },
                          _vm._l(_vm.reg_channels, function (v, k) {
                            return _c("el-option", {
                              key: k,
                              attrs: {
                                label:
                                  "【" +
                                  v.name +
                                  "】" +
                                  (v.reg && "（") +
                                  v.reg.substring(0, 20) +
                                  (v.reg.length > 19 ? "..." : "") +
                                  (v.reg && "）"),
                                value: v.id,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "channel-box-item" }, [
                    _c("div", { staticClass: "font-14" }, [
                      _vm._v(
                        "\n                            以下渠道配置组件状态为："
                      ),
                      _c("span", [_vm._v("隐藏")]),
                      _vm._v(
                        "（若全渠道状态为隐藏，建议以下值留空）\n                        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-item" },
                      [
                        _c("label", [_vm._v("渠道")]),
                        _vm._v(" "),
                        _c("el-select", {
                          attrs: {
                            multiple: "",
                            filterable: "",
                            "allow-create": "",
                            "default-first-option": "",
                            remote: "",
                            "reserve-keyword": "",
                            placeholder:
                              "请输入渠道（多项条件组合，后面项优先级高于前面项；不选渠道和渠道组代表默认全渠道）",
                          },
                          model: {
                            value: _vm.propData.shield_channel,
                            callback: function ($$v) {
                              _vm.$set(_vm.propData, "shield_channel", $$v)
                            },
                            expression: "propData.shield_channel",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-item" },
                      [
                        _c("label", [_vm._v("正则渠道组")]),
                        _vm._v(" "),
                        _c(
                          "el-select",
                          {
                            attrs: {
                              multiple: "",
                              placeholder: "请选择正则渠道组",
                              filterable: "",
                            },
                            model: {
                              value: _vm.propData.shield_reg_channel_group,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.propData,
                                  "shield_reg_channel_group",
                                  $$v
                                )
                              },
                              expression: "propData.shield_reg_channel_group",
                            },
                          },
                          _vm._l(_vm.reg_channels, function (v, k) {
                            return _c("el-option", {
                              key: k,
                              attrs: {
                                label:
                                  "【" +
                                  v.name +
                                  "】" +
                                  (v.reg && "（") +
                                  v.reg.substring(0, 20) +
                                  (v.reg.length > 19 ? "..." : "") +
                                  (v.reg && "）"),
                                value: v.id,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }