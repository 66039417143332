"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    propForm: {
      type: Object,
      default: function _default() {
        return {
          schannel: ''
        };
      }
    },
    update: {
      // 数据更新
      type: Function,
      default: function _default() {
        return function () {};
      }
    },
    labelWidth: {
      // 标题宽度
      type: String,
      default: function _default() {
        return '55px';
      }
    },
    labelTitle: {
      // 标题
      type: String,
      default: function _default() {
        return 'schannel';
      }
    },
    placeholder: {
      // 提示内容
      type: String,
      default: function _default() {
        return '来源渠道';
      }
    },
    openRules: {
      // 是否开启规则验证
      type: Boolean,
      default: function _default() {
        return false;
      }
    }
  },
  watch: {
    propForm: {
      handler: function handler() {
        this.update();
      },
      deep: true
    }
  },
  data: function data() {
    return {
      rules: {
        schannel: {
          required: true,
          message: '设置来源渠道',
          trigger: 'blur'
        }
      }
    };
  },
  methods: {
    onCopy: function onCopy(e) {
      this.$message({
        type: 'success',
        message: '复制成功!',
        duration: 1000
      });
    },
    onError: function onError(e) {
      this.$message.error('复制失败!');
    }
  }
};