"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.navClassify = exports.comlist = void 0;
/**
 * by 长风
 *
 * 组件id与分类说明：组件命名【分类序号(从1开始) - 同类型序号（从1开始）- 组件名字】
 * *** 分类序号：详见下方 navClassify 定义
 * *** 同类型序号：举例【1-】代表banner
 *     那么组件命名：1-1-banner （banner组件）
 *                 1-2-banner-swiper （banner轮播组件）
 *                 1-3-banner-xxx (如果还有banner类型的其他组件采用这种方式类推)
 *
 * *** 组件id: 组件命名的前2位数字组成组件id。例如：1-1，1-2
 *
 * ps-注意事项：
 * 1、title不要重复，保持唯一
 * 2、相同类型使用同一icon图标
 */

var comlist = exports.comlist = [{
  icon: 'https://img-fe.tengzhihh.com/image/67cf99f21686a0-32x32.png',
  name: '1-1-banner',
  title: 'banner',
  id: '1-1',
  item_num: 1,
  // 初始配置项个数
  type: 'page'
}, {
  icon: 'https://img-fe.tengzhihh.com/image/9c883436eeb7e7-32x32.png',
  name: '1-2-banner-swiper',
  title: '轮播图',
  id: '1-2',
  item_num: 3,
  type: 'page'
}, {
  icon: 'https://img-fe.tengzhihh.com/image/d99c9294c80c11-32x32.png',
  name: '2-1-catalog-list-1',
  title: '目录列表-1',
  id: '2-1',
  item_num: 3,
  type: 'page'
}, {
  icon: 'https://img-fe.tengzhihh.com/image/ed7cf4e91787db-32x32.png',
  name: '3-1-graphic-list-1',
  title: '图文列表-1',
  id: '3-1',
  item_num: 8,
  type: 'page'
}, {
  icon: 'https://img-fe.tengzhihh.com/image/ed7cf4e91787db-32x32.png',
  name: '3-2-graphic-list-2',
  title: '图文列表-2',
  id: '3-2',
  item_num: 3,
  type: 'page'
}, {
  icon: 'https://img-fe.tengzhihh.com/image/ca69b76ced829f-32x32.png',
  name: '4-1-img-list-1',
  title: '图片列表-1',
  id: '4-1',
  item_num: 2,
  type: 'page'
}, {
  icon: 'https://img-fe.tengzhihh.com/image/ca69b76ced829f-32x32.png',
  name: '4-2-img-list-2',
  title: '图片列表-2',
  id: '4-2',
  item_num: 3,
  type: 'page'
}, {
  icon: 'https://img-fe.tengzhihh.com/image/2f47406e15d66d-32x32.png',
  name: '5-1-nav-bar-1',
  title: '导航栏-1',
  id: '5-1',
  item_num: 4,
  type: 'page'
}, {
  icon: 'https://img-fe.tengzhihh.com/image/2f47406e15d66d-32x32.png',
  name: '5-2-nav-bar-2',
  title: '导航栏-2',
  id: '5-2',
  item_num: 3,
  type: 'page'
}, {
  icon: 'https://img-fe.tengzhihh.com/image/829b5df17d0348-34x32.png',
  name: '6-1-pop-window-1',
  title: '弹窗-1',
  id: '6-1',
  item_num: 1,
  type: 'page'
}, {
  icon: 'https://img-fe.tengzhihh.com/image/dc7d18d04b8c4f-32x32.png',
  name: '7-1-pop-bar-1',
  title: '悬浮窗-1',
  id: '7-1',
  item_num: 1,
  type: 'page'
}, {
  icon: 'https://img-fe.tengzhihh.com/image/dc7d18d04b8c4f-32x32.png',
  name: '7-2-pop-bar-2',
  title: '悬浮窗-2',
  id: '7-2',
  item_num: 1,
  type: 'page'
}, {
  icon: 'https://img-fe.tengzhihh.com/image/fe8709a426564c-32x32.png',
  name: '7-3-pop-icon-1',
  title: '悬浮窗-3',
  id: '7-3',
  item_num: 1,
  type: 'page'
}, {
  icon: 'https://img-fe.tengzhihh.com/image/fe8709a426564c-32x32.png',
  name: '8-1-guide-add-wechat',
  title: '导粉-1',
  id: '8-1',
  item_num: 1,
  type: 'fans'
}, {
  icon: 'https://img-fe.tengzhihh.com/image/fe8709a426564c-32x32.png',
  name: '8-2-guide-add-wechat',
  title: '导粉-2',
  id: '8-2',
  item_num: 1,
  type: 'fans'
}, {
  icon: 'https://img-fe.tengzhihh.com/image/fe8709a426564c-32x32.png',
  name: '9-1-goods',
  title: '商城-1',
  id: '9-1',
  item_num: 1,
  type: 'goods'
}];

// 分类
var navClassify = exports.navClassify = [{
  title: '全部',
  componentList: [],
  iconList: [],
  type: 'page'
}, {
  id: '1-',
  title: 'banner',
  componentList: [],
  iconList: [],
  type: 'page'
}, {
  id: '2-',
  title: '目录列表',
  componentList: [],
  iconList: [],
  type: 'page'
}, {
  id: '3-',
  title: '图文列表',
  componentList: [],
  iconList: [],
  type: 'page'
}, {
  id: '4-',
  title: '图片列表',
  componentList: [],
  iconList: [],
  type: 'page'
}, {
  id: '5-',
  title: '导航栏',
  componentList: [],
  iconList: [],
  type: 'page'
}, {
  id: '6-',
  title: '弹窗',
  componentList: [],
  iconList: [],
  type: 'page'
}, {
  id: '7-',
  title: '悬浮',
  componentList: [],
  iconList: [],
  type: 'page'
}, {
  id: '8-',
  title: '导粉',
  componentList: [],
  iconList: [],
  type: 'fans'
}, {
  id: '9-',
  title: '商城',
  componentList: [],
  iconList: [],
  type: 'goods'
}];