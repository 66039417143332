var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ComConfig", {
    attrs: {
      title: "目录列表-1",
      component_name: "2-1-catalog-list-1",
      styleConfig: _vm.styleConfig,
      initConfigNum: 3,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }