var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("div", { staticClass: "tips font-10" }, [
        _vm._v("提示：拖拽可调整配置项顺序"),
      ]),
      _vm._v(" "),
      _c(
        "draggable",
        {
          attrs: { disabled: _vm.disabled },
          model: {
            value: _vm.configLists.config_data,
            callback: function ($$v) {
              _vm.$set(_vm.configLists, "config_data", $$v)
            },
            expression: "configLists.config_data",
          },
        },
        _vm._l(_vm.configLists.config_data, function (v, k) {
          return _c("div", { key: k }, [
            _c("section", { staticClass: "config-item" }, [
              k !== 0 || _vm.configLists.config_data.length > 1
                ? _c("div", {
                    staticClass: "delete el-icon-delete",
                    on: {
                      click: function ($event) {
                        return _vm.deleteHandle(k)
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "config-item-left" },
                [
                  _c("ComImage", {
                    attrs: {
                      update: _vm.update,
                      propForm: _vm.configLists.config_data[k],
                    },
                    on: {
                      "update:propForm": function ($event) {
                        return _vm.$set(_vm.configLists.config_data, k, $event)
                      },
                      "update:prop-form": function ($event) {
                        return _vm.$set(_vm.configLists.config_data, k, $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "config-item-right" },
                [
                  (_vm.styleConfig.titleShow || "show") === "show"
                    ? _c("ComTitle", {
                        attrs: {
                          placeholder: _vm.styleConfig.titlePlaceholder,
                          update: _vm.update,
                          propForm: _vm.configLists.config_data[k],
                        },
                        on: {
                          "update:propForm": function ($event) {
                            return _vm.$set(
                              _vm.configLists.config_data,
                              k,
                              $event
                            )
                          },
                          "update:prop-form": function ($event) {
                            return _vm.$set(
                              _vm.configLists.config_data,
                              k,
                              $event
                            )
                          },
                          closeOrOpenDrag: _vm.closeOrOpenDrag,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.styleConfig.descShow === "show"
                    ? _c("ComDesc", {
                        attrs: {
                          placeholder: _vm.styleConfig.descPlaceholder,
                          update: _vm.update,
                          propForm: _vm.configLists.config_data[k],
                        },
                        on: {
                          "update:propForm": function ($event) {
                            return _vm.$set(
                              _vm.configLists.config_data,
                              k,
                              $event
                            )
                          },
                          "update:prop-form": function ($event) {
                            return _vm.$set(
                              _vm.configLists.config_data,
                              k,
                              $event
                            )
                          },
                          closeOrOpenDrag: _vm.closeOrOpenDrag,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("ComUrl", {
                    attrs: {
                      ruleFlag: false,
                      update: _vm.update,
                      propForm: _vm.configLists.config_data[k],
                    },
                    on: {
                      "update:propForm": function ($event) {
                        return _vm.$set(_vm.configLists.config_data, k, $event)
                      },
                      "update:prop-form": function ($event) {
                        return _vm.$set(_vm.configLists.config_data, k, $event)
                      },
                      closeOrOpenDrag: _vm.closeOrOpenDrag,
                    },
                  }),
                  _vm._v(" "),
                  _vm.styleConfig.numPeopleShow === "show"
                    ? _c("ComNumPeople", {
                        attrs: {
                          placeholder: _vm.styleConfig.numPeoplePlaceholder,
                          update: _vm.update,
                          propForm: _vm.configLists.config_data[k],
                        },
                        on: {
                          "update:propForm": function ($event) {
                            return _vm.$set(
                              _vm.configLists.config_data,
                              k,
                              $event
                            )
                          },
                          "update:prop-form": function ($event) {
                            return _vm.$set(
                              _vm.configLists.config_data,
                              k,
                              $event
                            )
                          },
                          closeOrOpenDrag: _vm.closeOrOpenDrag,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.styleConfig.numCommentShow === "show"
                    ? _c("ComNumComment", {
                        attrs: {
                          placeholder: _vm.styleConfig.numCommentPlaceholder,
                          update: _vm.update,
                          propForm: _vm.configLists.config_data[k],
                        },
                        on: {
                          "update:propForm": function ($event) {
                            return _vm.$set(
                              _vm.configLists.config_data,
                              k,
                              $event
                            )
                          },
                          "update:prop-form": function ($event) {
                            return _vm.$set(
                              _vm.configLists.config_data,
                              k,
                              $event
                            )
                          },
                          closeOrOpenDrag: _vm.closeOrOpenDrag,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.styleConfig.styleTypeShow === "show"
                    ? _c("ComStyleType", {
                        attrs: {
                          update: _vm.update,
                          propForm: _vm.configLists.config_data[k],
                        },
                        on: {
                          "update:propForm": function ($event) {
                            return _vm.$set(
                              _vm.configLists.config_data,
                              k,
                              $event
                            )
                          },
                          "update:prop-form": function ($event) {
                            return _vm.$set(
                              _vm.configLists.config_data,
                              k,
                              $event
                            )
                          },
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  (_vm.styleConfig.extendShow || "show") === "show"
                    ? _c("ComExtend", {
                        attrs: {
                          update: _vm.update,
                          propForm: _vm.configLists.config_data[k],
                        },
                        on: {
                          "update:propForm": function ($event) {
                            return _vm.$set(
                              _vm.configLists.config_data,
                              k,
                              $event
                            )
                          },
                          "update:prop-form": function ($event) {
                            return _vm.$set(
                              _vm.configLists.config_data,
                              k,
                              $event
                            )
                          },
                          closeOrOpenDrag: _vm.closeOrOpenDrag,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ])
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }