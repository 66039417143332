var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "section",
        { staticClass: "search-area" },
        [
          _c(
            "el-input",
            {
              staticClass: "input-with-select width-300",
              attrs: { placeholder: "输入应用名称/APPID", clearable: "" },
              on: { change: _vm.onSearch },
              model: {
                value: _vm.searchInput,
                callback: function ($$v) {
                  _vm.searchInput = $$v
                },
                expression: "searchInput",
              },
            },
            [
              _c("el-button", {
                attrs: { slot: "append", icon: "el-icon-search" },
                slot: "append",
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", plain: "" },
                  on: {
                    click: function ($event) {
                      return _vm.onTableHandle(_vm.tableExpand)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.tableExpand == "0"
                          ? "展开表格所有行"
                          : "收起表格所有行"
                      ) +
                      "\n            "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-plus" },
                  on: { click: _vm.onAdd },
                },
                [_vm._v("新增")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "section",
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "default-table",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.defaultTableData,
                "row-key": _vm.getRowKeys,
                "expand-row-keys": _vm.expandsDefault,
                border: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { type: "expand" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-table",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { data: scope.row.childrens, border: "" },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "title",
                                label: "页面名称",
                                "min-width": "180",
                                "header-align": "center",
                                align: "center",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "flag",
                                label: "页面标识",
                                "min-width": "100",
                                "header-align": "center",
                                align: "center",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "updated_at",
                                label: "上次修改时间",
                                "min-width": "160",
                                "header-align": "center",
                                align: "center",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "last_at",
                                label: "上次同步时间",
                                "min-width": "160",
                                "header-align": "center",
                                align: "center",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "cancel",
                                label: "操作",
                                "min-width": "180",
                                "header-align": "center",
                                align: "center",
                                fixed: "right",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-link",
                                          {
                                            attrs: { type: "primary" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.onConfig(
                                                  scope.row,
                                                  "public"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    资源配置\n                                "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                staticClass: "table-head-style",
                attrs: {
                  prop: "name",
                  label: "公共配置模板名称",
                  "min-width": "100",
                  "header-align": "center",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                staticClass: "table-head-style",
                attrs: {
                  prop: "version",
                  label: "版本号",
                  "min-width": "100",
                  "header-align": "center",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "app_id",
                  label: "app_id",
                  "min-width": "70",
                  "header-align": "center",
                  align: "center",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "section",
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "row-key": _vm.getRowKeys,
                "expand-row-keys": _vm.expands,
                border: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { type: "expand" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-table",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { data: scope.row.childrens, border: "" },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "title",
                                label: "页面名称",
                                "min-width": "180",
                                "header-align": "center",
                                align: "center",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "flag",
                                label: "页面标识",
                                "min-width": "100",
                                "header-align": "center",
                                align: "center",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "sandbox_url",
                                label: "配置页测服链接",
                                "min-width": "120",
                                "header-align": "center",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.sandbox_url
                                          ? _c(
                                              "div",
                                              [
                                                _c(
                                                  "el-link",
                                                  {
                                                    attrs: { type: "info" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.checkLink(
                                                          scope.row.sandbox_url
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                        查看\n                                    "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-link",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "clipboard",
                                                        rawName:
                                                          "v-clipboard:copy",
                                                        value:
                                                          scope.row.sandbox_url,
                                                        expression:
                                                          "scope.row.sandbox_url",
                                                        arg: "copy",
                                                      },
                                                      {
                                                        name: "clipboard",
                                                        rawName:
                                                          "v-clipboard:success",
                                                        value: _vm.onCopy,
                                                        expression: "onCopy",
                                                        arg: "success",
                                                      },
                                                      {
                                                        name: "clipboard",
                                                        rawName:
                                                          "v-clipboard:error",
                                                        value: _vm.onError,
                                                        expression: "onError",
                                                        arg: "error",
                                                      },
                                                    ],
                                                    attrs: { type: "info" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                        复制\n                                    "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "div",
                                              { staticClass: "tips-nolink" },
                                              [_vm._v("还未配置")]
                                            ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "production_url",
                                label: "配置页线上链接",
                                "min-width": "120",
                                "header-align": "center",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.production_url
                                          ? _c(
                                              "div",
                                              [
                                                _c(
                                                  "el-link",
                                                  {
                                                    attrs: { type: "info" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.checkLink(
                                                          scope.row
                                                            .production_url
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                        查看\n                                    "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-link",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "clipboard",
                                                        rawName:
                                                          "v-clipboard:copy",
                                                        value:
                                                          scope.row
                                                            .production_url,
                                                        expression:
                                                          "scope.row.production_url",
                                                        arg: "copy",
                                                      },
                                                      {
                                                        name: "clipboard",
                                                        rawName:
                                                          "v-clipboard:success",
                                                        value: _vm.onCopy,
                                                        expression: "onCopy",
                                                        arg: "success",
                                                      },
                                                      {
                                                        name: "clipboard",
                                                        rawName:
                                                          "v-clipboard:error",
                                                        value: _vm.onError,
                                                        expression: "onError",
                                                        arg: "error",
                                                      },
                                                    ],
                                                    attrs: { type: "info" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                        复制\n                                    "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "div",
                                              { staticClass: "tips-nolink" },
                                              [_vm._v("还未配置")]
                                            ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "created_at",
                                label: "创建时间",
                                "min-width": "160",
                                "header-align": "center",
                                align: "center",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "updated_at",
                                label: "上次同步时间",
                                "min-width": "160",
                                "header-align": "center",
                                align: "center",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "cancel",
                                label: "操作",
                                "min-width": "180",
                                "header-align": "center",
                                align: "center",
                                fixed: "right",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-link",
                                          {
                                            attrs: { type: "primary" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.onModify(scope.row)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    修改\n                                "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-link",
                                          {
                                            attrs: { type: "primary" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.onConfig(scope.row)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    资源配置\n                                "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("el-button", {
                                          attrs: {
                                            type: "text",
                                            icon: "el-icon-delete",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onDelete(scope.row)
                                            },
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "name",
                  label: "应用名称",
                  "min-width": "100",
                  "header-align": "center",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "app_id",
                  label: "APPID（数据平台id）",
                  "min-width": "70",
                  "header-align": "center",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "sandbox_url",
                  label: "应用测服链接",
                  "min-width": "130",
                  "header-align": "center",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.sandbox_url
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-link",
                                  {
                                    attrs: { type: "info" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.checkLink(
                                          scope.row.sandbox_url
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            查看\n                        "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-link",
                                  {
                                    directives: [
                                      {
                                        name: "clipboard",
                                        rawName: "v-clipboard:copy",
                                        value: scope.row.sandbox_url,
                                        expression: "scope.row.sandbox_url",
                                        arg: "copy",
                                      },
                                      {
                                        name: "clipboard",
                                        rawName: "v-clipboard:success",
                                        value: _vm.onCopy,
                                        expression: "onCopy",
                                        arg: "success",
                                      },
                                      {
                                        name: "clipboard",
                                        rawName: "v-clipboard:error",
                                        value: _vm.onError,
                                        expression: "onError",
                                        arg: "error",
                                      },
                                    ],
                                    attrs: { type: "info" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            复制\n                        "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _c("div", { staticClass: "tips-nolink" }, [
                              _vm._v("还未配置"),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "production_url",
                  label: "应用正式链接",
                  "min-width": "130",
                  "header-align": "center",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.production_url
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-link",
                                  {
                                    attrs: { type: "info" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.checkLink(
                                          scope.row.production_url
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            查看\n                        "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-link",
                                  {
                                    directives: [
                                      {
                                        name: "clipboard",
                                        rawName: "v-clipboard:copy",
                                        value: scope.row.production_url,
                                        expression: "scope.row.production_url",
                                        arg: "copy",
                                      },
                                      {
                                        name: "clipboard",
                                        rawName: "v-clipboard:success",
                                        value: _vm.onCopy,
                                        expression: "onCopy",
                                        arg: "success",
                                      },
                                      {
                                        name: "clipboard",
                                        rawName: "v-clipboard:error",
                                        value: _vm.onError,
                                        expression: "onError",
                                        arg: "error",
                                      },
                                    ],
                                    attrs: { type: "info" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            复制\n                        "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _c("div", { staticClass: "tips-nolink" }, [
                              _vm._v("还未配置"),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.tablePage.total_page > 0
        ? _c(
            "section",
            { staticClass: "pagination-wrap" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.tablePage.current,
                  "page-size": _vm.tablePage.per_page,
                  layout: "prev, pager, next, jumper",
                  "page-count": _vm.tablePage.total_page,
                },
                on: {
                  "current-change": _vm.handleCurrentChange,
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.tablePage, "current", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.tablePage, "current", $event)
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("Manage", {
        ref: "managePop",
        attrs: { manageType: _vm.manageType, formData: _vm.chooseData },
        on: {
          "update:formData": function ($event) {
            _vm.chooseData = $event
          },
          "update:form-data": function ($event) {
            _vm.chooseData = $event
          },
          upDateList: _vm.upDateList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }