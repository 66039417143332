var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "场景码列表修改",
        visible: _vm.dialogVisible,
        width: "60%",
        center: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.ruleForm,
            "label-position": "left",
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "状态", prop: "status" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择状态" },
                  model: {
                    value: _vm.ruleForm.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "status", $$v)
                    },
                    expression: "ruleForm.status",
                  },
                },
                _vm._l(_vm.statusList, function (item) {
                  return _c("el-option", {
                    key: item.label,
                    attrs: { label: item.value, value: item.label },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "渠道号", prop: "channel_id" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入渠道号" },
                model: {
                  value: _vm.ruleForm.channel_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "channel_id", $$v)
                  },
                  expression: "ruleForm.channel_id",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "产品id", prop: "product_id" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入产品id" },
                model: {
                  value: _vm.ruleForm.product_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "product_id", $$v)
                  },
                  expression: "ruleForm.product_id",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "有效时间", prop: "expired_at" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "datetime",
                  placeholder: "选择日期时间",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                },
                model: {
                  value: _vm.ruleForm.expired_at,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "expired_at", $$v)
                  },
                  expression: "ruleForm.expired_at",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.onCancel } }, [_vm._v("取 消")]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submitForm("ruleForm")
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }