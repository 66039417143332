var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ComComponent", {
    ref: "comComponent",
    attrs: {
      title: "图文列表-2",
      id: _vm.id,
      keyId: _vm.keyId,
      tips: "说明：banner尺寸宽度750px，高度不限制，默认平铺。icon尺寸为150*150。组件模板可复用多个，形成资源瀑布流",
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          var configData = ref.configData
          return [
            configData.length >= 1
              ? _c(
                  "div",
                  { staticClass: "item-lists" },
                  _vm._l(
                    _vm.keyId == undefined ? _vm.nullData : configData,
                    function (v, k) {
                      return _c(
                        "div",
                        {
                          key: k,
                          staticClass: "items",
                          on: {
                            click: function ($event) {
                              return _vm.onLocation(v.url_config)
                            },
                          },
                        },
                        [
                          v.style_id === "banner"
                            ? _c(
                                "div",
                                { staticClass: "items-banner" },
                                [
                                  _c(
                                    "el-image",
                                    {
                                      staticClass: "items-banner-img",
                                      attrs: { src: v.img_url, fit: "contain" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "image-slot",
                                          attrs: { slot: "error" },
                                          slot: "error",
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-picture",
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "items-banner-text" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "items-title font-15" },
                                        [_vm._v(_vm._s(v.title || "标题"))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "items-dec font-12" },
                                        [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(v.desc) +
                                              "\n                        "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "items-other-dec font-12",
                                        },
                                        [
                                          _c("span", {
                                            staticClass: "icon-ce",
                                          }),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(v.people_num || 0) +
                                                "人已测"
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("span", {
                                            staticClass: "icon-zan",
                                          }),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(v.comment_num || 0) +
                                                "人已评价"
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          v.style_id === "icon"
                            ? _c(
                                "div",
                                { staticClass: "items-subitem" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "items-subitem-left" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "items-title font-15" },
                                        [_vm._v(_vm._s(v.title || "标题"))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "items-dec font-12" },
                                        [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(
                                                v.desc ||
                                                  "描述描述描述描述描述描述描述描述描述描述"
                                              ) +
                                              "\n                        "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "items-other-dec font-12",
                                        },
                                        [
                                          _c("span", {
                                            staticClass: "icon-ce",
                                          }),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(v.people_num || 0) +
                                                "人已测"
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("span", {
                                            staticClass: "icon-zan",
                                          }),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(v.comment_num || 0) +
                                                "人已评价"
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-image",
                                    {
                                      staticClass: "items-subitem-right",
                                      attrs: { src: v.img_url, fit: "contain" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "image-slot",
                                          attrs: { slot: "error" },
                                          slot: "error",
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-picture",
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      )
                    }
                  ),
                  0
                )
              : _vm._e(),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }