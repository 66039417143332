"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _defineProperty2 = _interopRequireDefault(require("/var/www/html/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _backend = require("@/api/backend");
var _vuex = require("vuex");
var _lodash = _interopRequireDefault(require("lodash"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    manageType: {
      type: String,
      default: ''
    },
    ruleForm: {
      type: Object,
      default: function _default() {
        return {
          name: '',
          // 配置标签名称
          value: '',
          // 配置值
          channel_id: '',
          // 渠道
          product_id: '',
          // 产品ID ， 所有产品则为：all
          mode: '' // 模式：模糊匹配- fuzzy 精准匹配 - full
        };
      }
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      rules: {
        name: {
          required: true,
          message: '请输入配置标签名称',
          trigger: 'blur'
        },
        value: {
          required: true,
          message: '请输入配置值',
          trigger: 'blur'
        },
        channel_id: {
          required: true,
          message: '请输入渠道',
          trigger: 'blur'
        },
        product_id: {
          required: true,
          message: '请输入产品ID',
          trigger: 'blur'
        },
        mode: {
          required: true,
          message: '请选择模式',
          trigger: 'blur'
        }
      }
    };
  },
  computed: (0, _vuex.mapState)({
    all_products: function all_products(state) {
      var from = this.$route.query.from;
      var products = [];
      switch (from) {
        case 'tf':
          products = _lodash.default.cloneDeep(state.common.all_tf_products);
          break;
        case 'qm':
          products = _lodash.default.cloneDeep(state.common.all_qm_products);
          break;
        default:
          products = _lodash.default.cloneDeep(state.common.all_products);
      }
      var item = {
        id: 'all',
        name: '全部测算'
      };
      if (products) {
        products.unshift(item);
      }
      return products;
    }
  }),
  mounted: function mounted() {
    var from = this.$route.query.from;
    switch (from) {
      case 'tf':
        this.getAllTfProducts();
        break;
      case 'qm':
        this.getAllQmProducts();
        break;
      default:
        this.getAllProducts();
    }
  },
  methods: _objectSpread(_objectSpread({}, (0, _vuex.mapActions)({
    getAllProducts: 'common/getAllProducts',
    getAllTfProducts: 'common/getAllTfProducts',
    getAllQmProducts: 'common/getAllQmProducts'
  })), {}, {
    onCancel: function onCancel() {
      this.dialogVisible = false;
    },
    submitForm: function submitForm(formName) {
      var _this = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this.handleEvent();
        } else {
          return false;
        }
      });
    },
    handleEvent: function handleEvent() {
      var _this2 = this;
      var _this$ruleForm = this.ruleForm,
        id = _this$ruleForm.id,
        name = _this$ruleForm.name,
        value = _this$ruleForm.value,
        channel_id = _this$ruleForm.channel_id,
        product_id = _this$ruleForm.product_id,
        mode = _this$ruleForm.mode;
      var from = this.$route.query.from;
      var params = {
        name: name,
        value: value,
        channel_id: channel_id,
        product_id: product_id,
        mode: mode
      };
      if (from) {
        params.from = from;
      }
      if (this.manageType === 'add') {
        (0, _backend.addProductConfig)(params).then(function (_ref) {
          var code = _ref.code;
          if (code === 200) {
            _this2.$message({
              message: '添加成功',
              type: 'success'
            });
            _this2.dialogVisible = false;
            _this2.$parent.getList(); // 更新父组件数据
          }
        });
      } else {
        (0, _backend.editProductConfig)(id, params).then(function (_ref2) {
          var code = _ref2.code;
          if (code === 200) {
            _this2.$message({
              message: '修改成功',
              type: 'success'
            });
            _this2.dialogVisible = false;
            _this2.$parent.getList(); // 更新父组件数据
          }
        });
      }
    }
  })
};