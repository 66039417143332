"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.object.freeze");
var _monitor = require("@/api/monitor");
var _workWechatManage = _interopRequireDefault(require("./components/work-wechat-manage"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    WorkWxManage: _workWechatManage.default
  },
  data: function data() {
    return {
      searchAccountName: '',
      // 账号昵称搜索
      searchAccountID: '',
      // 账号ID搜索
      searchCompanyId: '',
      // 主体ID搜索
      tableData: [],
      manageType: '',
      // 弹窗操作类型
      chooseData: {},
      currentPage: 1,
      totalPage: 1,
      perPage: 30,
      companyList: [],
      loading: true
    };
  },
  mounted: function mounted() {
    this.getWorkWxCompanyList();
    this.getList();
  },
  methods: {
    // 企业微信主体列表
    getWorkWxCompanyList: function getWorkWxCompanyList() {
      var _this = this;
      (0, _monitor.getCompanyList)().then(function (_ref) {
        var data = _ref.data,
          code = _ref.code;
        if (code === 200) {
          _this.companyList = Object.freeze(data);
        }
      });
    },
    // 企业微信号列表数据
    getList: function getList() {
      var _this2 = this;
      var params = {
        page: this.currentPage,
        per_page: this.perPage,
        corp_id: this.searchCompanyId,
        user_id: this.searchAccountID,
        name: this.searchAccountName
      };
      (0, _monitor.getWorkWxList)(params).then(function (_ref2) {
        var data = _ref2.data,
          code = _ref2.code;
        _this2.loading = false;
        if (code === 200) {
          _this2.tableData = Object.freeze(data.list);
          _this2.totalPage = data.total_page;
        }
      }).catch(function (err) {
        console.log(err);
        _this2.loading = false;
      });
    },
    // 分页
    handleCurrentChange: function handleCurrentChange(page) {
      this.currentPage = page;
      this.getList();
    },
    onSearch: function onSearch() {
      if (this.searchAccountID && !this.searchAccountID.trim()) {
        this.$message({
          message: '请输入搜索账号ID！',
          type: 'warning'
        });
        // 清除前后空格
        this.searchAccountID = this.searchAccountID.trim();
        return;
      }
      if (this.searchAccountName && !this.searchAccountName.trim()) {
        this.$message({
          message: '请输入搜索账号昵称！',
          type: 'warning'
        });
        // 清除前后空格
        this.searchAccountName = this.searchAccountName.trim();
        return;
      }
      // 搜索重置
      this.loading = true;
      this.totalPage = 1;
      this.currentPage = 1;
      this.getList();
    },
    onAdd: function onAdd() {
      this.chooseData = {
        corp_id: '',
        user_id: '',
        name: '',
        control: 1,
        apply: 1,
        weight: 0
      };
      this.manageType = 'add';
      this.$refs.managePop.dialogVisible = true;
    },
    onModify: function onModify(val) {
      this.chooseData = val;
      this.manageType = 'modify';
      this.$refs.managePop.dialogVisible = true;
    },
    onDelete: function onDelete(val) {
      var _this3 = this;
      this.$confirm('确认删除？').then(function (_) {
        (0, _monitor.deleteWorkWx)(val.id).then(function (_ref3) {
          var code = _ref3.code;
          if (code === 200) {
            _this3.$message({
              message: '删除成功',
              type: 'success'
            });
            // 更新列表
            _this3.getList();
          }
        });
      }).catch(function (_) {});
    }
  }
};