"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getOrderList = getOrderList;
exports.reportOrder = reportOrder;
var _request = _interopRequireDefault(require("@/utils/request"));
// 投放回传数据配置（手动上报订单）

/**
 * 获取订单列表
 * https://yapi.linghit.cn/project/755/interface/api/69178
 * @param {object} params
 * params 包含：
 *   channel-渠道（非必需）
 *   channel_mode-渠道匹配模式（非必需）
 *   status-上报状态（非必需）
 *   start-开始时间（非必需）
 *   end-结束时间（非必需）
 *   page-当前页码（非必需）
 */
function getOrderList(params) {
  return (0, _request.default)({
    url: '/resource/ad_report_order/list',
    method: 'get',
    params: params
  });
}

/**
 * 上报订单
 * https://yapi.linghit.cn/project/755/interface/api/69179
 * @param {array} ids
 * @returns
 */
function reportOrder(ids) {
  return (0, _request.default)({
    url: '/resource/ad_report_order/conversion',
    method: 'post',
    data: {
      ids: ids
    }
  });
}