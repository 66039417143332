"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/var/www/html/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/es6.object.freeze");
var _wechatapp = require("@/api/wechatapp");
var _productManage = _interopRequireDefault(require("./components/product-manage.vue"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    ProductManage: _productManage.default
  },
  data: function data() {
    return {
      tableData: [],
      totalPage: 1,
      currentPage: 1,
      perPage: 15,
      appId: '',
      // 应用app_id
      status: '',
      // 应用状态 0 无效 1有效 2备用
      flag: '',
      // 分组标签
      programAlias: '',
      // 别称
      type: '',
      // 应用类型 1：公众号 2小程序
      manageType: '',
      // 弹窗操作类型 add-新增 modify-修改
      chooseData: {},
      typeOptions: [{
        value: 1,
        label: '公众号'
      }, {
        value: 2,
        label: '小程序'
      }],
      statusOptions: [{
        value: 0,
        label: '无效'
      }, {
        value: 1,
        label: '有效'
      }, {
        value: 2,
        label: '备用'
      }]
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      var params = {
        page: this.currentPage,
        per_page: this.perPage
      };
      if (this.appId) {
        params.app_id = this.appId;
      }
      if (this.status !== '') {
        params.status = this.status;
      }
      if (this.flag) {
        params.flag = this.flag;
      }
      if (this.programAlias) {
        params.program_alias = this.programAlias;
      }
      if (this.type) {
        params.type = this.type;
      }
      (0, _wechatapp.getWechatAppLists)(params).then(function (_ref) {
        var code = _ref.code,
          data = _ref.data;
        if (code === 200) {
          var list = data.list,
            meta = data.meta;
          _this.tableData = Object.freeze(list);
          _this.totalPage = meta.page.total_page;
        }
      });
    },
    /**
     * 分页请求
     */
    handleCurrentChange: function handleCurrentChange(page) {
      this.currentPage = page;
      this.getList();
    },
    /**
     * 状态值格式化
     */
    statusFormatter: function statusFormatter(row) {
      return this.statusOptions[row.status].label;
    },
    /**
     * 类型值格式化
     */
    typeFormatter: function typeFormatter(row) {
      return this.typeOptions[row.type - 1].label;
    },
    tableRowClassName: function tableRowClassName(_ref2) {
      var row = _ref2.row;
      if (row.status == 0) {
        return 'warning-row';
      } else if (row.status == 1) {
        return 'success-row';
      }
      return '';
    },
    onModify: function onModify(val) {
      this.chooseData = _objectSpread({}, val);
      this.manageType = 'modify';
      this.$refs.managePop.dialogVisible = true;
    },
    onAdd: function onAdd() {
      this.chooseData = {}; // 清空数据
      this.manageType = 'add';
      this.$refs.managePop.dialogVisible = true;
    },
    onSearch: function onSearch() {
      // 搜索重置
      this.totalPage = 1;
      this.currentPage = 1;
      this.getList();
    }
  }
};