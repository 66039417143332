"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    propForm: {
      type: Object,
      default: function _default() {
        return {
          style_id: 'banner'
        };
      }
    },
    update: {
      // 数据更新
      type: Function,
      default: function _default() {
        return function () {};
      }
    },
    labelWidth: {
      // 标题宽度
      type: String,
      default: function _default() {
        return '55px';
      }
    },
    labelPosition: {
      // 标题位置
      type: String,
      default: function _default() {
        return 'right';
      }
    }
  },
  watch: {
    propForm: {
      handler: function handler() {
        this.update();
      },
      deep: true
    }
  },
  data: function data() {
    return {};
  },
  methods: {}
};