var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "el-form",
        {
          attrs: {
            model: _vm.propForm,
            "label-width": _vm.labelWidth,
            "label-position": _vm.labelPosition,
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                "label-position": "top",
                label: "类型",
                prop: "style_id",
              },
            },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.propForm.style_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.propForm, "style_id", $$v)
                    },
                    expression: "propForm.style_id",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "banner" } }, [
                    _vm._v("banner位"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: "icon" } }, [
                    _vm._v("icon位"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }