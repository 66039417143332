"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.object.freeze");
require("core-js/modules/es6.regexp.split");
var _yunying = require("@/api/yunying");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  components: {},
  props: {
    configLists: {
      type: Object,
      default: function _default() {
        return {
          config_data: []
        };
      }
    },
    update: {
      // 数据更新
      type: Function,
      default: function _default() {
        return function () {};
      }
    }
  },
  data: function data() {
    return {
      index: 0,
      wxgroup: [],
      teacher: [],
      value: '',
      uploadImg: {
        img_url: ''
      },
      rules: {
        goods_group_id: [{
          required: true,
          message: '请选择分组',
          trigger: 'blur'
        }]
      },
      groups: []
    };
  },
  mounted: function mounted() {
    this.getGroup();
  },
  computed: {
    selectGroups: function selectGroups() {
      var data = this.configLists.config_data[0].goods_group_id;
      console.log(data);
      if (typeof data === 'string') {
        return data.split(',').map(function (ele) {
          return parseInt(ele);
        });
      }
      return [];
    }
  },
  methods: {
    getGroup: function getGroup() {
      var _this = this;
      var params = {
        page: 1,
        per_page: 100
      };
      (0, _yunying.getGoodsGroup)(params).then(function (_ref) {
        var data = _ref.data,
          code = _ref.code;
        if (code === 200) {
          _this.groups = Object.freeze(data.list);
        }
      });
    },
    deleteHandle: function deleteHandle(index) {
      var _this2 = this;
      this.$confirm('即将删除该项, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this2.$store.commit('components/DELETE_COMPONENT_CONFIG_ITEM', index);
        _this2.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(function () {});
    },
    validator: function validator() {
      return this.$refs.formRules.validate();
    },
    groupsChange: function groupsChange(val) {
      this.$set(this.configLists.config_data[0], 'goods_group_id', val.join(','));
    }
  }
};