"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _lodash = _interopRequireDefault(require("lodash"));
var _pattern = require("@/api/pattern");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    manageType: {
      type: String,
      default: ''
    },
    ruleForm: {
      type: Object,
      default: function _default() {
        return {
          scene_id: '',
          status: '',
          product_id: '',
          id: '',
          expired_at: '',
          created_at: '',
          channel_id: ''
        };
      }
    },
    statusList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      listData: []
    };
  },
  watch: {
    ruleForm: function ruleForm(value) {
      this.listData = _lodash.default.cloneDeep(value);
    }
  },
  methods: {
    onCancel: function onCancel() {
      this.dialogVisible = false;
    },
    submitForm: function submitForm(formName) {
      var _this = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this.handleEvent();
        } else {
          return false;
        }
      });
    },
    handleEvent: function handleEvent() {
      var _this2 = this;
      var id = this.ruleForm.id;
      (0, _pattern.editScene)(id, this.ruleForm).then(function (_ref) {
        var code = _ref.code;
        if (code === 200) {
          _this2.$message({
            message: '修改成功',
            type: 'success'
          });
          _this2.dialogVisible = false;
          _this2.$parent.getList(); // 更新父组件数据
        }
      }).catch();
    }
  }
};