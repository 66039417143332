"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  name: 'channel_item',
  props: {
    propForm: {
      type: Object,
      default: function _default() {
        return {
          channel_item: []
        };
      }
    },
    update: {
      // 数据更新
      type: Function,
      default: function _default() {
        return function () {};
      }
    },
    labelWidth: {
      // 标题宽度
      type: String,
      default: function _default() {
        return '55px';
      }
    },
    labelPosition: {
      // 标题位置
      type: String,
      default: function _default() {
        return 'left';
      }
    },
    openRules: {
      // 是否开启规则验证
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    placeholder: {
      default: function _default() {
        return '请输入渠道（多项条件组合，后面项优先级高于前面项；不选渠道和渠道组代表默认全渠道）';
      }
    }
  },
  watch: {
    propForm: {
      handler: function handler() {
        this.update();
      },
      deep: true
    }
  },
  data: function data() {
    return {
      rules: {
        channels: {
          type: 'array',
          required: true,
          message: '请输入渠道',
          trigger: 'blur'
        }
      }
    };
  },
  methods: {
    remoteMethod: function remoteMethod() {}
  }
};