var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "role-container" },
    [
      _c(
        "el-button",
        {
          staticClass: "add-btn",
          staticStyle: { "margin-bottom": "20px" },
          attrs: { type: "primary", icon: "el-icon-plus" },
          on: {
            click: function ($event) {
              return _vm.editRow()
            },
          },
        },
        [_vm._v("新增角色\n    ")]
      ),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "table-wrap" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: {
                data: _vm.tableData,
                "element-loading-text": "Loading",
                border: "",
                fit: "",
                "highlight-current-row": "",
                size: "small",
              },
            },
            [
              _c("el-table-column", {
                attrs: { align: "center", label: "ID", width: "50" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(_vm._s(scope.row.id))]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "标识",
                  "header-align": "center",
                  align: "center",
                  "min-width": "80",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(_vm._s(scope.row.flag || "-"))]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "名称",
                  "header-align": "center",
                  align: "center",
                  "min-width": "80",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(_vm._s(scope.row.role || "-"))]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "创建时间",
                  "header-align": "center",
                  align: "center",
                  "min-width": "80",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(_vm._s(scope.row.created_at || "-"))]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "更新时间",
                  "header-align": "center",
                  align: "center",
                  "min-width": "80",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(_vm._s(scope.row.updated_at || "-"))]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "权限",
                  "header-align": "center",
                  align: "center",
                  "min-width": "250",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.permission && scope.row.permission.length > 0
                          ? _c(
                              "div",
                              { staticClass: "permission-list" },
                              _vm._l(
                                scope.row.permission,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "permission-item",
                                    },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(item.desc) +
                                          "\n                        "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            )
                          : _c("div", [_vm._v("-")]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "header-align": "center",
                  align: "center",
                  "min-width": "80",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.id == 30
                          ? _c("div", [_vm._v("不可操作")])
                          : _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "text-btn",
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.editRow(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            编辑\n                        "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "text-btn",
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.delRow(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            删除\n                        "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }