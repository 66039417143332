var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "page-module" }, [
    _c("img", {
      staticClass: "header",
      attrs: {
        src: "https://img-fe.tengzhihh.com/image/76921736ed7da3-750x128.png",
        alt: "",
      },
    }),
    _vm._v(" "),
    _c("div", { staticClass: "app-mian-drag" }, [
      _vm.cur_select_module && _vm.cur_select_module.length > 0
        ? _c(
            "div",
            { staticClass: "grid" },
            _vm._l(_vm.cur_select_module, function (v, k) {
              return _c(
                "div",
                {
                  key: k,
                  staticClass: "grid-item",
                  on: {
                    click: function ($event) {
                      return _vm.selectModule(v, k)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "item" },
                    [
                      _c(
                        _vm.moduleList.filter(function (item, index) {
                          return item.component_id === v.component_id
                        })[0].module,
                        {
                          tag: "component",
                          attrs: {
                            id: _vm.moduleList.filter(function (item, index) {
                              return item.component_id === v.component_id
                            })[0].component_id,
                            keyId: k,
                          },
                        }
                      ),
                      _vm._v(" "),
                      _vm.cur_select_module_index == k
                        ? _c(
                            "div",
                            { staticClass: "operate-move" },
                            [
                              _c(
                                "el-popover",
                                {
                                  attrs: { placement: "left", width: "210" },
                                  model: {
                                    value: _vm.visible,
                                    callback: function ($$v) {
                                      _vm.visible = $$v
                                    },
                                    expression: "visible",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "pop-title" }, [
                                    _vm._v("确定删除吗？"),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "text-align": "right",
                                        margin: "0",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { size: "mini", type: "text" },
                                          on: {
                                            click: function ($event) {
                                              _vm.visible = false
                                            },
                                          },
                                        },
                                        [_vm._v("取消")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            size: "mini",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.remove(v, k)
                                            },
                                          },
                                        },
                                        [_vm._v("确定")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("el-button", {
                                    staticClass: "remove-icon",
                                    attrs: {
                                      slot: "reference",
                                      plain: "",
                                      icon: "el-icon-delete",
                                      circle: "",
                                    },
                                    slot: "reference",
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.cur_select_module_index == k
                        ? _c("div", { staticClass: "active-item" })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              )
            }),
            0
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticStyle: { height: "50px" } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }