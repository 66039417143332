var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "el-form",
        {
          attrs: {
            model: _vm.propForm,
            rules: _vm.openRules ? _vm.rules : {},
            "label-width": _vm.labelWidth,
            "label-position": _vm.labelPosition,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "正则渠道组", prop: "reg_channel_group" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "width",
                  attrs: {
                    multiple: "",
                    placeholder: _vm.placeholder,
                    filterable: "",
                    loading: _vm.loading,
                  },
                  model: {
                    value: _vm.propForm.reg_channel_group,
                    callback: function ($$v) {
                      _vm.$set(_vm.propForm, "reg_channel_group", $$v)
                    },
                    expression: "propForm.reg_channel_group",
                  },
                },
                _vm._l(_vm.reg_channels, function (v, k) {
                  return _c("el-option", {
                    key: k,
                    attrs: {
                      label:
                        "【" +
                        v.name +
                        "】" +
                        (v.reg && "（") +
                        v.reg.substring(0, 20) +
                        (v.reg.length > 19 ? "..." : "") +
                        (v.reg && "）"),
                      value: v.id,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }