var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.manageType === "add" ? "新增标签" : "修改标签",
            visible: _vm.dialogVisible,
            width: "60%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-position": "left",
                "label-width": "70px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "标题", prop: "title" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入标题" },
                    model: {
                      value: _vm.ruleForm.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "title", $$v)
                      },
                      expression: "ruleForm.title",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "名称", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入名称",
                      disabled: _vm.manageType === "add" ? false : true,
                    },
                    model: {
                      value: _vm.ruleForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "name", $$v)
                      },
                      expression: "ruleForm.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "remark" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", placeholder: "请输入备注" },
                    model: {
                      value: _vm.ruleForm.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "remark", $$v)
                      },
                      expression: "ruleForm.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.onCancel } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("ruleForm")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }