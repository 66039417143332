var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.manageType == "add" ? "新增导粉组" : "修改导粉组",
            visible: _vm.dialogVisible,
            width: "65%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "tips" }, [
            _vm._v("\n            - 关于"),
            _c("span", { staticStyle: { color: "#ff0707" } }, [_vm._v("权重")]),
            _vm._v(
              "说明：权重用于选择了导粉号时，控制每个导粉号出现概率，权重总值为100。不填默认平均权重。导粉号数量为1时，权重默认为100。\n            "
            ),
            _c("div", [
              _vm._v(
                "- 权重填写框与导粉号顺序对应，每次变更导粉号，权重需全部重填"
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "section",
            { staticClass: "lists" },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-position": "left",
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "标识", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "投放-测试1" },
                        model: {
                          value: _vm.ruleForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "name", $$v)
                          },
                          expression: "ruleForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "导粉号", prop: "wechatId" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            multiple: "",
                            placeholder:
                              "请选择导粉号(可多选，根据权重分发出现概率)",
                          },
                          on: {
                            change: function (val) {
                              return _vm.componentSelect("wechat", val)
                            },
                          },
                          model: {
                            value: _vm.ruleForm.wechatId,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "wechatId", $$v)
                            },
                            expression: "ruleForm.wechatId",
                          },
                        },
                        _vm._l(_vm.wechats, function (v, k) {
                          return _c("el-option", {
                            key: k,
                            attrs: {
                              label:
                                v.name +
                                ": " +
                                v.wechat +
                                "（" +
                                _vm.FANS_TYPES_MAP.get(v.type) +
                                "）",
                              value: v.id,
                              disabled: v.status === 1 ? false : true,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.ruleForm.wechatId.length > 1
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "导粉权重", prop: "percent" } },
                        _vm._l(_vm.ruleForm.percent, function (item, index) {
                          return _c("el-input", {
                            key: index,
                            staticClass: "width-50",
                            staticStyle: { "margin-right": "10px" },
                            attrs: { maxlength: "2" },
                            model: {
                              value: _vm.ruleForm.percent[index],
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm.percent, index, $$v)
                              },
                              expression: "ruleForm.percent[index]",
                            },
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.onCancel } }, [
                _vm._v("取消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("ruleForm")
                    },
                  },
                },
                [_vm._v("确定并同步")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }