var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ComConfig", {
    attrs: {
      title: "轮播图",
      component_name: "1-2-banner-swiper",
      initConfigNum: 3,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }