var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "module-config" }, [
    _c("header", [_vm._v("组件配置")]),
    _vm._v(" "),
    _vm.cur_select_module.length > 0
      ? _c(
          "div",
          [
            _c(_vm.moduleList[_vm.cur_select_module_index].module, {
              ref: "configRef",
              tag: "component",
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }