var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "el-form",
        { attrs: { inline: true } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "渠道号" } },
            [
              _c("el-input", {
                staticClass: "width-250",
                attrs: { placeholder: "请输入渠道", clearable: "" },
                model: {
                  value: _vm.channel,
                  callback: function ($$v) {
                    _vm.channel = $$v
                  },
                  expression: "channel",
                },
              }),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  staticClass: "width-150",
                  attrs: { placeholder: "请选择渠道匹配模式" },
                  model: {
                    value: _vm.channelMode,
                    callback: function ($$v) {
                      _vm.channelMode = $$v
                    },
                    expression: "channelMode",
                  },
                },
                _vm._l(_vm.channelModeOptions, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "状态" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "width-150",
                  attrs: { placeholder: "请选择上报状态" },
                  model: {
                    value: _vm.reportStatus,
                    callback: function ($$v) {
                      _vm.reportStatus = $$v
                    },
                    expression: "reportStatus",
                  },
                },
                _vm._l(_vm.reportStatusOptions, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "日期" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "datetimerange",
                  "picker-options": _vm.pickerOptions,
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  align: "right",
                },
                model: {
                  value: _vm.timeRange,
                  callback: function ($$v) {
                    _vm.timeRange = $$v
                  },
                  expression: "timeRange",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-search", plain: "" },
                  on: { click: _vm.onSearch },
                },
                [_vm._v("\n                查询\n            ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmReport },
                },
                [_vm._v("确认上报")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData, border: "" },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "selection",
              width: "55",
              selectable: _vm.handleSelectable,
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "id", label: "ID", width: "120", align: "center" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "created_at",
              label: "时间",
              width: "240",
              align: "center",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "channel",
              label: "渠道",
              width: "180",
              align: "center",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "order_id",
              label: "订单号",
              width: "240",
              align: "center",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "ad_type",
              label: "投放类型",
              width: "240",
              align: "center",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "event_type",
              label: "上报类型",
              width: "120",
              align: "center",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "status", label: "是否上报", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type: scope.row.status === 1 ? "success" : "primary",
                          "disable-transitions": "",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(scope.row.status === 1 ? "已上报" : "未上报")
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.totalPage > 1
        ? _c(
            "section",
            { staticClass: "pagination-wrap" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.currentPage,
                  "page-size": _vm.perPage,
                  layout: "prev, pager, next, jumper",
                  "page-count": _vm.totalPage,
                },
                on: {
                  "current-change": _vm.handleCurrentChange,
                  "update:currentPage": function ($event) {
                    _vm.currentPage = $event
                  },
                  "update:current-page": function ($event) {
                    _vm.currentPage = $event
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }