var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "el-form",
        {
          ref: "RuleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-position": "right",
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "主渠道范围", prop: "channels" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    multiple: "",
                    clearable: false,
                    filterable: "",
                    placeholder: "请选择渠道",
                  },
                  on: { change: _vm.changeSelect },
                  model: {
                    value: _vm.ruleForm.channels,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "channels", $$v)
                    },
                    expression: "ruleForm.channels",
                  },
                },
                _vm._l(_vm.optionsList, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: {
                      label:
                        item.name +
                        "(" +
                        _vm.attribute(item.channel_attribute) +
                        "-" +
                        _vm.type(item.channel_type) +
                        (item.carrier_attribute
                          ? "-" + item.carrier_attribute
                          : "") +
                        ")",
                      value: item.channel + "," + item.name,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "子渠道展示" } },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.sub_channel },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "mainname", label: "主渠道", width: "180" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "子渠道" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-checkbox-group",
                              {
                                model: {
                                  value: scope.row.nodes,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "nodes", $$v)
                                  },
                                  expression: "scope.row.nodes",
                                },
                              },
                              _vm._l(
                                scope.row.sub_channel_list,
                                function (v, k) {
                                  return _c(
                                    "el-checkbox",
                                    {
                                      key: k,
                                      attrs: { label: v.show_channel },
                                      on: {
                                        change: function ($event) {
                                          return _vm.changeCheckbox(
                                            scope.row,
                                            scope.row.nodes
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(v.sub_name) +
                                          "\n                            "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              1
                            ),
                            _vm._v(" "),
                            scope.row.page.total_page > 1
                              ? _c(
                                  "section",
                                  { staticClass: "pagination-wrap" },
                                  [
                                    _c("el-pagination", {
                                      attrs: {
                                        "current-page": scope.row.page.current,
                                        "page-size": scope.row.page.per_page,
                                        layout: "prev, pager, next, jumper",
                                        "page-count": scope.row.page.total_page,
                                      },
                                      on: {
                                        "update:currentPage": function (
                                          $event
                                        ) {
                                          return _vm.$set(
                                            scope.row.page,
                                            "current",
                                            $event
                                          )
                                        },
                                        "update:current-page": function (
                                          $event
                                        ) {
                                          return _vm.$set(
                                            scope.row.page,
                                            "current",
                                            $event
                                          )
                                        },
                                        "current-change": function ($event) {
                                          return _vm.handleCurrentChange(
                                            $event,
                                            scope.row,
                                            scope.$index
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }