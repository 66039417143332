"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      activeName: 'backendTag',
      navShow: true
    };
  },
  mounted: function mounted() {
    var name = this.$route.name;
    var hideRouteName = ['backendTag', 'tfBackendTag', 'qmBackendTag'];
    this.activeName = name;
    if (hideRouteName.indexOf(name) < 0) {
      this.navShow = false;
    }
  },
  methods: {
    handleClick: function handleClick(_ref) {
      var name = _ref.name;
      this.$router.push({
        name: name
      });
    }
  }
};