var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "role-container" },
    [
      _c(
        "el-button",
        {
          staticClass: "add-btn",
          staticStyle: { "margin-bottom": "20px" },
          attrs: { type: "primary", icon: "el-icon-plus" },
          on: {
            click: function ($event) {
              return _vm.addRow()
            },
          },
        },
        [_vm._v("\n        新增权限\n    ")]
      ),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "table-wrap" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: {
                data: _vm.tableData,
                "element-loading-text": "Loading",
                border: "",
                fit: "",
                "highlight-current-row": "",
                size: "small",
              },
            },
            [
              _c("el-table-column", {
                attrs: { align: "center", label: "ID", width: "50" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(_vm._s(scope.row.id))]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "名称",
                  "header-align": "center",
                  align: "center",
                  "min-width": "120",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(_vm._s(scope.row.desc || "-"))]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "创建时间",
                  "header-align": "center",
                  align: "center",
                  "min-width": "65",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(_vm._s(scope.row.created_at || "-"))]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "更新时间",
                  "header-align": "center",
                  align: "center",
                  "min-width": "65",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(_vm._s(scope.row.updated_at || "-"))]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "方式",
                  "header-align": "center",
                  align: "center",
                  "min-width": "100",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          _vm._s(
                            scope.row.method == "" ? "ANY" : scope.row.method
                          )
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "前端路由",
                  "header-align": "center",
                  align: "center",
                  "min-width": "150",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(_vm._s(scope.row.front_route))]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "HTTP路径",
                  "header-align": "center",
                  align: "center",
                  "min-width": "150",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(_vm._s(scope.row.route))]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "header-align": "center",
                  align: "center",
                  "min-width": "80",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.editflag == 1 && scope.row.id != 60
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "text-btn",
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.editRow(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            编辑\n                        "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "text-btn",
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.delRow(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            删除\n                        "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _c("div", [
                              _vm._v("不可操作"),
                              _c("br"),
                              _vm._v("仅限开发者编辑"),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("permitEdit", {
        ref: "permitManage",
        attrs: { manageType: _vm.manageType, formData: _vm.chooseData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }