"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/var/www/html/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _vuex = require("vuex");
var _yunying = require("@/api/yunying");
var _imageUpload = _interopRequireDefault(require("@/components/image-upload"));
var _datePicker = _interopRequireDefault(require("@/components/date-picker"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  components: {
    ImageUpload: _imageUpload.default,
    DatePicker: _datePicker.default
  },
  props: {
    propForm: {
      type: Object,
      default: function _default() {
        return {
          img_url: ''
        };
      }
    },
    update: {
      // 数据更新
      type: Function,
      default: function _default() {
        return function () {};
      }
    },
    uploadIcon: {
      //是否展示上传图标
      type: Boolean,
      default: true
    },
    uploaded: {
      //上传成功回调
      type: Function,
      default: function _default(imgUrl) {
        this.propForm.img_url = imgUrl;
      }
    }
  },
  watch: {
    propForm: {
      handler: function handler() {
        this.update();
      },
      deep: true
    },
    filterText: function filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  data: function data() {
    return {
      activeName: 'first',
      // 类型 选择图片，上传图片
      dialogVisible: false,
      loading: true,
      activeGroup: 0,
      //选中的图片分组 （0 默认全部）
      activePicId: '',
      //选中的图片id
      activeItem: '',
      //选中的图片相关信息
      filterText: '',
      // 分组类目搜索输入
      // 参数重置
      defaultProps: {
        children: 'children',
        label: 'group_name',
        id: 'id'
      },
      tablePage: {
        per_page: 300,
        current: 1,
        total_page: 1
      },
      imgList: [],
      // 图片列表数据
      group_id: 0,
      // 图片分组id, 全部0 （获取图片库资源）
      img_url: '',
      // 图片上传 - 图片链接 (图片上传组件获取)
      imgGroupListData: []
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapState)({
    img_group_list: function img_group_list(state) {
      return state.common.img_group_list;
    }
  })),
  mounted: function mounted() {
    this.initGroup();
  },
  methods: {
    // 获取图片分组列表
    initGroup: function initGroup() {
      this.$store.dispatch('common/getImgGroupList');
    },
    // 打开弹窗 才请求数据，避免接口重复多次
    openDialog: function openDialog() {
      var _this = this;
      this.dialogVisible = true;
      // 图片上传组件也有图片分类请求，因此先加个延时
      setTimeout(function () {
        _this.$store.dispatch('common/getImgGroupList');
      }, 1000);
      this.getImgGroupDetail();
    },
    // 日期筛选
    getDateTime: function getDateTime(valueTime) {
      this.getImgGroupDetail('', valueTime);
    },
    // 获取图片列表 （默认先获取全部）
    getImgGroupDetail: function getImgGroupDetail(groupId, valueTime) {
      var _this2 = this;
      this.loading = true;
      var tablePage = this.tablePage;
      var params = {
        per_page: tablePage.per_page,
        page: tablePage.current,
        group_id: groupId || this.group_id
      };
      if (valueTime) {
        params.start_time = valueTime[0];
        params.end_time = valueTime[1];
      }
      (0, _yunying.imageList)(params).then(function (_ref) {
        var data = _ref.data;
        _this2.loading = false;
        _this2.imgList = Object.assign(data.list);
        _this2.tablePage = {
          per_page: data.per_page,
          current: data.current_page,
          total_page: data.total_page,
          total_num: data.total_num
        };
      }).catch(function (err) {
        _this2.loading = false;
      });
    },
    // 分页
    handleCurrentChange: function handleCurrentChange(page) {
      this.tablePage.current = page;
      this.getImgGroupDetail();
    },
    // 类目搜索
    filterNode: function filterNode(value, data) {
      if (!value) return true;
      return data.group_name.indexOf(value) !== -1;
    },
    // 分组类目点击
    handleNodeClick: function handleNodeClick(data) {
      var id = data.id;
      this.getImgGroupDetail(id);
    },
    // 选择图片分组
    selectPicGroup: function selectPicGroup(k, id) {
      this.activeGroup = k;
      this.getImgGroupDetail(id);
    },
    // 选择单项图片
    selectPicItem: function selectPicItem(val) {
      var id = val.id;
      this.activePicId = id;
      this.activeItem = val;
    },
    // 图片库选择确定
    onSureSelectPic: function onSureSelectPic() {
      var activeItem = this.activeItem;
      if (!activeItem && !activeItem.access_url) {
        this.$message.error('请选择图片');
        return;
      }
      // 展示
      // this.propForm.img_url = activeItem.access_url;
      this.uploaded(activeItem.access_url);
      this.dialogVisible = false;
    },
    // 图片上传组件数据获取
    getImage: function getImage(params) {
      this.img_url = params.img_url;
    },
    // 确定图片上传
    onSureUploadImage: function onSureUploadImage() {
      var _this3 = this;
      var uploadflag = this.$refs.imageUpload.validateData(); // 图片上传组件验证
      if (!uploadflag) {
        return;
      }
      // 调用图片上传的保存图片到图片库方法
      this.$refs.imageUpload.savePicToGallery();
      // 数据展示
      setTimeout(function () {
        // this.propForm.img_url = this.img_url;
        _this3.uploaded(_this3.img_url);
        _this3.dialogVisible = false;
        //清空上传组件数据
        _this3.$refs.imageUpload.resetForm();
      }, 100);
    },
    // 取消
    onCancel: function onCancel() {
      this.dialogVisible = false;
      this.propForm.img_url = '';
      this.$refs.imageUpload.resetForm();
    }
  }
};