var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("p", { staticClass: "remark" }, [
        _vm._v("\n        【备注】：\n        "),
        _c("span", [_vm._v(_vm._s(_vm.tagData.remark))]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "menu-area" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-plus" },
              on: { click: _vm.onAdd },
            },
            [_vm._v("新增")]
          ),
          _vm._v(" "),
          _c(
            "el-input",
            {
              staticClass: "width-250",
              attrs: { placeholder: "产品id搜索", clearable: "" },
              model: {
                value: _vm.productId,
                callback: function ($$v) {
                  _vm.productId = $$v
                },
                expression: "productId",
              },
            },
            [
              _c("el-button", {
                attrs: { slot: "append", icon: "el-icon-search" },
                on: { click: _vm.onSearch },
                slot: "append",
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-input",
            {
              staticClass: "width-250",
              attrs: { placeholder: "渠道搜索", clearable: "" },
              model: {
                value: _vm.channel,
                callback: function ($$v) {
                  _vm.channel = $$v
                },
                expression: "channel",
              },
            },
            [
              _c("el-button", {
                attrs: { slot: "append", icon: "el-icon-search" },
                on: { click: _vm.onSearch },
                slot: "append",
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData, border: "" },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "id",
              label: "ID",
              "min-width": "80",
              align: "center",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "channel_id",
              label: "渠道",
              "min-width": "180",
              align: "center",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "mode",
              label: "渠道匹配模式",
              width: "120",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.mode === "fuzzy"
                      ? _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v("模糊匹配"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.mode === "full"
                      ? _c("el-tag", { attrs: { type: "danger" } }, [
                          _vm._v("精准匹配"),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "name",
              label: "名称",
              width: "200",
              align: "center",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "product_id",
              label: "产品ID",
              "min-width": "200",
              align: "center",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "product_name",
              label: "产品名称",
              "min-width": "240",
              align: "center",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "value",
              label: "配置值",
              "min-width": "100",
              align: "center",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center", "min-width": "240" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          plain: "",
                          icon: "el-icon-edit",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.onModify(scope.row)
                          },
                        },
                      },
                      [_vm._v("\n                    修改\n                ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.totalPage > 1
        ? _c(
            "section",
            { staticClass: "pagination-wrap" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.currentPage,
                  "page-size": _vm.perPage,
                  layout: "prev, pager, next, jumper",
                  "page-count": _vm.totalPage,
                },
                on: {
                  "current-change": _vm.handleCurrentChange,
                  "update:currentPage": function ($event) {
                    _vm.currentPage = $event
                  },
                  "update:current-page": function ($event) {
                    _vm.currentPage = $event
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("ProductManage", {
        ref: "managePop",
        attrs: { manageType: _vm.manageType, ruleForm: _vm.chooseData },
        on: {
          "update:ruleForm": function ($event) {
            _vm.chooseData = $event
          },
          "update:rule-form": function ($event) {
            _vm.chooseData = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }