var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "el-form",
        {
          ref: "urlRuleForm",
          attrs: {
            model: _vm.url_config,
            "label-width": _vm.labelWidth,
            "label-position": _vm.labelPosition,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.labelTitle, required: "" } },
            [
              _c(
                "div",
                { staticStyle: { "padding-bottom": "6px" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.url_config.url_rule,
                        callback: function ($$v) {
                          _vm.$set(_vm.url_config, "url_rule", $$v)
                        },
                        expression: "url_config.url_rule",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "rule" } }, [
                        _vm._v("域名库+路径拼接（方便统一管理更换）"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "norule" } }, [
                        _vm._v("自定义链接"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.url_config.url_rule === "norule"
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "path_norule",
                            rules: _vm.rules.path_norule,
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "input-with-select",
                            attrs: { placeholder: "请输入链接" },
                            model: {
                              value: _vm.url_config.path_norule,
                              callback: function ($$v) {
                                _vm.$set(_vm.url_config, "path_norule", $$v)
                              },
                              expression: "url_config.path_norule",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 9 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "url_id",
                                rules: _vm.rules.domain,
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "请选择域名",
                                    filterable: "",
                                  },
                                  on: { change: _vm.handleChange },
                                  model: {
                                    value: _vm.url_config.url_id,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.url_config, "url_id", $$v)
                                    },
                                    expression: "url_config.url_id",
                                  },
                                },
                                _vm._l(_vm.domainList, function (v, k) {
                                  return _c("el-option", {
                                    key: k,
                                    attrs: {
                                      label:
                                        "【" + v.name + "】" + v.production_url,
                                      value: v.id,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "path", rules: _vm.rules.path } },
                            [
                              _c("el-input", {
                                staticClass: "input-with-select",
                                attrs: { placeholder: _vm.pathPlaceholder },
                                model: {
                                  value: _vm.url_config.path,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.url_config, "path", $$v)
                                  },
                                  expression: "url_config.path",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 3 } },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { width: "100%" },
                              on: { click: _vm.checkUrl },
                            },
                            [_vm._v("查看验证")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }