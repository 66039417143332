"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.object.freeze");
var _yunying = require("@/api/yunying");
var _validate = require("@/utils/validate");
var _addFlag = _interopRequireDefault(require("./add-flag"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  components: {
    AddFlag: _addFlag.default
  },
  props: {
    manageType: {},
    formData: {
      type: Object,
      default: function _default() {
        return {
          title: '',
          flag: '',
          app_id: '',
          production_url: '',
          sandbox_url: ''
        };
      }
    }
  },
  data: function data() {
    var validateUrl = function validateUrl(rule, value, callback) {
      if (value && !(0, _validate.isCorrectUrl)(value)) {
        callback(new Error('链接格式不正确'));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      loading2: false,
      dialogVisible: false,
      rules: {
        title: {
          required: true,
          message: '请输入页面名称',
          trigger: 'blur'
        },
        flag: {
          required: true,
          message: '请选择页面标识',
          trigger: 'change'
        },
        app_id: {
          required: true,
          message: '请选择h5应用产品',
          trigger: 'change'
        },
        production_url: {
          required: false,
          validator: validateUrl,
          trigger: 'blur'
        },
        sandbox_url: {
          required: false,
          validator: validateUrl,
          trigger: 'blur'
        }
      },
      flagOption: [],
      // 页面标识
      productOption: [] // 产品列表
    };
  },
  mounted: function mounted() {
    this.getH5Applist();
    this.getPageFlag();
  },
  methods: {
    // 获取应用列表
    getH5Applist: function getH5Applist(desc, appid) {
      var _this = this;
      var perPage = 1000;
      if (desc || appid) {
        perPage = 20;
      }
      var params = {
        per_page: perPage,
        current: 1,
        desc: desc || '',
        appid: appid || ''
      };
      (0, _yunying.getH5List)(params).then(function (_ref) {
        var data = _ref.data;
        _this.loading = false;
        _this.loading2 = false;
        var list = Object.freeze(data.list);
        // 先过滤空的appid数据，没有appid暂时无法新建页面
        var newList = [];
        list.map(function (v, k) {
          if (v.appid) {
            newList.push(v);
          }
        });
        _this.productOption = newList;
      });
    },
    // 应用远程搜索
    remoteMethod: function remoteMethod(query) {
      if (query !== '') {
        this.loading2 = true;
        var flag = /^[A-Za-z0-9]+$/.test(query);
        if (flag) {
          this.getH5Applist('', query);
        } else {
          this.getH5Applist(query, '');
        }
      }
    },
    // 获取标识列表
    getPageFlag: function getPageFlag() {
      var _this2 = this;
      // module=page页面配置，module=fans导粉配置
      var name = this.$route.name;
      (0, _yunying.getPageFlag)().then(function (_ref2) {
        var data = _ref2.data;
        _this2.flagOption = data.filter(function (item) {
          return item.module === name;
        });
      });
    },
    // 新增标识
    addFlagHandle: function addFlagHandle(name) {
      var _this3 = this;
      (0, _yunying.addPageFlag)({
        flag: name
      }).then(function (_ref3) {
        var data = _ref3.data;
        if (data) {
          _this3.$message({
            message: '添加成功',
            type: 'success',
            duration: 1000
          });
          _this3.$refs.addFlag.dialogVisible = false;
          // 新增以后更新列表
          _this3.getPageFlag();
        }
      });
    },
    // 新增标识弹窗
    onAddFlagPop: function onAddFlagPop() {
      this.$refs.addFlag.dialogVisible = true;
    },
    // 提交数据
    onSubmit: function onSubmit() {
      var _this4 = this;
      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          var formData = _this4.formData,
            manageType = _this4.manageType;
          if (manageType === 'add') {
            _this4.loading = true;
            _this4.createPageHandle(formData);
          } else {
            _this4.updatePageInfo(formData);
          }
        }
      });
    },
    // 新建页面
    createPageHandle: function createPageHandle(formData) {
      var _this5 = this;
      (0, _yunying.createPage)(formData).then(function (_ref4) {
        var data = _ref4.data;
        _this5.loading = false;
        if (data) {
          _this5.$alert('页面创建成功, 是否立马前往进行资源配置?', '提示', {
            confirmButtonText: '确定',
            callback: function callback(action) {
              _this5.dialogVisible = false;
              if (action == 'confirm') {
                _this5.$emit('upDateList');
                var name = _this5.$route.name;
                _this5.$router.push({
                  name: 'config',
                  query: {
                    page_id: data,
                    flag: formData.flag,
                    modules: name
                  }
                });
              } else {
                _this5.$emit('upDateList');
              }
            }
          });
        }
      }).catch(function (_ref5) {
        var err = _ref5.err;
        _this5.loading = false;
        console.log(err);
      });
    },
    // 修改页面
    updatePageInfo: function updatePageInfo(formData) {
      var _this6 = this;
      var params = {
        page_id: formData.id,
        title: formData.title,
        production_url: formData.production_url,
        sandbox_url: formData.sandbox_url
      };
      (0, _yunying.updatePageInfo)(params).then(function (_ref6) {
        var data = _ref6.data;
        _this6.$message({
          message: '修改成功',
          type: 'success'
        });
        _this6.dialogVisible = false;
        // 更新数据
        _this6.$emit('upDateList');
      });
    }
  }
};