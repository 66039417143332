"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.string.link");
require("core-js/modules/es6.function.name");
var _lodash = _interopRequireDefault(require("lodash"));
var _yunying = require("@/api/yunying");
var _imageUpload = _interopRequireDefault(require("@/components/image-upload"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  components: {
    ImageUpload: _imageUpload.default
  },
  props: {
    manageType: {},
    ruleForm: {
      type: Object,
      default: function _default() {
        return {
          name: '',
          goods_id: '',
          link: '',
          image: '',
          desc: '',
          status: 1,
          tag: '',
          sandbox_link: ''
        };
      }
    },
    imageUploadData: {
      type: Object,
      default: function _default() {
        return {
          img_url: '' // 图片上传 - 图片链接
        };
      }
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      rules: {
        name: {
          required: true,
          message: '请输入商品名',
          trigger: 'blur'
        },
        goods_id: {
          required: true,
          message: '请输入商品id',
          trigger: 'blur'
        },
        link: {
          required: true,
          message: '请输入正式链接',
          trigger: 'blur'
        },
        sandbox_link: {
          required: true,
          message: '请输入测试链接',
          trigger: 'blur'
        },
        desc: {
          required: true,
          message: '请输入商品描述',
          trigger: 'blur'
        },
        status: {
          required: true,
          message: '请选择状态',
          trigger: 'change'
        }
      },
      tags: []
    };
  },
  mounted: function mounted() {
    this.getTags();
  },
  methods: {
    // 获取标签
    getTags: function getTags() {
      var _this = this;
      (0, _yunying.getGoodsProp)({
        mode: 'tag'
      }).then(function (_ref) {
        var data = _ref.data;
        _this.tags = data;
      });
    },
    onCancel: function onCancel() {
      this.dialogVisible = false;
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;
      var imgFlag = this.$refs.imageUpload.validateData(); // 图片上传组件验证
      this.$refs[formName].validate(function (valid) {
        if (valid && imgFlag) {
          _this2.handleEvent();
        } else {
          return false;
        }
      });
    },
    handleEvent: function handleEvent() {
      var _this3 = this;
      var manageType = this.manageType,
        ruleForm = this.ruleForm,
        imageUploadData = this.imageUploadData;
      var params = {
        name: ruleForm.name,
        goods_id: ruleForm.goods_id,
        image: imageUploadData.img_url,
        status: ruleForm.status,
        desc: ruleForm.desc,
        link: ruleForm.link,
        tag: ruleForm.tag,
        sandbox_link: ruleForm.sandbox_link
      };
      if (manageType == 'add') {
        (0, _yunying.addGoods)(params).then(function (data) {
          if (data.code === 200) {
            _this3.$message({
              message: '添加成功',
              type: 'success'
            });
            _this3.dialogVisible = false;
            _this3.$parent.getList(); // 更新父组件数据
          }
        });
      } else {
        (0, _yunying.modifyGoods)(ruleForm.id, params).then(function (data) {
          if (data.code === 200) {
            _this3.$message({
              message: '修改成功',
              type: 'success'
            });
            _this3.dialogVisible = false;
            _this3.$parent.getList(); // 更新父组件数据
          }
        });
      }
    }
  }
};