"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/var/www/html/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.array.fill");
var _lodash = _interopRequireDefault(require("lodash"));
var _yunying = require("@/api/yunying");
var _mutations;
// 配置组件
var _default = exports.default = {
  namespaced: true,
  state: {
    // 数据格式模板 （用于新增，如果新开发组件，在这里新增字段）
    MODULE_JSON: {
      component_id: '',
      //组件id,如：1-1 （唯一）
      component_title: '',
      //组件标题 （唯一）
      component_name: '',
      //组件文件名称，如：1-1-banner （用于动态加载组件路径查找） （唯一）
      // 组件状态
      status: {
        channel_item: [],
        // 单个渠道列表 ['swchannel'] 已停用，暂时保留
        channel_group: [],
        // 中台渠道组（渠道组id） number
        reg_channel_group: [],
        // 正则渠道组（渠道组id） number
        shield_channel: [],
        // 不可见状态渠道
        shield_reg_channel_group: [],
        // 不可见状态渠道组
        // total_show: 'enable', // 显示enable，隐藏disable
        default_visible: 'none' // 全渠道状态 显示enable，隐藏disable，空值none(用于项目，使用空值会继承公共模板的状态
      },
      // 组件配置 （多项）
      component: [{
        channel_item: [],
        channel_group: [],
        reg_channel_group: [],
        schannel: '',
        content_type: 'url_config',
        // 普通格式：rul_config，图文列表：img_text
        // 组件内容配置模块，如果有新增字段，请在下方添加
        config_data: [{}
        // {
        //     img_url: '', // 图片链接
        //     img_text_id: '', // 图文项id（图文项要用到）Number
        //     title: '', // 标题
        //     url_config: {
        //         url_rule: 'rule', // rule:域名库+路径拼接  norule:自定义链接
        //         url_id: '', // (rule) 域名id （0：自定义） Number
        //         url_name: '', // (rule) 域名 (与url_id配套使用)
        //         path: '', // (rule) 路径
        //         path_norule: '' // (norule) 自定义链接
        //     },
        //     desc: '', //描述内容
        //     people_num: '', //测评人数
        //     comment_num: '', //评价人数
        //     style_id: 'banner', //组件有多样式时的使用 （设置默认值为'banner',其他'icon',图文组件2使用）
        //     extend: '' // 自定义扩展内容，格式不限制
        // }
        ]
      }]
    },
    cur_select_module_index: 0,
    //当前选中的组件
    // 多渠道内容切换id
    active_content_index: 0,
    //当前组件选中的配置项，虚拟id(即使删除,id依然递增，例如：删除当前index为4的项，下一项从5开始)
    active_content_key: 0,
    //当前组件选中的配置项，实际长度id (每次增删操作都重新排序,与当前内容key值一致，始终从0开始按照顺序排序)
    init_content_length: '',
    // 当前组件选中的配置项初始长度

    /** 接口数据 */
    cur_select_module: [],
    // 组件配置模块数据
    page_init_data: '',
    // 接口返回的完整数据 （页面信息+组件配置信息）
    current_public_module: '',
    // 当前页面公共模板配置数据
    current_public_page_id: '' // 当前页面公共模板配置id
  },
  mutations: (_mutations = {}, (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_mutations, 'ADD_CUR_SELECT_MOUDLE', function ADD_CUR_SELECT_MOUDLE(state, data) {
    // 1、先切换两个id（渠道多内容控制）,因为新增组件的渠道配置项为1（即component的长度），如果当前id为其他值，默认展示会出错
    state.active_content_index = 0;
    state.active_content_key = 0;
    // 2、获取数据模板，用于新增复制
    var MODULE_JSON = state.MODULE_JSON;
    var component_id = data.component_id,
      component_name = data.component_name,
      item_num = data.item_num;
    MODULE_JSON = _lodash.default.cloneDeep(MODULE_JSON);
    // 3、组件配置项默认个数处理 (config_data个数处理)
    var CONFIG_JSON = MODULE_JSON.component[0].config_data[0];
    var config_temp = new Array(item_num || 1).fill(CONFIG_JSON);
    // todo 这里处理导致数据是浅拷贝 ? config_temp = _.cloneDeep(config_temp);
    config_temp = JSON.parse(JSON.stringify(config_temp));
    // 一些组件默认参数的处理
    if (component_name === '3-2-graphic-list-2') {
      // 图文列表2
      config_temp[1].style_id = 'icon';
      config_temp[2].style_id = 'icon';
    }
    MODULE_JSON.component[0].config_data = config_temp;
    // 4、自动填充schannel  (appid + 页面标识 + 组件id)
    var _state$page_init_data = state.page_init_data,
      app_id = _state$page_init_data.app_id,
      flag = _state$page_init_data.flag;
    var schannel = "".concat(app_id, "_").concat(flag, "_").concat(component_id);
    MODULE_JSON.component[0].schannel = schannel;
    // 5、修改组件属性字段：content_type （给后端用,该项组件数据需根据id从源头取值，即图文库，保证数据一改全改）
    if (component_name === '3-1-graphic-list-1') {
      // 图文列表1 - 图文项
      MODULE_JSON.component[0].content_type = 'img_text';
    }
    // 导粉类型
    if (component_name.indexOf('8-') > -1) {
      MODULE_JSON.component[0].content_type = 'fans_config';
    }
    //商城
    if (component_name.indexOf('9-') > -1) {
      MODULE_JSON.component[0].content_type = 'goods_type';
    }
    // 6、新增的数据模板 （原模板+组件id参数+组件配置项）
    var temp = Object.assign(MODULE_JSON, {
      component_id: component_id,
      component_name: component_name
    });
    state.cur_select_module.push(temp);
  }), 'ADD_COPY_CUR_SELECT_MOUDLE', function ADD_COPY_CUR_SELECT_MOUDLE(state, data) {
    var component = data.component;
    // schannel  (appid + 页面标识 + 组件id)
    var _state$page_init_data2 = state.page_init_data,
      app_id = _state$page_init_data2.app_id,
      flag = _state$page_init_data2.flag;
    var schannel = "".concat(app_id, "_").concat(flag, "_").concat(data.component_id);
    component.map(function (v, k) {
      return v.schannel = schannel;
    });
    state.cur_select_module.push(data);
  }), 'REMOVE_CUR_SELECT_MOUDLE', function REMOVE_CUR_SELECT_MOUDLE(state, index) {
    state.cur_select_module.splice(index, 1);
  }), 'SET_SELECT_INDEX', function SET_SELECT_INDEX(state, index) {
    state.cur_select_module_index = index;
  }), 'SET_CONTENT_INDEX', function SET_CONTENT_INDEX(state, data) {
    state.active_content_index = data.active_content_index; // 虚拟id(即使删除,id依然递增)
    state.active_content_key = data.active_content_key; // 真实长度key
  }), 'ADD_CUR_CONTENT', function ADD_CUR_CONTENT(state, data) {
    var copySelect = data.copySelect,
      initConfigNum = data.initConfigNum,
      component_name = data.component_name;
    // 当前操作项数据
    var channelModule = state.cur_select_module[state.cur_select_module_index];
    var channelModuleList = channelModule.component;
    if (copySelect == 0) {
      // 新增空白项
      var MODULE_JSON = state.MODULE_JSON;
      MODULE_JSON = _lodash.default.cloneDeep(MODULE_JSON);
      // 1.要复制的模板
      var temp = MODULE_JSON.component[0];
      temp = Object.assign(temp, {
        index: state.init_content_length
      });
      // 2.模板的默认配置个数设置
      var CONFIG_JSON = MODULE_JSON.component[0].config_data[0];
      var config_temp = new Array(initConfigNum || 1).fill(CONFIG_JSON);
      // config_temp = _.cloneDeep(config_temp);
      config_temp = JSON.parse(JSON.stringify(config_temp));
      // 3.一些组件默认参数的处理
      if (component_name === '3-2-graphic-list-2') {
        // 图文列表2
        config_temp[1].style_id = 'icon';
        config_temp[2].style_id = 'icon';
      }
      // 4.schnanel填充  (appid + 页面标识 + 组件id)
      var _state$page_init_data3 = state.page_init_data,
        app_id = _state$page_init_data3.app_id,
        flag = _state$page_init_data3.flag;
      var schannel = "".concat(app_id, "_").concat(flag, "_").concat(channelModule.component_id);
      temp.schannel = schannel;
      // 5、修改组件属性字段：content_type （给后端用,改项组件数据需根据id从源头取值，即图文库，保证数据一改全改）
      if (component_name === '3-1-graphic-list-1') {
        // 图文列表1 - 图文项
        temp.content_type = 'img_text';
      }
      if (component_name.indexOf('8-') > -1) {
        // 导粉类型
        MODULE_JSON.component[0].content_type = 'fans_config';
      }
      //商城
      if (component_name.indexOf('9-') > -1) {
        MODULE_JSON.component[0].content_type = 'goods_type';
      }
      // 6.push
      temp.config_data = config_temp;
      channelModuleList.push(temp);
    } else {
      // 新增复制项
      var copy_module = channelModuleList[Number(copySelect) - 1];
      copy_module = _lodash.default.cloneDeep(copy_module);
      copy_module = Object.assign(copy_module, {
        index: state.init_content_length
      });
      channelModuleList.push(copy_module);
    }
    state.init_content_length++;

    // 切换当前默认展示项 (长度能确定，通过长度反推active_content_index)
    var id = channelModuleList.length - 1;
    state.active_content_key = id;
    state.active_content_index = channelModuleList[id].index;
  }), 'REMOVE_CUR_CONTENT', function REMOVE_CUR_CONTENT(state, data) {
    var key = data.key,
      index = data.index;
    var channelModuleList = state.cur_select_module[state.cur_select_module_index].component;
    // 删除
    channelModuleList.splice(key, 1);
    // tab处理
    if (index === state.active_content_index) {
      // 如果删除当前选中的tab,默认展示回第一个tab
      state.active_content_key = 0;
      state.active_content_index = channelModuleList[0].index; //找到当前对应的虚拟id
    } else {
      // 非当前tab，active_content_index是固定已知，反推active_content_key
      channelModuleList.map(function (v, k) {
        if (v.index === state.active_content_index) {
          state.active_content_key = k;
        }
      });
    }
  }), 'SET_INIT_CONTENT_LENGTH', function SET_INIT_CONTENT_LENGTH(state, data) {
    state.init_content_length = data;
  }), 'UPDATE_CUR_SELECT_MOUDLE', function UPDATE_CUR_SELECT_MOUDLE(state, data) {
    state.cur_select_module = Object.assign(state.cur_select_module, data);
  }), 'ADD_COMPONENT_CONFIG_ITEM', function ADD_COMPONENT_CONFIG_ITEM(state) {
    var cur_select_module = state.cur_select_module,
      cur_select_module_index = state.cur_select_module_index,
      active_content_key = state.active_content_key,
      MODULE_JSON = state.MODULE_JSON;
    var temp = MODULE_JSON.component[0].config_data[0];
    temp = _lodash.default.cloneDeep(temp);
    cur_select_module[cur_select_module_index].component[active_content_key].config_data.push(temp);
  }), (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_mutations, 'DELETE_COMPONENT_CONFIG_ITEM', function DELETE_COMPONENT_CONFIG_ITEM(state, index) {
    var cur_select_module = state.cur_select_module,
      cur_select_module_index = state.cur_select_module_index,
      active_content_key = state.active_content_key;
    cur_select_module[cur_select_module_index].component[active_content_key].config_data.splice(index, 1);
  }), 'INIT_CUR_SELECT_MOUDLE', function INIT_CUR_SELECT_MOUDLE(state, data) {
    // 断开链路，避免数值无法对比
    state.cur_select_module = _lodash.default.cloneDeep(data.page_component);
    state.page_init_data = data.page_init_data;
  }), 'CURRENT_PUBLIC_MOUDLE', function CURRENT_PUBLIC_MOUDLE(state, data) {
    state.current_public_module = data;
  }), 'CURRENT_PUBLIC_PAGE_ID', function CURRENT_PUBLIC_PAGE_ID(state, data) {
    state.current_public_page_id = data;
  })),
  actions: {
    // 获取页面配置数据
    getPageConfig: function getPageConfig(_ref, params) {
      var commit = _ref.commit,
        state = _ref.state;
      (0, _yunying.getPageConfig)(params).then(function (_ref2) {
        var data = _ref2.data;
        commit('INIT_CUR_SELECT_MOUDLE', {
          page_init_data: data,
          page_component: data.page_component
        });
      }).catch(function (err) {
        console.log(err.data);
      });
    },
    // 获取公共配置页数据
    getDefaultPageConfig: function getDefaultPageConfig(_ref3, params) {
      var commit = _ref3.commit,
        state = _ref3.state;
      var flag = params.flag,
        type = params.type;
      (0, _yunying.getDefaultPageConfig)({
        flag: flag
      }).then(function (_ref4) {
        var data = _ref4.data;
        if (type === 'h5app') {
          // 如果是应用页面请求公共数据，是用于新增判断与对比，不能重置页面数据
          commit('CURRENT_PUBLIC_MOUDLE', data.page_component);
          commit('CURRENT_PUBLIC_PAGE_ID', data.page_id);
        } else {
          commit('INIT_CUR_SELECT_MOUDLE', {
            page_init_data: data,
            page_component: data.page_component
          });
        }
      }).catch(function (err) {
        console.log(err.data);
      });
    }
  }
};