"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _validate = require("@/utils/validate");
var _yunying = require("@/api/yunying");
var _channelSelect = _interopRequireDefault(require("@/components/channel-select"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ['chooseData'],
  components: {
    ChannelSelect: _channelSelect.default
  },
  data: function data() {
    return {
      id: this.$route.query.id,
      // 渠道组id
      editType: this.$route.query.edit_type,
      // 类型，修改，新增、查看
      ruleForm: {
        name: ''
      },
      rules: {
        name: {
          required: true,
          message: '请输入渠道组名称',
          trigger: 'blur'
        }
      },
      tablePage: {
        per_page: 2000,
        current: 1,
        total_page: 1,
        total_num: 1
      },
      detailSubChannel: '' // 渠道详情，子渠道列表
    };
  },
  watch: {
    chooseData: function chooseData(newVal) {
      this.ruleForm = Object.assign({}, newVal);
    }
  },
  mounted: function mounted() {
    this.getChannelInfo();
  },
  methods: {
    // 接收子组件选择的渠道
    getChannelsData: function getChannelsData(channelData) {
      this.channelData = channelData;
    },
    // 提交
    submitForm: function submitForm(formName) {
      var _this = this;
      var channelValidate = this.$refs.ChannelSelect.validateData();
      this.$refs[formName].validate(function (valid) {
        if (valid && channelValidate) {
          var editType = _this.editType;
          if (editType === 'add') {
            _this.addEvent();
          }
        } else {
          _this.$message.error('无法添加！请检查填写项是否符合要求！');
          return false;
        }
      });
    },
    // 新增
    addEvent: function addEvent() {
      var _this2 = this;
      var channelData = this.channelData,
        ruleForm = this.ruleForm,
        data = {
          name: ruleForm.name,
          show_channel: channelData.join(',')
        };
      var loading = this.$loading({
        lock: true,
        text: '提交中...',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)'
      });
      (0, _yunying.addCenterChannels)(data).then(function (res) {
        if (res.code == 200) {
          _this2.$message({
            message: '添加成功',
            type: 'success',
            duration: 1000
          });
          _this2.goBack();
        }
        loading.close();
      }).catch(function (err) {
        loading.close();
      });
    },
    resetForm: function resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    goBack: function goBack() {
      this.$router.push({
        path: 'channelsmanage'
      });
    },
    getChannelInfo: function getChannelInfo() {
      var _this3 = this;
      var id = this.id,
        editType = this.editType;
      if (!id || editType == 'add') return;
      (0, _yunying.centerChannelsInfo)({
        id: id
      }).then(function (_ref) {
        var data = _ref.data;
        var name = data.name,
          channels = data.channels;
        _this3.ruleForm.name = name;
        _this3.detailSubChannel = channels;
      });
    }
  }
};