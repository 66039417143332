var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("section", { staticClass: "search-area" }, [
        _c(
          "div",
          [
            _c(
              "el-select",
              {
                staticClass: "width-200",
                attrs: { placeholder: "业务线" },
                model: {
                  value: _vm.h5LineValue,
                  callback: function ($$v) {
                    _vm.h5LineValue = $$v
                  },
                  expression: "h5LineValue",
                },
              },
              _vm._l(_vm.h5Line, function (v) {
                return _c("el-option", {
                  key: v.label,
                  attrs: { label: v.label, value: v.value },
                })
              }),
              1
            ),
            _vm._v(" "),
            _c(
              "el-input",
              {
                staticClass: "width-250",
                attrs: { placeholder: "请输入应用名称搜索", clearable: "" },
                on: { change: _vm.onSearch },
                model: {
                  value: _vm.searchInput,
                  callback: function ($$v) {
                    _vm.searchInput = $$v
                  },
                  expression: "searchInput",
                },
              },
              [
                _c("el-button", {
                  attrs: { slot: "append", icon: "el-icon-search" },
                  slot: "append",
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-input",
              {
                staticClass: "width-250",
                attrs: { placeholder: "请输入Appid搜索", clearable: "" },
                on: { change: _vm.onSearch },
                model: {
                  value: _vm.searchAppid,
                  callback: function ($$v) {
                    _vm.searchAppid = $$v
                  },
                  expression: "searchAppid",
                },
              },
              [
                _c("el-button", {
                  attrs: { slot: "append", icon: "el-icon-search" },
                  slot: "append",
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.upDateData } },
              [_vm._v("更新应用数据")]
            ),
            _vm._v(" "),
            _c(
              "el-tooltip",
              {
                staticClass: "item",
                attrs: { effect: "dark", placement: "top-end" },
              },
              [
                _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                  _c("div", [
                    _vm._v("h5应用的增删改查，需前往灵机信息化平台的项目记录"),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "\n                        如果在信息化平台更新了数据，需点击旁边的【更新应用数据】按钮，同步最新数据\n                    "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("el-button", [_vm._v("说明")]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            border: "",
            "element-loading-spinner": "el-icon-loading",
            "element-loading-background": "rgba(0, 0, 0, 0.5)",
          },
        },
        [
          _c("el-table-column", {
            attrs: { type: "expand" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-form",
                      {
                        staticClass: "demo-table-expand",
                        attrs: { "label-position": "left" },
                      },
                      [
                        _c("el-form-item", { attrs: { label: "应用名称" } }, [
                          _c("span", [_vm._v(_vm._s(scope.row.desc))]),
                        ]),
                        _vm._v(" "),
                        _c("el-form-item", { attrs: { label: "应用归属" } }, [
                          _c("span", [_vm._v("在线测算")]),
                        ]),
                        _vm._v(" "),
                        _c("el-form-item", { attrs: { label: "APPID" } }, [
                          _c("span", [_vm._v(_vm._s(scope.row.app_id))]),
                        ]),
                        _vm._v(" "),
                        _c("el-form-item", { attrs: { label: "项目id" } }, [
                          _c("span", [_vm._v(_vm._s(scope.row.name))]),
                        ]),
                        _vm._v(" "),
                        _c("el-form-item", { attrs: { label: "线上链接" } }, [
                          _c("span", [_vm._v(_vm._s(scope.row.pro_url))]),
                        ]),
                        _vm._v(" "),
                        _c("el-form-item", { attrs: { label: "测服链接" } }, [
                          _c("span", [_vm._v(_vm._s(scope.row.dev_url))]),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "app_id",
              label: "APPID",
              "min-width": "80",
              "header-align": "center",
              align: "center",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "desc",
              label: "应用名称",
              "min-width": "130",
              "header-align": "center",
              align: "center",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "pro_url",
              label: "线上链接",
              "min-width": "160",
              "header-align": "center",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-link",
                      {
                        attrs: { type: "info" },
                        on: {
                          click: function ($event) {
                            return _vm.checkLink(scope.row.pro_url)
                          },
                        },
                      },
                      [_vm._v(" 查看 ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-link",
                      {
                        directives: [
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:copy",
                            value: scope.row.pro_url,
                            expression: "scope.row.pro_url",
                            arg: "copy",
                          },
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:success",
                            value: _vm.onCopy,
                            expression: "onCopy",
                            arg: "success",
                          },
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:error",
                            value: _vm.onError,
                            expression: "onError",
                            arg: "error",
                          },
                        ],
                        attrs: { type: "info" },
                      },
                      [_vm._v("\n                    复制\n                ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "dev_url",
              label: "测服链接",
              "min-width": "160",
              "header-align": "center",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-link",
                      {
                        attrs: { type: "info" },
                        on: {
                          click: function ($event) {
                            return _vm.checkLink(scope.row.dev_url)
                          },
                        },
                      },
                      [_vm._v(" 查看 ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-link",
                      {
                        directives: [
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:copy",
                            value: scope.row.dev_url,
                            expression: "scope.row.dev_url",
                            arg: "copy",
                          },
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:success",
                            value: _vm.onCopy,
                            expression: "onCopy",
                            arg: "success",
                          },
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:error",
                            value: _vm.onError,
                            expression: "onError",
                            arg: "error",
                          },
                        ],
                        attrs: { type: "info" },
                      },
                      [_vm._v("\n                    复制\n                ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c(
            "el-table-column",
            {
              attrs: {
                prop: "cancel",
                label: "操作",
                "min-width": "100",
                "header-align": "center",
                align: "center",
                fixed: "right",
              },
            },
            [
              _c(
                "el-link",
                {
                  staticClass: "font-10",
                  attrs: { type: "primary" },
                  on: { click: _vm.onAdd },
                },
                [
                  _vm._v(
                    "\n                需前往灵机信息化平台操作\n            "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.tablePage.total_page > 0
        ? _c(
            "section",
            { staticClass: "pagination-wrap" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.tablePage.current,
                  "page-size": _vm.tablePage.per_page,
                  layout: "prev, pager, next, jumper",
                  "page-count": _vm.tablePage.total_page,
                },
                on: {
                  "current-change": _vm.handleCurrentChange,
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.tablePage, "current", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.tablePage, "current", $event)
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }