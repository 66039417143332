var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.manageType == "add" ? "新增老师信息" : "修改老师信息",
            visible: _vm.dialogVisible,
            width: "680px",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-position": "left",
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "模块", prop: "template" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "西式命理" },
                    model: {
                      value: _vm.ruleForm.template,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "template", $$v)
                      },
                      expression: "ruleForm.template",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "名称", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "xx老师" },
                    model: {
                      value: _vm.ruleForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "name", $$v)
                      },
                      expression: "ruleForm.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "multiple",
                  attrs: { label: "头像", prop: "avatar_image" },
                },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "图片链接" },
                    model: {
                      value: _vm.ruleForm.avatar_image,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "avatar_image", $$v)
                      },
                      expression: "ruleForm.avatar_image",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-upload",
                    {
                      attrs: {
                        action: _vm.uploadImgUrl,
                        headers: { Authorization: _vm.token },
                        limit: 1,
                        multiple: false,
                        accept: "image/*",
                        "before-upload": _vm.handleBeforeUpload,
                        "on-exceed": _vm.handleExceed,
                        "on-success": _vm.handleSuccess,
                        "on-error": _vm.handleError,
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.triggerUpload("avatar")
                            },
                          },
                        },
                        [
                          _vm._v("上传"),
                          _c("i", {
                            staticClass: "el-icon-upload el-icon--right",
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "multiple",
                  attrs: { label: "大图", prop: "introduce_image" },
                },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "图片链接" },
                    model: {
                      value: _vm.ruleForm.introduce_image,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "introduce_image", $$v)
                      },
                      expression: "ruleForm.introduce_image",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-upload",
                    {
                      attrs: {
                        action: _vm.uploadImgUrl,
                        headers: { Authorization: _vm.token },
                        limit: 1,
                        multiple: false,
                        accept: "image/*",
                        "before-upload": _vm.handleBeforeUpload,
                        "on-exceed": _vm.handleExceed,
                        "on-success": _vm.handleSuccess,
                        "on-error": _vm.handleError,
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.triggerUpload("introduce")
                            },
                          },
                        },
                        [
                          _vm._v("上传"),
                          _c("i", {
                            staticClass: "el-icon-upload el-icon--right",
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "描述", prop: "tip" } },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      placeholder: "想继续追问，加我微信吧",
                    },
                    model: {
                      value: _vm.ruleForm.tip,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "tip", $$v)
                      },
                      expression: "ruleForm.tip",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "其他", prop: "introduce" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "我在这里等你" },
                    model: {
                      value: _vm.ruleForm.introduce,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "introduce", $$v)
                      },
                      expression: "ruleForm.introduce",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "扩展" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "自定义，格式不限（技术侧使用）" },
                    model: {
                      value: _vm.ruleForm.extend,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "extend", $$v)
                      },
                      expression: "ruleForm.extend",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.onCancel } }, [
                _vm._v("取消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
                [_vm._v("确定并同步")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }