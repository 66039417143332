"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.object.freeze");
var _defineProperty2 = _interopRequireDefault(require("/var/www/html/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/es6.function.name");
var _yunying = require("@/api/yunying");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    ruleForm: {
      type: Object,
      default: function _default() {
        return {
          name: '',
          type: '',
          group: []
        };
      }
    },
    manageType: {},
    types: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      loading: false,
      dialogVisible: false,
      rules: {
        name: {
          required: true,
          message: '请输入名称',
          trigger: 'blur'
        },
        type: {
          required: true,
          message: '请选择类型',
          trigger: 'blur'
        },
        group: {
          required: true,
          message: '请选择商品',
          trigger: 'blur'
        }
      },
      goods: []
    };
  },
  mounted: function mounted() {
    // this.getH5Applist();
    this.getList();
  },
  methods: {
    // 获取导粉组件列表
    getList: function getList() {
      var _this = this;
      var params = {
        per_page: 200,
        page: 1
      };
      (0, _yunying.getGoods)(params).then(function (_ref) {
        var data = _ref.data,
          code = _ref.code;
        if (code === 200) {
          var list = data.list.map(function (item) {
            var label = "".concat(item.name, " \uFF08id\uFF1A").concat(item.goods_id, "\uFF09");
            if (item.tag_name) {
              label = "".concat(item.name, " \uFF08id\uFF1A").concat(item.goods_id, "\uFF0C\u6807\u7B7E\uFF1A").concat(item.tag_name, "\uFF09");
            }
            return _objectSpread({
              label: label
            }, item);
          });
          _this.goods = Object.freeze(list);
        }
      });
    },
    onCancel: function onCancel() {
      this.dialogVisible = false;
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this2.handleEvent();
        } else {
          return false;
        }
      });
    },
    handleEvent: function handleEvent() {
      var _this3 = this;
      var _this$ruleForm = this.ruleForm,
        type = _this$ruleForm.type,
        group = _this$ruleForm.group,
        id = _this$ruleForm.id,
        name = _this$ruleForm.name;
      var data = {
        name: name,
        type: type,
        goods_item_group: group.join(','),
        status: 1
      };
      if (this.manageType === 'add') {
        (0, _yunying.addGoodsGroup)(data).then(function (_ref2) {
          var code = _ref2.code;
          if (code === 200) {
            _this3.$message({
              message: '添加成功',
              type: 'success'
            });
            _this3.dialogVisible = false;
            _this3.$parent.getList(); // 更新父组件数据
          }
        });
      } else {
        (0, _yunying.modifyGoodsGroup)(id, data).then(function () {
          _this3.$message({
            message: '修改成功',
            type: 'success'
          });
          _this3.dialogVisible = false;
          _this3.$parent.getList(); // 更新父组件数据
        });
      }
    }
  }
};