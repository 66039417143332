var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "edit-wrapper" }, [
    _c(
      "div",
      { staticClass: "menu" },
      [
        _c("div", { staticClass: "font-18" }, [
          _vm._v(_vm._s(_vm.id ? "角色修改" : "新增角色")),
        ]),
        _vm._v(" "),
        _c(
          "el-button",
          {
            staticStyle: { "margin-bottom": "10px" },
            on: { click: _vm.cancel },
          },
          [_vm._v("返回")]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "form-wrap" },
      [
        _c(
          "el-form",
          {
            ref: "ruleForm",
            attrs: {
              model: _vm.ruleForm,
              rules: _vm.rules,
              "label-position": "right",
              "label-width": "100px",
            },
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "标识", prop: "flag" } },
              [
                _c("el-input", {
                  attrs: {
                    placeholder: "请输入角色标识（例如：administrator）",
                    clearable: "",
                  },
                  model: {
                    value: _vm.ruleForm.flag,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "flag", $$v)
                    },
                    expression: "ruleForm.flag",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "角色名称", prop: "role" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入角色名称", clearable: "" },
                  model: {
                    value: _vm.ruleForm.role,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "role", $$v)
                    },
                    expression: "ruleForm.role",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "权限分配", prop: "selected" } },
              [
                _vm.permitList.length > 0
                  ? _c(
                      "div",
                      [
                        _c("el-transfer", {
                          staticStyle: {
                            "text-align": "left",
                            display: "inline-block",
                          },
                          attrs: {
                            filterable: "",
                            titles: ["选择权限", "已有权限"],
                            format: {
                              noChecked: "${total}",
                              hasChecked: "${checked}/${total}",
                            },
                            data: _vm.permitList,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var option = ref.option
                                  return _c("span", {}, [
                                    _vm._v(_vm._s(option.label)),
                                  ])
                                },
                              },
                            ],
                            null,
                            false,
                            3395026510
                          ),
                          model: {
                            value: _vm.ruleForm.selected,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "selected", $$v)
                            },
                            expression: "ruleForm.selected",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "span",
      {
        staticClass: "form-buttons",
        attrs: { slot: "footer" },
        slot: "footer",
      },
      [
        _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取 消")]),
        _vm._v(" "),
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.submit } },
          [_vm._v("保存")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }