var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c(
      "div",
      { staticClass: "config-item" },
      [
        _c(
          "el-form",
          {
            ref: "formRules",
            attrs: {
              model: _vm.configLists.config_data[0],
              "label-width": "80px",
              labelPosition: "left",
              rules: _vm.rules,
            },
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "商品组", prop: "goods_group_id" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      value: _vm.selectGroups,
                      filterable: "",
                      placeholder: "请选择",
                      multiple: "",
                    },
                    on: { change: _vm.groupsChange },
                  },
                  _vm._l(_vm.groups, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "扩展" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "自定义，格式不限（技术侧使用）" },
                  model: {
                    value: _vm.configLists.config_data[0].extend,
                    callback: function ($$v) {
                      _vm.$set(_vm.configLists.config_data[0], "extend", $$v)
                    },
                    expression: "configLists.config_data[0].extend",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }