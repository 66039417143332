"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    propForm: {
      type: Object,
      default: function _default() {
        return {
          extend: ''
        };
      }
    },
    update: {
      // 数据更新
      type: Function,
      default: function _default() {
        return function () {};
      }
    },
    labelWidth: {
      // 标题宽度
      type: String,
      default: function _default() {
        return '55px';
      }
    },
    labelTitle: {
      // 标题
      type: String,
      default: function _default() {
        return '扩展';
      }
    },
    placeholder: {
      // 提示内容
      type: String,
      default: function _default() {
        return '自定义，格式不限（技术侧使用）';
      }
    }
  },
  watch: {
    propForm: {
      handler: function handler() {
        this.update();
      },
      deep: true
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    onFocus: function onFocus() {
      this.$emit('closeOrOpenDrag', true);
    },
    onBlur: function onBlur() {
      this.$emit('closeOrOpenDrag', false);
    }
  }
};