var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ComComponent", {
    ref: "comComponent",
    attrs: {
      title: "轮播图",
      id: _vm.id,
      keyId: _vm.keyId,
      tips: "说明：轮播banner的尺寸请保持一致，宽度750px，高度不低于150px。个数不限定",
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          var configData = ref.configData
          return [
            configData.length >= 1
              ? _c(
                  "el-carousel",
                  { attrs: { height: "150px" } },
                  _vm._l(
                    _vm.keyId == undefined ? [{}, {}, {}] : configData,
                    function (v, k) {
                      return _c(
                        "el-carousel-item",
                        { key: k, staticClass: "swiper-banner" },
                        [
                          _c(
                            "el-image",
                            {
                              attrs: { src: v.img_url, fit: "contain" },
                              on: {
                                click: function ($event) {
                                  return _vm.onLocation(v.url_config)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "image-slot",
                                  attrs: { slot: "error" },
                                  slot: "error",
                                },
                                [_c("i", { staticClass: "el-icon-picture" })]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    }
                  ),
                  1
                )
              : _vm._e(),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }