var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ComComponent", {
    ref: "comComponent",
    attrs: {
      title: "导航栏-2",
      id: _vm.id,
      keyId: _vm.keyId,
      tips: "说明：icon建议尺寸为50*50，右边大图尺寸为 225*110。tab个数限定为3个",
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          var configData = ref.configData
          return [
            configData.length >= 1
              ? _c(
                  "div",
                  { staticClass: "nav-bar" },
                  _vm._l(
                    _vm.keyId == undefined ? [{}, {}, {}] : configData,
                    function (v, k) {
                      return _c(
                        "div",
                        {
                          key: k,
                          staticClass: "nav-bar-item",
                          class: "nav-bar-item" + k,
                          on: {
                            click: function ($event) {
                              return _vm.onLocation(v.url_config)
                            },
                          },
                        },
                        [
                          k < 2
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-image",
                                    {
                                      staticClass: "img-icon",
                                      attrs: { src: v.img_url, fit: "contain" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "image-default",
                                          attrs: { slot: "error" },
                                          slot: "error",
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-picture",
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "font-12" }, [
                                    _vm._v(_vm._s(v.title || "标题")),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          k === 2
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-image",
                                    {
                                      staticClass: "img-icon-2",
                                      attrs: { src: v.img_url, fit: "contain" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "image-default",
                                          attrs: { slot: "error" },
                                          slot: "error",
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-picture",
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      )
                    }
                  ),
                  0
                )
              : _vm._e(),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }