var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ComComponent", {
    ref: "comComponent",
    attrs: {
      title: "目录列表-1",
      id: _vm.id,
      keyId: _vm.keyId,
      tips: "说明：icon尺寸为150*150。个数不限定",
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          var configData = ref.configData
          return [
            configData.length >= 1
              ? _c(
                  "div",
                  { staticClass: "item-lists" },
                  _vm._l(
                    _vm.keyId == undefined ? [{}, {}, {}] : configData,
                    function (v, k) {
                      return _c(
                        "div",
                        {
                          key: k,
                          staticClass: "items",
                          on: {
                            click: function ($event) {
                              return _vm.onLocation(v.url_config)
                            },
                          },
                        },
                        [
                          _c(
                            "el-image",
                            {
                              staticClass: "img-box",
                              attrs: { src: v.img_url, fit: "contain" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "image-slot",
                                  attrs: { slot: "error" },
                                  slot: "error",
                                },
                                [_c("i", { staticClass: "el-icon-picture" })]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "text-box" }, [
                            _c("div", { staticClass: "font-16" }, [
                              _vm._v(_vm._s(v.title || "标题")),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "font-14" }, [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    v.desc ||
                                      "内容描述内容描述内容描述内容描述内容描述内容描述"
                                  ) +
                                  "\n                    "
                              ),
                            ]),
                          ]),
                        ],
                        1
                      )
                    }
                  ),
                  0
                )
              : _vm._e(),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }