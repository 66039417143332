var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "channel-wrap" }, [
    _c(
      "div",
      { staticClass: "menu" },
      [
        _c("div", { staticClass: "font-18" }, [
          _vm._v(
            "\n            " +
              _vm._s(
                _vm.editType === "detail" ? "中台渠道组详情" : "中台渠道组新增"
              ) +
              "\n        "
          ),
        ]),
        _vm._v(" "),
        _c(
          "el-button",
          {
            staticStyle: { "margin-bottom": "10px" },
            on: { click: _vm.goBack },
          },
          [_vm._v("返回")]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "lists" },
      [
        _c(
          "el-form",
          {
            ref: "ruleForm",
            attrs: {
              model: _vm.ruleForm,
              rules: _vm.rules,
              "label-position": "right",
              "label-width": "100px",
            },
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "渠道组名称", prop: "name" } },
              [
                _c("el-input", {
                  staticClass: "width-400",
                  attrs: {
                    placeholder: "请输入渠道组名称",
                    clearable: "",
                    maxlength: "20",
                    "show-word-limit": "",
                    disabled: _vm.editType === "detail" ? true : false,
                  },
                  model: {
                    value: _vm.ruleForm.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "name", $$v)
                    },
                    expression: "ruleForm.name",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.editType == "add"
              ? _c("ChannelSelect", {
                  ref: "ChannelSelect",
                  on: { getChannelsData: _vm.getChannelsData },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.editType == "detail"
              ? _c("el-form-item", { attrs: { label: "子渠道列表" } }, [
                  _vm.detailSubChannel
                    ? _c(
                        "div",
                        { staticClass: "detail-channel-list" },
                        _vm._l(_vm.detailSubChannel, function (v, k) {
                          return _c(
                            "div",
                            { key: k, staticClass: "channel-item" },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(v.show_channel) +
                                  "\n                    "
                              ),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "dialog-footer" },
      [
        _vm.editType !== "detail"
          ? _c("el-button", { on: { click: _vm.goBack } }, [_vm._v("取 消")])
          : _vm._e(),
        _vm._v(" "),
        _vm.editType !== "detail"
          ? _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.submitForm("ruleForm")
                  },
                },
              },
              [_vm._v("\n            确定\n        ")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.editType === "detail"
          ? _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.goBack } },
              [_vm._v("\n            了解\n        ")]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }