"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/var/www/html/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _yunying = require("@/api/yunying");
var _backend = require("@/api/backend");
// 公共数据 （图片库，图片分类）
var _default = exports.default = {
  namespaced: true,
  state: {
    /** 接口数据 */
    img_group_list: '',
    // 图片分类列表
    img_group_detail: '',
    // 图片不同分组对应的图片列表数据
    center_channels: '',
    // 中台渠道组列表
    reg_channels: '',
    // 正则渠道组列表
    all_products: '',
    // 产品列表
    all_tf_products: '',
    // 投放产品列表
    all_qm_products: '' // 起名产品列表
  },
  mutations: (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, 'INIT_IMG_GRUOPS', function INIT_IMG_GRUOPS(state, data) {
    state.img_group_list = data;
  }), 'INIT_IMG_GRUOPS_DETAIL', function INIT_IMG_GRUOPS_DETAIL(state, data) {
    state.img_group_detail = data;
  }), 'INIT_CENTER_CHANNELS', function INIT_CENTER_CHANNELS(state, data) {
    state.center_channels = data;
  }), 'INIT_REG_CHANNELS', function INIT_REG_CHANNELS(state, data) {
    state.reg_channels = data;
  }), 'INIT_ALL_PRODUCTS', function INIT_ALL_PRODUCTS(state, data) {
    state.all_products = data;
  }), 'INIT_ALL_TF_PRODUCTS', function INIT_ALL_TF_PRODUCTS(state, data) {
    state.all_tf_products = data;
  }), 'INIT_ALL_QM_PRODUCTS', function INIT_ALL_QM_PRODUCTS(state, data) {
    state.all_qm_products = data;
  }),
  actions: {
    // 获取图片分类列表
    getImgGroupList: function getImgGroupList(_ref) {
      var commit = _ref.commit,
        state = _ref.state;
      // 避免多次请求
      if (state.img_group_list.length > 0) {
        return;
      }
      (0, _yunying.imgGroupList)().then(function (_ref2) {
        var data = _ref2.data;
        commit('INIT_IMG_GRUOPS', data);
      });
    },
    // 获取图片对应分组的列表 (默认先请求全部 id:0)
    getImgGroupDetail: function getImgGroupDetail(_ref3, params) {
      var commit = _ref3.commit,
        state = _ref3.state;
      (0, _yunying.imageList)(params).then(function (_ref4) {
        var data = _ref4.data;
        commit('INIT_IMG_GRUOPS_DETAIL', data.list);
      }).catch(function () {});
    },
    // 获取中台渠道组
    getChannelGroup: function getChannelGroup(_ref5, params) {
      var commit = _ref5.commit,
        state = _ref5.state;
      if (state.center_channels.length > 0) {
        return;
      }
      // 暂时先一次性请求完数据，后续数据量多再考虑优化
      return new Promise(function (resolve, reject) {
        (0, _yunying.centerChannelsList)(params).then(function (_ref6) {
          var data = _ref6.data;
          var list = data.list;
          commit('INIT_CENTER_CHANNELS', list);
          resolve(data);
        }).catch(function () {
          reject();
        });
      });
    },
    // 获取正则渠道组
    getRegChannels: function getRegChannels(_ref7, _ref8) {
      var commit = _ref7.commit,
        state = _ref7.state;
      var params = _ref8.params,
        _ref8$update = _ref8.update,
        update = _ref8$update === void 0 ? false : _ref8$update;
      // update - 是否需要更新数据
      if (state.reg_channels.length > 0 && !update) {
        return;
      }
      // 暂时先一次性请求完数据，后续数据量多再考虑优化
      return new Promise(function (resolve, reject) {
        (0, _yunying.regChannelsList)(params).then(function (_ref9) {
          var data = _ref9.data;
          var list = data.list;
          commit('INIT_REG_CHANNELS', list);
          resolve(data);
        }).catch(function () {
          reject();
        });
      });
    },
    // 获取全部产品
    getAllProducts: function getAllProducts(_ref10, params) {
      var commit = _ref10.commit,
        state = _ref10.state;
      if (state.all_products) {
        return;
      }
      return new Promise(function (resolve, reject) {
        (0, _backend.getProducts)(params).then(function (_ref11) {
          var data = _ref11.data,
            code = _ref11.code;
          if (code === 200) {
            commit('INIT_ALL_PRODUCTS', data);
            resolve(data);
          }
        }).catch(function () {
          reject();
        });
      });
    },
    // 获取全部投放产品
    getAllTfProducts: function getAllTfProducts(_ref12) {
      var commit = _ref12.commit,
        state = _ref12.state;
      if (state.all_tf_products) {
        return;
      }
      return new Promise(function (resolve, reject) {
        (0, _backend.getProducts)('tf').then(function (_ref13) {
          var data = _ref13.data,
            code = _ref13.code;
          if (code === 200) {
            commit('INIT_ALL_TF_PRODUCTS', data);
            resolve(data);
          }
        }).catch(function () {
          reject();
        });
      });
    },
    // 获取全部起名产品
    getAllQmProducts: function getAllQmProducts(_ref14) {
      var commit = _ref14.commit,
        state = _ref14.state;
      if (state.all_qm_products) {
        return;
      }
      return new Promise(function (resolve, reject) {
        (0, _backend.getProducts)('qm').then(function (_ref15) {
          var data = _ref15.data,
            code = _ref15.code;
          if (code === 200) {
            commit('INIT_ALL_QM_PRODUCTS', data);
            resolve(data);
          }
        }).catch(function () {
          reject();
        });
      });
    }
  }
};