var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "schannel-wrap" },
    [
      _c(
        "el-form",
        {
          attrs: {
            model: _vm.propForm,
            rules: _vm.openRules ? _vm.rules : {},
            "label-width": _vm.labelWidth,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.labelTitle, prop: "schannel" } },
            [
              _c("el-input", {
                staticStyle: { width: "262px" },
                attrs: {
                  clearable: "",
                  placeholder: _vm.placeholder,
                  disabled: true,
                },
                model: {
                  value: _vm.propForm.schannel,
                  callback: function ($$v) {
                    _vm.$set(_vm.propForm, "schannel", $$v)
                  },
                  expression: "propForm.schannel",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-link",
        {
          directives: [
            {
              name: "clipboard",
              rawName: "v-clipboard:copy",
              value: _vm.propForm.schannel,
              expression: "propForm.schannel",
              arg: "copy",
            },
            {
              name: "clipboard",
              rawName: "v-clipboard:success",
              value: _vm.onCopy,
              expression: "onCopy",
              arg: "success",
            },
            {
              name: "clipboard",
              rawName: "v-clipboard:error",
              value: _vm.onError,
              expression: "onError",
              arg: "error",
            },
          ],
          attrs: { type: "primary" },
        },
        [_vm._v("\n        复制\n    ")]
      ),
      _vm._v(" "),
      _c(
        "el-dropdown",
        [
          _c("span", { staticClass: "el-dropdown-link" }, [_vm._v("规则")]),
          _vm._v(" "),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            [
              _c("el-dropdown-item", [
                _c("div", [
                  _vm._v("schannel的拼接规则：(自动拼接下发，不允许自定义)"),
                ]),
                _vm._v(" "),
                _c("div", [_vm._v("应用ID（APPID） + 页面标识 + 组件ID")]),
                _vm._v(" "),
                _c("div", [_vm._v("组件ID见组件左上角")]),
                _vm._v(" "),
                _c("div", [
                  _vm._v(
                    "注意：继承公共的组件schannel也是按照上述规则下发，无需自动添加"
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }