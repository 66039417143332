"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/var/www/html/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _user = require("@/api/user");
var _vuex = require("vuex");
var _timers = require("timers");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      id: this.$route.params.ID || '',
      // 从链接获取角色id
      permitList: '',
      // 权限列表
      ruleForm: {
        flag: '',
        role: '',
        selected: []
      },
      rules: {
        flag: {
          required: true,
          message: '请输入角色标识',
          trigger: 'blur'
        },
        role: {
          required: true,
          message: '请输入角色名称',
          trigger: 'blur'
        },
        selected: {
          required: true,
          message: '请选择权限',
          trigger: 'blur'
        }
      },
      oldData: '' // 旧数据，保存时对比用
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(['permissionList'])),
  mounted: function mounted() {
    var _this = this;
    if (this.id) {
      this.getRoleById();
    }
    this.$store.dispatch('user/getPermissionList').then(function () {
      _this.permitList = _this.generateData(_this.permissionList);
    });
  },
  methods: {
    // 初始化权限选择列表(数据过滤)
    generateData: function generateData(permission) {
      var data = [];
      permission.map(function (item, index) {
        data.push({
          key: item.id,
          label: item.desc,
          disabled: false
        });
      });
      return data;
    },
    cancel: function cancel() {
      this.$router.push({
        path: '/auth/rolemanage'
      });
    },
    submit: function submit() {
      var _this2 = this;
      this.$refs['ruleForm'].validate(function (valid) {
        if (valid) {
          _this2.submitRole();
        }
      });
    },
    // 提交角色信息
    submitRole: function submitRole() {
      var _this3 = this;
      var ruleForm = this.ruleForm,
        oldData = this.oldData;
      var params = {
        flag: ruleForm.flag,
        role: ruleForm.role,
        permission: ruleForm.selected.join(',')
      };
      if (this.id) {
        // 修改
        if (JSON.stringify(ruleForm) === JSON.stringify(oldData)) {
          this.$message('您未修改任何信息');
          return;
        }
        params.id = this.id;
        this.$confirm('您确定修改该角色信息吗', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          (0, _user.updateRole)(params).then(function (res) {
            _this3.$message({
              type: 'success',
              message: '提交成功!'
            });
            _this3.$router.push({
              path: '/auth/rolemanage'
            });
          });
        });
      } else {
        // 新增
        (0, _user.addRole)(params).then(function (res) {
          _this3.$message({
            type: 'success',
            message: '提交成功!'
          });
          _this3.$router.push({
            path: '/auth/rolemanage'
          });
        });
      }
    },
    // 角色详情获取
    getRoleById: function getRoleById() {
      var _this4 = this;
      (0, _user.getRoleDetail)({
        id: this.id
      }).then(function (res) {
        var _res$data = res.data,
          flag = _res$data.flag,
          role = _res$data.role,
          permission = _res$data.permission;
        _this4.ruleForm.flag = flag;
        _this4.ruleForm.role = role;
        if (permission) {
          var arr = [];
          permission.map(function (item) {
            arr.push(item.id);
          });
          _this4.ruleForm.selected = arr;
        }
        _this4.oldData = Object.assign({}, _this4.ruleForm);
      });
    }
  }
};