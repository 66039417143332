var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ComConfig", {
    attrs: {
      title: "图文列表-1",
      component_name: "3-1-graphic-list-1",
      initConfigNum: 8,
      configItemId: 2,
      addBtnShow: false,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }