var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "section",
        { staticClass: "search-area" },
        [
          _c(
            "div",
            [
              _c(
                "el-input",
                {
                  staticClass: "width-250",
                  attrs: { placeholder: "请输入渠道组名称搜索", clearable: "" },
                  on: { change: _vm.getList },
                  model: {
                    value: _vm.searchName,
                    callback: function ($$v) {
                      _vm.searchName = $$v
                    },
                    expression: "searchName",
                  },
                },
                [
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-search" },
                    slot: "append",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-input",
                {
                  staticClass: "width-250",
                  staticStyle: { "margin-left": "10px" },
                  attrs: { placeholder: "请输入匹配规则搜索", clearable: "" },
                  on: { change: _vm.getList },
                  model: {
                    value: _vm.searchReg,
                    callback: function ($$v) {
                      _vm.searchReg = $$v
                    },
                    expression: "searchReg",
                  },
                },
                [
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-search" },
                    slot: "append",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-plus" },
              on: { click: _vm.onAdd },
            },
            [_vm._v("新增")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData, border: "", size: "medium" },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "id",
              label: "ID",
              "min-width": "40",
              "header-align": "center",
              align: "center",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "name",
              label: "渠道组名称",
              "min-width": "150",
              "header-align": "center",
              align: "center",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "reg",
              label: "匹配规则",
              "min-width": "200",
              "header-align": "center",
              align: "center",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "子渠道组", "min-width": "120", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(scope.row.sub_reg_data, function (v, k) {
                    return _c(
                      "el-tooltip",
                      {
                        key: k,
                        attrs: {
                          effect: "dark",
                          content: v.reg,
                          placement: "top",
                        },
                      },
                      [
                        _c("el-tag", [
                          _vm._v(
                            "\n                        " +
                              _vm._s(v.name) +
                              "\n                    "
                          ),
                        ]),
                      ],
                      1
                    )
                  })
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "created_at",
              label: "创建时间",
              "min-width": "100",
              "header-align": "center",
              align: "center",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "updated_at",
              label: "更新时间",
              "min-width": "100",
              "header-align": "center",
              align: "center",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "cancel",
              label: "操作",
              "min-width": "70",
              "header-align": "center",
              align: "center",
              fixed: "right",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-button", {
                      attrs: { type: "text", icon: "el-icon-edit" },
                      on: {
                        click: function ($event) {
                          return _vm.onModify(scope.row)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("el-button", {
                      attrs: { type: "text", icon: "el-icon-delete" },
                      on: {
                        click: function ($event) {
                          return _vm.onDelete(scope.row)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.tablePage.total_page > 0
        ? _c(
            "section",
            { staticClass: "pagination-wrap" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.tablePage.current,
                  "page-size": _vm.tablePage.per_page,
                  layout: "prev, pager, next, jumper",
                  "page-count": _vm.tablePage.total_page,
                },
                on: {
                  "current-change": _vm.handleCurrentChange,
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.tablePage, "current", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.tablePage, "current", $event)
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("RegChannelsEdit", {
        ref: "RegChannelEdit",
        attrs: { editType: _vm.editType, ruleForm: _vm.chooseData },
        on: {
          "update:ruleForm": function ($event) {
            _vm.chooseData = $event
          },
          "update:rule-form": function ($event) {
            _vm.chooseData = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }