"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/var/www/html/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _vuex = require("vuex");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ['id', 'title', 'tips', 'keyId'],
  data: function data() {
    return {};
  },
  computed: _objectSpread({}, (0, _vuex.mapState)({
    cur_select_module: function cur_select_module(state) {
      return state.components.cur_select_module;
    },
    cur_select_module_index: function cur_select_module_index(state) {
      return state.components.cur_select_module_index;
    },
    active_content_key: function active_content_key(state) {
      return state.components.active_content_key;
    }
  })),
  methods: {
    // 跳转公共方法（组件凡是跳转都统一调用该方法）
    onLocation: function onLocation(url_config) {
      var switchFlag = _jsCookie.default.get('url_switch') || 'on';
      var url_name = url_config.url_name,
        path = url_config.path,
        url_rule = url_config.url_rule,
        path_norule = url_config.path_norule;
      if (switchFlag == 'off') {
        this.$message({
          message: '请先开启【组件资源位链接跳转】按钮，才能点击预览链接~',
          type: 'warning'
        });
        return;
      }
      if (url_rule === 'norule') {
        if (!path_norule) {
          this.$message({
            message: '请先配置链接，才能点击跳转~',
            type: 'warning'
          });
          return;
        }
        window.open("".concat(path_norule), '_blank');
      } else {
        if (!url_name) {
          this.$message({
            message: '请先配置链接，才能点击跳转~',
            type: 'warning'
          });
          return;
        }
        window.open("".concat(url_name).concat(path), '_blank');
      }
    }
  }
};