"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    propForm: {
      type: Object,
      default: function _default() {
        return {
          title: ''
        };
      }
    },
    update: {
      // 数据更新
      type: Function,
      default: function _default() {
        return function () {};
      }
    },
    labelWidth: {
      // 标题宽度
      type: String,
      default: function _default() {
        return '55px';
      }
    },
    labelTitle: {
      // 标题
      type: String,
      default: function _default() {
        return '标题';
      }
    },
    placeholder: {
      // 提示内容
      type: String,
      default: function _default() {
        return '输入标题';
      }
    },
    openRules: {
      // 是否开启规则验证
      type: Boolean,
      default: function _default() {
        return false;
      }
    }
  },
  watch: {
    propForm: {
      handler: function handler() {
        this.update();
      },
      deep: true
    }
  },
  data: function data() {
    return {
      rules: {
        title: {
          required: true,
          message: '请输入标题',
          trigger: 'blur'
        }
      }
    };
  },
  methods: {
    onFocus: function onFocus() {
      this.$emit('closeOrOpenDrag', true);
    },
    onBlur: function onBlur() {
      this.$emit('closeOrOpenDrag', false);
    }
  }
};