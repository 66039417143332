"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _slicedToArray2 = _interopRequireDefault(require("/var/www/html/node_modules/@babel/runtime-corejs2/helpers/slicedToArray.js"));
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      moduleList: []
    };
  },
  computed: {
    cur_select_module_index: function cur_select_module_index() {
      return this.$store.state.components.cur_select_module_index;
    },
    cur_select_module: function cur_select_module() {
      var _this = this;
      var data = this.$store.state.components.cur_select_module;
      this.moduleList = [];
      data.map(function (v, k) {
        _this.moduleList.push({
          component_name: v.component_name,
          component_id: v.component_id,
          module: function module(resolve) {
            require(["@/views/modulestore/module/".concat(v.component_name, "/config")], resolve);
          }
        });
      });
      return data;
    }
  },
  mounted: function mounted() {},
  methods: {
    validator: function validator() {
      if (this.$refs.configRef) {
        var _this$$refs$configRef = (0, _slicedToArray2.default)(this.$refs.configRef.$children, 1),
          childRef = _this$$refs$configRef[0];
        if (typeof childRef.validator === 'function') {
          return childRef.validator();
        }
      }
      // if (typeof this.$refs.configRef.validator === 'function') {
      //     return this.$refs.configRef.validator();
      // }
      return Promise.resolve(true);
    }
  }
};