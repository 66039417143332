"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.object.freeze");
var _yunying = require("@/api/yunying");
var _constants = require("@/constants");
var _configManage = _interopRequireDefault(require("./components/config-manage"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 新增配置项
var _default = exports.default = {
  components: {
    ConfigManage: _configManage.default
  },
  data: function data() {
    return {
      loading: true,
      tableData: [],
      searchName: '',
      searchId: '',
      // 修改数据
      chooseData: {
        wechatId: [],
        id: '',
        percent: [],
        huokeId: [],
        huokePercent: []
      },
      manageType: '',
      // 操作类型
      currentPage: 1,
      totalPage: 1,
      pageSize: 30
    };
  },
  mounted: function mounted() {
    this.getWxConfigList();
  },
  methods: {
    // 获取配置列表
    getWxConfigList: function getWxConfigList() {
      var _this = this;
      var params = {
        page: this.currentPage,
        per_page: this.pageSize
      };
      if (this.searchName) {
        params.name = this.searchName;
      }
      if (this.searchId) {
        params.wechat = this.searchId;
      }
      (0, _yunying.getWxGroup)(params).then(function (_ref) {
        var data = _ref.data,
          code = _ref.code;
        _this.loading = false;
        if (code === 200) {
          _this.tableData = Object.freeze(data.list);
          _this.totalPage = data.total_page;
        }
      });
    },
    onSearch: function onSearch() {
      this.getWxConfigList();
    },
    // 分页
    handleCurrentChange: function handleCurrentChange(page) {
      this.currentPage = page;
      this.getWxConfigList();
    },
    // 新增配置
    onAdd: function onAdd() {
      this.chooseData = {
        name: '',
        wechatId: [],
        percent: [],
        huokeId: [],
        huokePercent: []
      };
      this.manageType = 'add';
      this.$refs.ConfigManage.dialogVisible = true;
    },
    // 修改
    onModify: function onModify(val) {
      var weight = val.weight,
        wechat_group_info = val.wechat_group_info,
        id = val.id,
        name = val.name,
        cus_acq_group_info = val.cus_acq_group_info,
        cus_acq_weight = val.cus_acq_weight;
      var wechatId = wechat_group_info.map(function (v) {
        return parseInt(v.id);
      });
      var percent = weight.split(',').map(function (v) {
        return v;
      });
      var huokeId = cus_acq_group_info.map(function (v) {
        return parseInt(v.id);
      });
      var huokePercent = cus_acq_weight.split(',').map(function (v) {
        return v;
      });
      this.chooseData = {
        id: id,
        name: name,
        percent: percent,
        wechatId: wechatId,
        huokeId: huokeId,
        huokePercent: huokePercent
      };
      this.manageType = 'modify';
      this.$refs.ConfigManage.dialogVisible = true;
    },
    //同步
    onSync: function onSync(_ref2) {
      var id = _ref2.id;
      var loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      (0, _yunying.syncWxGroup)(id).then(function () {
        loading.close();
      });
    },
    handleType: function handleType(s) {
      this.typesVal = s;
      this.getWxConfigList();
    },
    groupFormat: function groupFormat(_ref3) {
      var wechat_name = _ref3.wechat_name,
        wechat = _ref3.wechat,
        type = _ref3.type;
      var tps = _constants.FANS_TYPES_MAP.get(type),
        label = "".concat(wechat_name, "\uFF08").concat(tps, "\uFF1A").concat(wechat, "\uFF09");
      return label;
    },
    tableRowClassName: function tableRowClassName(index) {
      if (index % 2 === 0) {
        return 'second-type';
      } else {
        return 'first-type';
      }
    }
  }
};