"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/var/www/html/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _lodash = _interopRequireDefault(require("lodash"));
var _vuex = require("vuex");
var _yunying = require("@/api/yunying");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    labelWidth: {
      type: String,
      default: function _default() {
        return '80px';
      }
    },
    labelTitle: {
      type: String,
      default: function _default() {
        return '选择图片';
      }
    },
    labelPosition: {
      type: String,
      default: function _default() {
        return 'right';
      }
    },
    groupFlag: {
      // 是否需要资源分组选项 (不出现的图片不保存到图片库，需手动保存)
      type: Boolean,
      default: function _default() {
        return true;
      }
    },
    inputWidth: {
      // 链接输入框长度
      type: String,
      default: function _default() {
        return '100%';
      }
    },
    ruleForm: {
      type: Object,
      default: function _default() {
        return {
          group_id: '',
          // 选择的分类id （传二级id即可）
          img_url: '' // 图片链接
        };
      }
    },
    required: {
      type: Boolean,
      default: function _default() {
        return true;
      }
    },
    disabled: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    }
  },
  watch: {
    required: function required(val) {
      this.rules.img_url.required = val;
    },
    disabled: function disabled(val) {
      this.imgDisabled = val;
    }
  },
  data: function data() {
    return {
      uploadImgUrl: _yunying.uploadImgUrl,
      // 图片上传链接
      // 重置字段
      groupProps: {
        value: 'id',
        label: 'group_name'
      },
      rules: {
        img_url: {
          required: this.required,
          message: '请上传图片或者输入图片链接',
          trigger: 'blur'
        }
      },
      imgGroupListData: '',
      picLists: [],
      // 上传的图片列表数据
      imgDisabled: this.disabled
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(['img_group_list', 'token'])),
  mounted: function mounted() {
    if (this.groupFlag) {
      this.initGroup();
    }
  },
  methods: {
    // 获取图片分组列表
    initGroup: function initGroup() {
      var _this = this;
      this.$store.dispatch('common/getImgGroupList').then(function (res) {
        _this.img_group_list.shift(); // 去除第一项全部
        _this.imgGroupListData = _this.img_group_list;
      });
    },
    // 分组选择
    selectGroupHandle: function selectGroupHandle(data) {
      this.ruleForm.group_id = data[1]; // 传二级分类的id即可
      this.postData();
    },
    // 新增分组
    addGroup: function addGroup() {
      this.$refs.addGroup.dialogVisible = true;
    },
    // 文件列表移除文件时的钩子
    handleRemove: function handleRemove(file, fileList) {
      this.resetPicList(fileList);
      this.postData();
    },
    // 点击文件列表中已上传的文件时的钩子
    handlePictureCardPreview: function handlePictureCardPreview(file) {},
    // 上传文件之前的钩子
    handleBeforeUpload: function handleBeforeUpload(file) {
      var isLt500KB = file.size / 1024 < 500;
      if (!isLt500KB) {
        this.$message.error('上传图片大小不能超过 500 kb!');
      }
      return isLt500KB;
    },
    // 图片超出限定个数钩子
    handleExceed: function handleExceed(file, fileList) {
      this.$message.error("\u53EA\u80FD\u4E0A\u4F201\u5F20\u56FE\u7247\uFF0C\u5982\u9700\u66F4\u6362\u56FE\u7247\uFF0C\u8BF7\u5220\u9664\u5DF2\u4E0A\u4F20\u6587\u4EF6\uFF0C\u91CD\u65B0\u4E0A\u4F20");
    },
    // 图片上传成功
    handleSuccess: function handleSuccess(response, file, fileList) {
      var _this2 = this;
      var data = file.response.data;
      this.resetPicList(fileList);
      this.ruleForm.img_url = data[0].url;
      setTimeout(function () {
        _this2.postData();
      });
    },
    // 图片数据过滤 fileList 插件的图片格式
    resetPicList: function resetPicList(fileList) {
      // 过滤下数据,拿到主要的图片数据 file_name、res_id、size、thumb_url、url
      var newArray = [];
      fileList.map(function (v, k) {
        if (v.response) {
          newArray.push(v.response.data[0]);
        }
      });
      this.picLists = newArray;
    },
    // 直接输入图片链接监听
    onChange: function onChange(val) {
      this.ruleForm.img_url = val;
      this.postData();
    },
    // 数据传递
    postData: function postData() {
      var ruleForm = this.ruleForm;
      this.$emit('postUploadImage', ruleForm);
    },
    // 上传失败
    handleError: function handleError() {
      this.$message.error('图片上传失败,请重新上传!');
    },
    // 删除展示图
    deletePic: function deletePic() {
      this.ruleForm.img_url = '';
    },
    // 保存图片到资源库 （选择了资源分组才能保存）(调用组件使用)
    savePicToGallery: function savePicToGallery() {
      var picLists = this.picLists,
        ruleForm = this.ruleForm;
      if (!ruleForm.group_id) {
        return;
      }
      var newArray = [];
      if (picLists.length > 0) {
        picLists[0].group_id = ruleForm.group_id;
        newArray = picLists;
      } else {
        // 直接输入链接,不上传图片
        newArray = [{
          url: ruleForm.img_url,
          thumb_url: '',
          res_id: '',
          file_name: '',
          size: '',
          group_id: ruleForm.group_id
        }];
      }
      (0, _yunying.saveImage)(JSON.stringify(newArray)).then(function (res) {});
    },
    // 数据校验 (提供给调用的组件使用)
    validateData: function validateData() {
      var isValidate = false;
      this.$refs['imageRuleForm'].validate(function (valid) {
        if (valid) {
          isValidate = true;
        } else {
          isValidate = false;
        }
      });
      return isValidate;
    },
    // 清空表单  (提供给调用的组件使用)
    resetForm: function resetForm() {
      this.$refs.imageRuleForm.resetFields();
      this.picLists = []; // 这里需要重置一下，避免第一次上传图片，第二次输入链接导致两个条件都成立
    }
  }
};