"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/var/www/html/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/es6.object.freeze");
require("core-js/modules/es6.function.name");
var _backend = require("@/api/backend");
var _productManage = _interopRequireDefault(require("./components/product-manage.vue"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    ProductManage: _productManage.default
  },
  data: function data() {
    return {
      tableData: [],
      totalPage: 1,
      currentPage: 1,
      perPage: 15,
      tagData: {},
      productId: '',
      channel: '',
      manageType: '',
      // 弹窗操作类型 add-新增 modify-修改
      chooseData: {}
    };
  },
  mounted: function mounted() {
    this.getTagDetail();
  },
  methods: {
    getTagDetail: function getTagDetail() {
      var _this = this;
      var _this$$route$query = this.$route.query,
        id = _this$$route$query.tag_id,
        from = _this$$route$query.from; // 标签id
      var data = {};
      if (!id) {
        return;
      }
      if (from) {
        data.from = from;
      }
      (0, _backend.getConfigTagDetail)(id, data).then(function (_ref) {
        var code = _ref.code,
          data = _ref.data;
        if (code === 200) {
          _this.tagData = data;
          _this.getList();
        }
      });
    },
    getList: function getList() {
      var _this2 = this;
      var name = this.tagData.name;
      var from = this.$route.query.from;
      if (!name) {
        return;
      }
      var params = {
        page: this.currentPage,
        per_page: this.perPage,
        name: name
      };
      if (this.channel) {
        params.channel = this.channel;
      }
      if (this.productId) {
        params.product_id = this.productId;
      }
      if (from) {
        params.from = from;
      }
      (0, _backend.getProductConfigList)(params).then(function (_ref2) {
        var code = _ref2.code,
          data = _ref2.data;
        if (code === 200) {
          var list = data.list,
            meta = data.meta;
          _this2.tableData = Object.freeze(list);
          _this2.totalPage = meta.page.total_page;
        }
      });
    },
    // 分页
    handleCurrentChange: function handleCurrentChange(page) {
      this.currentPage = page;
      this.getList();
    },
    onModify: function onModify(val) {
      this.chooseData = _objectSpread({}, val);
      this.manageType = 'modify';
      this.$refs.managePop.dialogVisible = true;
    },
    onAdd: function onAdd() {
      this.chooseData = {
        name: this.tagData.name
      }; // 清空数据
      this.manageType = 'add';
      this.$refs.managePop.dialogVisible = true;
    },
    onSearch: function onSearch() {
      // 搜索重置
      this.totalPage = 1;
      this.currentPage = 1;
      this.getList();
    }
  }
};