var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _vm.navShow
        ? _c(
            "el-tabs",
            {
              attrs: { type: "card" },
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c("el-tab-pane", {
                attrs: { label: "产品配置标签", name: "backendTag" },
              }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: { label: "投放产品配置", name: "tfBackendTag" },
              }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: { label: "起名产品配置", name: "qmBackendTag" },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }