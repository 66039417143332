"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.string.link");
require("core-js/modules/es6.object.freeze");
require("core-js/modules/es6.function.name");
var _yunying = require("@/api/yunying");
var _goodsManage = _interopRequireDefault(require("./components/goods-manage"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    Manage: _goodsManage.default
  },
  data: function data() {
    return {
      name: '',
      // 组件名搜索
      goods_id: '',
      // 微信号搜索
      tableData: [],
      manageType: '',
      // 弹窗操作类型
      chooseData: {},
      imageUploadData: {},
      currentPage: 1,
      totalPage: 1,
      perPage: 30,
      previewImage: '',
      dialogVisible: false,
      tags: []
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    // 微信号列表数据
    getList: function getList() {
      var _this = this;
      var params = {
        page: this.currentPage,
        per_page: this.perPage,
        name: this.name,
        goods_id: this.goods_id
      };
      (0, _yunying.getGoods)(params).then(function (_ref) {
        var data = _ref.data,
          code = _ref.code;
        if (code === 200) {
          _this.tableData = Object.freeze(data.list);
          _this.totalPage = data.total_page;
        }
      });
    },
    // 分页
    handleCurrentChange: function handleCurrentChange(page) {
      this.currentPage = page;
      this.getList();
    },
    onSearch: function onSearch() {
      this.getList();
    },
    onAdd: function onAdd() {
      this.chooseData = {
        status: 1
      };
      this.imageUploadData = {
        img_url: ''
      };
      this.manageType = 'add';
      this.$refs.managePop.dialogVisible = true;
    },
    onModify: function onModify(val) {
      this.chooseData = {
        name: val.name,
        goods_id: val.goods_id,
        status: val.status,
        desc: val.desc,
        tag: val.tag,
        link: val.link,
        image: val.image,
        id: val.id,
        sandbox_link: val.sandbox_link
      };
      this.imageUploadData = {
        img_url: val.image
      };
      this.manageType = 'modify';
      this.$refs.managePop.dialogVisible = true;
    },
    onCopy: function onCopy(e) {
      this.$message({
        type: 'success',
        message: '复制成功!',
        duration: 1000
      });
    },
    onError: function onError(e) {
      this.$message.error('复制失败!');
    },
    toGroup: function toGroup() {
      this.$router.push({
        name: 'goodsGroup'
      });
    },
    onPreviewImage: function onPreviewImage(data) {
      this.dialogVisible = true;
      this.previewImage = data.image;
    }
  }
};