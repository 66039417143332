var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ComConfig", {
    attrs: { title: "悬浮窗-2", component_name: "7-2-pop-bar-2", limit: 1 },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }