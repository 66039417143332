"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _lodash = _interopRequireDefault(require("lodash"));
var _monitor = require("@/api/monitor");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  components: {},
  props: {
    manageType: {},
    companyList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    ruleForm: {
      default: function _default() {
        return {
          corp_id: '',
          user_id: '',
          name: '',
          control: 1,
          apply: 1,
          weight: 0
        };
      }
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      rules: {
        corp_id: {
          required: true,
          message: '请输入主体',
          trigger: 'blur'
        },
        user_id: {
          required: true,
          message: '请输入账号ID',
          trigger: 'blur'
        },
        name: {
          required: true,
          message: '请输入账号昵称',
          trigger: 'blur'
        },
        weight: {
          required: true,
          message: '请选择监控优先级',
          trigger: 'change'
        },
        control: {
          required: true,
          message: '请选择监控状态',
          trigger: 'change'
        }
      }
    };
  },
  computed: {
    isModify: function isModify() {
      return this.manageType == 'modify';
    }
  },
  methods: {
    onCancel: function onCancel() {
      this.dialogVisible = false;
    },
    submitForm: function submitForm(formName) {
      var _this = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          if (!_this.ruleForm.user_id.trim()) {
            _this.$message({
              message: '请输入账号ID！',
              type: 'warning'
            });
            // 清除前后空格
            _this.ruleForm.user_id = _this.ruleForm.user_id.trim();
            return false;
          }
          if (!_this.ruleForm.name.trim()) {
            _this.$message({
              message: '请输入账号昵称！',
              type: 'warning'
            });
            // 清除前后空格
            _this.ruleForm.name = _this.ruleForm.name.trim();
            return false;
          }
          _this.handleEvent();
        } else {
          return false;
        }
      });
    },
    handleEvent: function handleEvent() {
      var _this2 = this;
      var manageType = this.manageType,
        ruleForm = this.ruleForm;
      if (manageType == 'add') {
        var params = {
          corp_id: ruleForm.corp_id,
          user_id: ruleForm.user_id.trim(),
          name: ruleForm.name.trim(),
          control: ruleForm.control,
          apply: ruleForm.apply,
          weight: ruleForm.weight
        };
        (0, _monitor.addWorkWx)(params).then(function (data) {
          if (data.code === 200) {
            _this2.$message({
              message: '添加成功',
              type: 'success'
            });
            _this2.dialogVisible = false;
            _this2.$parent.getList(); // 更新父组件数据
          }
        });
      } else {
        var _params = {
          control: ruleForm.control,
          weight: ruleForm.weight
        };
        (0, _monitor.modifyWorkWx)(ruleForm.id, _params).then(function (data) {
          if (data.code === 200) {
            _this2.$message({
              message: '修改成功',
              type: 'success'
            });
            _this2.dialogVisible = false;
            _this2.$parent.getList(); // 更新父组件数据
          }
        });
      }
    }
  }
};