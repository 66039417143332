"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuedraggable = _interopRequireDefault(require("vuedraggable"));
var _comImage = _interopRequireDefault(require("../components/com-image"));
var _comTitle = _interopRequireDefault(require("../components/com-title"));
var _comDesc = _interopRequireDefault(require("../components/com-desc"));
var _comUrl = _interopRequireDefault(require("../components/com-url"));
var _comNumPeople = _interopRequireDefault(require("../components/com-num-people"));
var _comNumComment = _interopRequireDefault(require("../components/com-num-comment"));
var _comStyleType = _interopRequireDefault(require("../components/com-style-type"));
var _comExtend = _interopRequireDefault(require("../components/com-extend"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  components: {
    draggable: _vuedraggable.default,
    ComImage: _comImage.default,
    ComTitle: _comTitle.default,
    ComDesc: _comDesc.default,
    ComUrl: _comUrl.default,
    ComNumPeople: _comNumPeople.default,
    ComNumComment: _comNumComment.default,
    ComStyleType: _comStyleType.default,
    ComExtend: _comExtend.default
  },
  props: {
    configLists: {
      type: Object,
      default: function _default() {
        return {
          config_data: []
        };
      }
    },
    update: {
      // 数据更新
      type: Function,
      default: function _default() {
        return function () {};
      }
    },
    styleConfig: {
      // 样式&显示配置项控制
      type: Object,
      default: function _default() {
        return {
          titleShow: 'show',
          titlePlaceholder: '输入标题',
          descShow: 'hide',
          descPlaceholder: '输入描述内容',
          numPeopleShow: 'hide',
          numPeoplePlaceholder: '请输入测试人数',
          numCommentShow: 'hide',
          numCommentPlaceholder: '请输入评价人数',
          styleTypeShow: 'hide',
          extendShow: 'show'
        };
      }
    }
  },
  data: function data() {
    return {
      disabled: false // false 可拖拽
    };
  },
  mounted: function mounted() {},
  methods: {
    deleteHandle: function deleteHandle(index) {
      var _this = this;
      this.$confirm('即将删除该项, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this.$store.commit('components/DELETE_COMPONENT_CONFIG_ITEM', index);
        _this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(function () {});
    },
    /**
     * @param flag true 关闭拖拽  false 开启拖拽
     * 配置项有拖拽功能，为了避免影响里面的其他操作（比如输入框的内容用鼠标选择会触发拖拽导致无法选中）
     * 需要自行手动屏蔽开启下
     */
    closeOrOpenDrag: function closeOrOpenDrag(flag) {
      if (flag) {
        // 关闭拖拽
        this.disabled = true;
      } else {
        // 开启拖拽
        this.disabled = false;
      }
    }
  }
};