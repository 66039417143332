var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ComConfig", {
    attrs: {
      title: "导航栏-1",
      component_name: "5-1-nav-bar-1",
      initConfigNum: 4,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }