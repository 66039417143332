"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/var/www/html/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/es6.object.freeze");
require("core-js/modules/es6.function.name");
var _yunying = require("@/api/yunying");
var _manage = _interopRequireDefault(require("./components/manage"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    Manage: _manage.default
  },
  data: function data() {
    return {
      searchName: '',
      // 组件名搜索
      tableData: [],
      manageType: '',
      // 弹窗操作类型
      chooseData: {
        content: {}
      },
      imageUploadData: {},
      currentPage: 1,
      totalPage: 1,
      pageSize: 30
    };
  },
  mounted: function mounted() {
    this.getWxComponentList();
  },
  methods: {
    // 微信号列表数据
    getWxComponentList: function getWxComponentList() {
      var _this = this;
      var params = {
        page: this.currentPage
      };
      if (this.searchName) {
        params.name = this.searchName;
      }
      (0, _yunying.getTeacher)(params).then(function (_ref) {
        var data = _ref.data,
          code = _ref.code;
        if (code === 200) {
          var table = data.list.map(function (v) {
            var content = JSON.parse(v.content);
            return {
              content: content,
              id: v.id,
              name: v.name,
              template: "".concat(v.name, "-").concat(content.name),
              updated_at: v.updated_at,
              avatar: content.avatar_image,
              desc: content.tip,
              tips: content.introduce
            };
          });
          _this.tableData = Object.freeze(table);
          _this.totalPage = data.total_page;
        }
      });
    },
    // 分页
    handleCurrentChange: function handleCurrentChange(page) {
      this.currentPage = page;
      this.getWxComponentList();
    },
    onSearch: function onSearch() {
      this.getWxComponentList();
    },
    onAdd: function onAdd() {
      this.chooseData = {};
      this.manageType = 'add';
      this.$refs.managePop.dialogVisible = true;
    },
    onModify: function onModify(_ref2) {
      var name = _ref2.name,
        id = _ref2.id,
        content = _ref2.content;
      this.chooseData = _objectSpread(_objectSpread({
        id: id
      }, content), {}, {
        template: name
      });
      this.manageType = 'modify';
      this.$refs.managePop.dialogVisible = true;
    }
  }
};