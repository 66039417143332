var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.manageType === "add" ? "新增应用配置" : "修改应用配置",
            visible: _vm.dialogVisible,
            width: "60%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-position": "left",
                "label-width": "130px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "APP_ID", prop: "app_id" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入应用app_id",
                      disabled: _vm.manageType === "add" ? false : true,
                    },
                    model: {
                      value: _vm.ruleForm.app_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "app_id", $$v)
                      },
                      expression: "ruleForm.app_id",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "名称", prop: "title" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入应用名称" },
                    model: {
                      value: _vm.ruleForm.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "title", $$v)
                      },
                      expression: "ruleForm.title",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "原始ID", prop: "origin_id" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入原始ID" },
                    model: {
                      value: _vm.ruleForm.origin_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "origin_id", $$v)
                      },
                      expression: "ruleForm.origin_id",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "密钥", prop: "secret" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入密钥" },
                    model: {
                      value: _vm.ruleForm.secret,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "secret", $$v)
                      },
                      expression: "ruleForm.secret",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "状态", prop: "status" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "status", $$v)
                        },
                        expression: "ruleForm.status",
                      },
                    },
                    _vm._l(_vm.statusOptions, function (item) {
                      return _c(
                        "el-radio",
                        {
                          key: item.value,
                          attrs: { label: item.value, value: item.value },
                        },
                        [
                          _vm._v(
                            "\n                        " + _vm._s(item.label)
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "类型", prop: "type" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "type", $$v)
                        },
                        expression: "ruleForm.type",
                      },
                    },
                    _vm._l(_vm.typeOptions, function (item) {
                      return _c(
                        "el-radio",
                        {
                          key: item.value,
                          attrs: { label: item.value, value: item.value },
                        },
                        [
                          _vm._v(
                            "\n                        " + _vm._s(item.label)
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "分组标签", prop: "flag" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入分组标签" },
                    model: {
                      value: _vm.ruleForm.flag,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "flag", $$v)
                      },
                      expression: "ruleForm.flag",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "别称", prop: "program_alias" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入别称" },
                    model: {
                      value: _vm.ruleForm.program_alias,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "program_alias", $$v)
                      },
                      expression: "ruleForm.program_alias",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "mark" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入备注" },
                    model: {
                      value: _vm.ruleForm.mark,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "mark", $$v)
                      },
                      expression: "ruleForm.mark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.onCancel } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("ruleForm")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }