var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dropdown",
        [
          _c("span", { staticClass: "el-dropdown-link" }, [_vm._v(" 说明 ")]),
          _vm._v(" "),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            [
              _c("el-dropdown-item", [
                _c("div", [
                  _vm._v("页面标识与公共模板配置相同,会继承公共模板的数据。"),
                ]),
                _vm._v(" "),
                _c("div", [
                  _vm._v(
                    "如果想独立配置，在当前页面重新配置【相同组件id】的组件即可覆盖公共。"
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _vm._v(
                    "例如：公共模板配置了组件【banner】,组件id【1-1】（见组件左上角），"
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c("span", { staticStyle: { opacity: "0" } }, [
                    _vm._v("占位："),
                  ]),
                  _vm._v(
                    "那么在应用里面重新配置组件1-1，数据就会覆盖公共\n                "
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }