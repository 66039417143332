var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "el-form",
        {
          attrs: {
            model: _vm.propForm,
            rules: _vm.openRules ? _vm.rules : {},
            "label-width": _vm.labelWidth,
            "label-position": _vm.labelPosition,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "渠道", prop: "channel_item" } },
            [
              _c("el-select", {
                attrs: {
                  multiple: "",
                  filterable: "",
                  "allow-create": "",
                  "default-first-option": "",
                  remote: "",
                  "reserve-keyword": "",
                  "remote-method": _vm.remoteMethod,
                  placeholder: _vm.placeholder,
                },
                model: {
                  value: _vm.propForm.channel_item,
                  callback: function ($$v) {
                    _vm.$set(_vm.propForm, "channel_item", $$v)
                  },
                  expression: "propForm.channel_item",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }